import { dispatch } from "../../utils/functions";
import * as types from "./actionTypes";

export function getRideHistory({
  id = null,
  externalId = null,
  index,
  size,
  requester = null,
  date = null,
  status = null,
  createdBy = null,
  driver = null,
}) {
  return dispatch(
    types.GET_RIDE_HISTORY,
    `/ride/user-history?index=${index}${id ? `&id=${id}` : ""}${
      externalId ? `&externalId=${externalId}` : ""
    }${status ? `&status=${status}` : ""}${
      date ? `&date=${date}` : ""
    }&length=${size}${requester ? `&requester=${requester}` : ""}${
      createdBy ? `&createdBy=${createdBy}` : ""
    }${driver ? `&driver=${driver}` : ""}`
  );
}

export function getAllRideHistory({index, date = null ,requester = null,status = null ,externalId = null , id = null, size,driver = null }) {
  return dispatch(
    types.GET_ALL_HISTORY,
    `/ride/user-history?index=${index}${requester ? `&requester=${requester}` : ''}${id ? `&id=${id}` : ''}${externalId ? `&externalId=${externalId}` : ''}${status != null ? `&status=${status}` : ''}${date ? `&date=${date}` : ''}${driver ? `&driver=${driver}` : ""}&length=${size}&all`
    // `/ride/user-history?index=${index}&length=${size}&status=${status}&date=${date}&externalId=${externalId}&id=${id}&all`
  );
}

export function getDriverLocation({ id }) {
  return dispatch(
    types.GET_DRIVER_LOCATION,
    `/ride/driver-location?ride_id=${id}`
  );
}

export function getParents(id){
  return dispatch(types.GET_PARENTS, `/ride/waypoints?id=${id}`
  );
}

export function simulateRide(model) {
  return dispatch(types.SIMULATE_RIDE, "/ride/new-simulate", model);
}

export function requestRide(model) {
  return dispatch(types.REQUEST_RIDE, "/ride/batch-request", model);
}

export function validateRide(model) {
  return dispatch(types.VALIDATE_RIDE, "/ride/validate", model);
}

export function cancelRide(model, currentStatusId = null) {
  return dispatch(types.CANCEL_RIDE, `/ride/cancel-ride-as-user?currentStatusId=${currentStatusId}`, model);
}

export function scheduleRide(model) {
  return dispatch(types.SCHEDULE_RIDE, "/ride/schedule-ride", model);
}
export function getRide({ id }) {
  return dispatch(types.GET_RIDE, `/ride/detail?id=${id}`);
}
export function saveRequestRideForm(model) {
  return { type: types.SAVE_REQUEST_RIDE_FORM, model };
}
export function resetRide(prop) {
  return { type: types.RESET_RIDE, prop };
}

export function trackRide(BUSINESS_ID) {
  return dispatch(
    types.TRACK_RIDE,
    `/ride/track-rides?requester=${BUSINESS_ID}`
  );
}

export function batchSimulateRide(model) {
  return dispatch(types.BATCH_SIMULATE_RIDE, `/ride/batch-simulate`, model);
}
