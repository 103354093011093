/* eslint-disable no-useless-escape */
import { verifyCpf, verifyLuhn } from './functions';
export function verifyEmail(value) {
  var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
}

export function verifyPassword(value) {
  var passRegex = /^[A-Za-z0-9@$!%*#?&]+$/;
  if (passRegex.test(value)) {
    return true;
  }
  return false;
}

export function verifyCPF(value) {
  if (verifyCpf(value)) {
    return true;
  }
  return false;
}

export function verifyCnpj(value) {
  var cnpjRegex = /[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2}/;
  if (cnpjRegex.test(value)) {
    return true;
  }
  return false;
}

export function verifyCardNumber(value) {
  if (verifyLuhn(value)) {
    return true;
  }
  return false;
}

export function verifyPhone(value) {
  var phoneRegex = /^\([1-9]{2}\) (?:9[1-9])[0-9]{2,3}\-[0-9]{4}$/;
  if (phoneRegex.test(value)) {
    return true;
  }
  return false;
}

export function verifyLength(value, length) {
  if (value.length >= length) {
    return true;
  }
  return false;
}

export function verifyExactLength(value, length) {
  if (value.length === length) {
    return true;
  }
  return false;
}
