import React, { useEffect } from 'react';
import { formatTimeDate } from '../../utils/functions';
import './RidePrint.css';

// import { Container } from './styles';

function RidePrint({ ride, ...props }) {
  const [logo] = React.useState(require('assets/img/logo-beelog-horiz.png'));
  
  useEffect(() => {
    const style = document.createElement('style');
    style.setAttribute('id', 'printlandscape')
    style.textContent = `@media print{
      @page {
        size: landscape;
        margin-top: 0.5cm;
        margin-bottom: 0.5cm;        
      }
      div{
        page-break-inside: avoid;
      }
    }`;
    document.head.append(style);
  },[])

  useEffect(
    () => () => {
      var element = document.getElementById('printlandscape');
      document.head.removeChild(element);
    },
    []
  );

  return (
    <div style={{ display: 'block', width: '100%', height: '685px' }}>
      <div style={{height:'70%'}}>
        <div style={{display:'inline-flex', flexDirection:'row nowrap', alignItems: 'center', alignSelf:'center'}}>
          <div style={{width: '30%'}}>
            <img style={{ width: '100%' }} src={logo} alt="logo" />
          </div>
          <div style={{width: '70%'}}>
            <h2
              style={{
                marginLeft: '60px',
                textAlign: 'center',
                width: '100%',
                justifyContent: 'center',
                fontWeight: 'bold',
              }}
            >
              Romaneio de Entrega
            </h2>
          </div>
        </div>
        <p style={{ textAlign: 'right', color: '#000000' }}>
          Motorista: {ride.parents[0].driver ? 
            `${(ride.parents[0].driver.name).toUpperCase()} - ` : 
            ''} 
            {ride.parents[0].driver && ride.parents[0].driver.driver ? 
            (ride.parents[0].driver.driver.vehicle.plate).toUpperCase() : 
            ''}
        </p>
        <p style={{ textAlign: 'right', color: 'black' }}>
          Solicitado em: {formatTimeDate(ride.date)[0]} - {formatTimeDate(ride.date)[1]}
        </p>
        <table id={'table-print'} cellSpacing={0} style={{ width: '100%'}}>
          <thead id={'thead-print'}>
            <tr>
              <th>Nº</th>
              <th>Cod. Frete</th>
              <th>Cod. Controle</th>
              <th>Núm. Volumes</th>
              <th>Destinatário</th>
              <th>Destino</th>
              <th>Bairro</th>
              <th>CEP</th>            
            </tr>
          </thead>
          <tbody id={'tbody-print'}>
            {ride.parents.map((point, i) => {
              const cep = point.destinationAddress.match(/\d{5}-\d{3}/);
              const bairro = point.destinationAddress.match(/- .*, .* -/);
              return (
                <tr key={point.id}>
                  <td>{point.waypoint}</td>
                  <td>{point.id}</td>
                  <td>{point.external_id ? point.external_id : ''}</td>
                  <td>{point.num_volumes}</td>
                  <td>{point.receiver_name ? point.receiver_name.split(' ').length > 0 ? point.receiver_name.split(' ')[0] : point.receiver_name : ''}</td>
                  <td style={{ maxWidth: '400px' }}>
                    {point.destinationAddress.replace(/\d{5}-\d{3},/, '').replace(/- .*,.*/, '')}
                  </td>
                  <td style={{ fontWeight: 'bold' }}>
                    {bairro ? bairro[0].replace(/[-]/g, '').trim().split(',')[0] : ''}
                  </td>
                  <td style={{ fontWeight: 'bold' }}>{cep ? cep[0] : ''}</td>                
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div style={{height:'30%', alignSelf:'flex-end', alignItems: 'flex-end', alignContent: 'flex-end'}}>
        <div style={{width: '58%', marginTop: "50px"  }}>
          <h4>Observações do Motorista:</h4>
          <div className="obsBox" />
        </div>
        <div
          style={{     
            float: 'right',
            width: '40%',
            textAlign: 'center',
            paddingRight: '0%',
            marginTop: '-20px',
          }}
        >
          <div style={{ border: '1px solid black' }} />
          <h4>Assinatura</h4>
        </div>
      </div>
    </div>
  );
}

export default RidePrint;
