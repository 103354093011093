import CardTable from '../views/pages/Wallet/CardTable';
import CardAdd from '../views/pages/Wallet/CardAdd';
import RideTable from '../views/pages/Ride/RideTable';
import Ride from '../views/pages/Ride/Ride';
import RideAdd from '../views/pages/Ride/RideAdd';
import CardShow from '../views/pages/Wallet/CardShow';
import Profile from '../views/pages/Profile/Profile';
import Payments from '../views/pages/Payments/Payments';
import RideWatch from '../views/pages/Ride/RideWatch';
import Income from '../views/pages/Income';
import DriversQueue from '../views/pages/DriversQueue';

const layout = '/dashboard';

const routes = [
  // {
  //   path: '/card/table',
  //   name: 'Cartões',
  //   component: CardTable,
  //   layout,
  //   protected: true
  // },
  // {
  //   path: '/card/add',
  //   name: 'Adicionar cartão',
  //   component: CardAdd,
  //   layout,
  //   protected: true
  // },
  {
    path: '/ride/table',
    name: 'Histórico de Fretes',
    component: RideTable,
    layout,
    protected: true,
  },
  {
    path: '/ride/map/add',
    name: 'Solicitar Frete',
    component: RideAdd,
    layout,
    protected: true,
  },
  {
    path: '/ride/map/watch',
    name: 'Acompanhar Fretes',
    component: RideWatch,
    layout,
    protected: true,
  },
  {
    path: '/income',
    name: 'Financeiro',
    component: Income,
    layout,
    protected: true,
    flag: 'INCOME',
  },
  {
    path: '/ride/:id',
    name: 'Frete',
    component: Ride,
    layout,
    protected: true,
  },
  {
    path: '/card/:id',
    name: 'card show',
    component: CardShow,
    layout,
    protected: true,
  },
  {
    path: '/profile',
    name: 'Meu perfil',
    component: Profile,
    layout,
    protected: true,
  },
  {
    path: '/payments',
    name: 'Beelog Cash',
    component: Payments,
    layout,
    protected: true,
  },
  {
    path: '/queue',
    name: 'Fila',
    component: DriversQueue,
    layout,
    protected: true,
  },
];

export default routes;
