import React from 'react';
// @material-ui/icons
import Face from '@material-ui/icons/Face';
import Call from '@material-ui/icons/Call';
import Email from '@material-ui/icons/Email';
import Lock from '@material-ui/icons/Lock';
import Fingerprint from '@material-ui/icons/Fingerprint';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';

import InputMask from 'inputmask';

// UTILS
import {
  verifyEmail,
  verifyLength,
  verifyCPF,
  verifyPhone,
  verifyPassword
} from '../../../../utils/validators';

const style = {
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center'
  },
  inputAdornmentIcon: {
    color: '#555'
  },
  inputAdornment: {
    position: 'relative'
  }
};

class UserStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      nameState: '',
      phone: '',
      phoneState: '',
      cpf: '',
      cpfState: '',
      email: '',
      emailState: '',
      password: '',
      passwordState: ''
    };
  }

  /** FUNCTIONS */
  sendState() {
    return this.state;
  }

  handleInputChange(e, stateName) {
    let mask;
    switch (stateName) {
      case 'email':
        this.setState({ [stateName]: e.target.value });

        if (verifyEmail(e.target.value)) {
          this.setState({ [stateName + 'State']: 'success' });
        } else {
          this.setState({ [stateName + 'State']: 'error' });
        }
        break;
      case 'name':
        this.setState({ [stateName]: e.target.value });

        if (verifyLength(e.target.value, 3)) {
          this.setState({ [stateName + 'State']: 'success' });
        } else {
          this.setState({ [stateName + 'State']: 'error' });
        }
        break;
      case 'cpf':
        mask = InputMask({
          mask: '999.999.999-99'
        }).mask(e.target);

        this.setState({ [stateName]: mask.maskset.buffer.join('') });

        if (verifyCPF(e.target.value)) {
          this.setState({ [stateName + 'State']: 'success' });
        } else {
          this.setState({ [stateName + 'State']: 'error' });
        }
        break;
      case 'password':
        this.setState({ [stateName]: e.target.value });

        if (verifyPassword(e.target.value)) {
          this.setState({ [stateName + 'State']: 'success' });
        } else {
          this.setState({ [stateName + 'State']: 'error' });
        }
        break;
      case 'phone':
        mask = InputMask({
          mask: '(99) 99999-9999'
        }).mask(e.target);

        this.setState({ [stateName]: mask.maskset.buffer.join('') });

        if (verifyPhone(e.target.value)) {
          this.setState({ [stateName + 'State']: 'success' });
        } else {
          this.setState({ [stateName + 'State']: 'error' });
        }
        break;
      default:
        break;
    }
  }

  isValidated() {
    if (
      this.state.nameState === 'success' &&
      this.state.phoneState === 'success' &&
      this.state.cpfState === 'success' &&
      this.state.emailState === 'success' &&
      this.state.passwordState === 'success'
    ) {
      return true;
    } else {
      if (this.state.nameState !== 'success') {
        this.setState({ nameState: 'error' });
      }
      if (this.state.phoneState !== 'success') {
        this.setState({ phoneState: 'error' });
      }
      if (this.state.cpfState !== 'success') {
        this.setState({ cpfState: 'error' });
      }
      if (this.state.emailState !== 'success') {
        this.setState({ emailState: 'error' });
      }
      if (this.state.passwordState !== 'success') {
        this.setState({ passwordState: 'error' });
      }
    }
    return false;
  }

  render() {
    const { classes } = this.props;

    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12}>
          <h4 className={classes.infoText}>
            Preencha aqui seus dados pessoais
          </h4>
        </GridItem>
        <GridItem xs={12} sm={6}>
          <CustomInput
            success={this.state.nameState === 'success'}
            error={this.state.nameState === 'error'}
            labelText={
              <span>
                Nome <small>(obrigatório)</small>
              </span>
            }
            id="name"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.handleInputChange(event, 'name'),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <Face className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
          <CustomInput
            success={this.state.phoneState === 'success'}
            error={this.state.phoneState === 'error'}
            labelText={
              <span>
                Telefone <small>(obrigatório)</small>
              </span>
            }
            id="phone"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.handleInputChange(event, 'phone'),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <Call className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
          <CustomInput
            success={this.state.cpfState === 'success'}
            error={this.state.cpfState === 'error'}
            labelText={
              <span>
                CPF <small>(obrigatório)</small>
              </span>
            }
            id="cpf"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.handleInputChange(event, 'cpf'),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <Fingerprint className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
          <CustomInput
            success={this.state.emailState === 'success'}
            error={this.state.emailState === 'error'}
            labelText={
              <span>
                Email <small>(obrigatório)</small>
              </span>
            }
            id="email"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.handleInputChange(event, 'email'),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <Email className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
          <CustomInput
            success={this.state.passwordState === 'success'}
            error={this.state.passwordState === 'error'}
            labelText={
              <span>
                Senha <small>(obrigatório)</small>
              </span>
            }
            id="password"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              type: 'password',
              onChange: event => this.handleInputChange(event, 'password'),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <Lock className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={10}></GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(style)(UserStep);
