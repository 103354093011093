import initialState from '../initialState';
import * as types from '../actions/actionTypes';

export default function cardReducer(state = initialState.card, action) {
  switch (action.type) {
    case types.GET_LIST_CARDS_SUCCESS: {
      return {
        ...state,
        cardsList: action.data
      };
    }

    case types.GET_LIST_CARDS_ERROR: {
      return {
        ...state,
        cardsList: action.error
      };
    }

    case types.ADD_CARD_SUCCESS: {
      return {
        ...state,
        cardAdd: action.data
      };
    }

    case types.ADD_CARD_ERROR: {
      return {
        ...state,
        cardAdd: action.error
      };
    }

    case types.DELETE_CARD_SUCCESS: {
      return {
        ...state,
        cardDeleted: action.data
      };
    }

    case types.DELETE_CARD_ERROR: {
      return {
        ...state,
        cardDeleted: action.error
      };
    }

    case types.RESET_CARDS: {
      return {
        ...state,
        [action.prop]: undefined
      };
    }

    default:
      return state;
  }
}
