/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

// core components
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

// Libs
import ReactTable from 'react-table';
import moment from "moment";
import "moment/locale/pt-br";
import { connect } from "react-redux";

// actions
import {
  getDriversQueue
} from "../../../redux/actions/user";

// components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Loading from "components/Loading/Loading.jsx";
import Notification from "components/Notification/Notification";

function DriversQueue(props) {
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState(false);
  const [message, setMessage] = useState(null);
  const [color, setColor] = useState(null);

  const [vehicleType, setVehicleType] = useState(0);

  const [drivers, setDrivers] = useState([]);

  /** EFFECTS */
  useEffect(()=>{
    setLoading(true);
    props.getDriversQueue(vehicleType)
  },[vehicleType])

  useEffect(()=>{
    if (props.driversQueue !== undefined){
      setLoading(false);
      let arr = [];
      props.driversQueue.drivers.map((driver,i) => {
        arr.push({
          position: i + 1,
          name: driver.name,
          plate: driver.plate,
          vehicle: driver.vehicle_description,
          arrival: moment(driver.queued_at).format("DD/MM/YYYY"),
        })
      });
      setDrivers(arr);
      if (props.driversQueue.drivers.length === 0){
        setMessage('Não há motoristas na fila');
        setColor('warning');
        setNotification(true);
      }
    }
  },[props.driversQueue])

  return (
    <>
      {notification ? (
        <Notification
          message={message}
          color={color}
          setNotification={setNotification}
        />
      ) : (
        ""
      )}
      {loading ? (<Loading/>) : (
        <>
          <br/>
          <GridContainer >
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader/>
                <CardBody>
                  <FormControl fullWidth> 
                    <InputLabel htmlFor="user">
                      Filtre por tipo de veículo:
                    </InputLabel>
                    <Select
                      native
                      value={vehicleType}
                      id="requester-select"
                      name="user"
                      fullWidth
                      onChange={e => setVehicleType(e.target.value)}
                    >
                      <option value={0}>
                        Moto
                      </option>
                      <option value={1}>
                        Carro
                      </option>
                    </Select> 
                  </FormControl>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <ReactTable
                    className="-striped -highlight"
                    noDataText="Nenhum veículo encontrado."
                    data={drivers ? drivers : []}
                    resizable={false}
                    sortable={false}
                    loadingText={"Carregando..."}
                    loading={drivers ? false : true}
                    previousText={"Anterior"}
                    nextText={"Próximo"}
                    pageText={"Página"}
                    ofText={"de"}
                    rowsText={"linhas"}
                    showPageSizeOptions={false}
                    defaultPageSize={10}
                    pages={Math.ceil(drivers.length / 10)}
                    columns={[
                      {
                        Header: "Posição",
                        id: "position",
                        accessor: (row) => `${(row.position)}º`,
                        width: 90,
                      },
                      {
                        Header: "Nome",
                        id: "name",
                        accessor: (row) => (row.name),
                      },
                      {
                        Header: "Placa",
                        id: "plate",
                        accessor: (row) => row.plate,
                      },
                      {
                        Header: "Veículo",
                        id: "vehicle",
                        accessor: (row) => row.vehicle,
                      },
                      {
                        Header: "Data de chegada",
                        id: "arrival",
                        accessor: (row) => row.arrival,
                      },
                    ]}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </>
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    userAuth: state.user.userAuth,
    driversQueue: state.user.driversQueue,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDriversQueue: (vehicle_type) => dispatch(getDriversQueue(vehicle_type)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DriversQueue);
