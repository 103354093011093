import { dispatch } from '../../utils/functions';
import * as types from './actionTypes';

export function identifyUser() {
  return dispatch(types.IDENTIFY_USER, '/account/identify');
}
export function addUser(model) {
  return dispatch(types.ADD_USER, '/account/business-signup', model);
}
export function updatePassword(model) {
  return dispatch(types.UPDATE_PASSWORD, '/account/update-password', model);
}
export function getIncome(model) {
  return dispatch(types.GET_INCOME, '/account/income', model);
}
export function resetUser(prop) {
  return { type: types.RESET_USER, prop };
}
export function getAllIncomes(model) {
  return dispatch(types.GET_ALL_INCOMES, '/account/income', model);
}
export function generateBankSlip(model) {
  return dispatch(types.GENERATE_BANK_SLIP, '/account/generate-bankslip', model);
}
export function getTotalCash(id) {
  return dispatch(types.GET_TOTAL_CASH, `/account/cash-amount?requesterId=${id}`);
}
export function getPendingBankSlips(id) {
  return dispatch(types.GET_PENDING_BANK_SLIPS, `/account/list-bankslips?requesterId=${id}`)
}
export function getDriversQueue(vehicle_type){
  return dispatch(types.GET_DRIVERS_QUEUE, `/account/drivers-queue?vehicle=${vehicle_type}`)
}