import React, { useState } from 'react';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
// @material-ui/icons
import PermIdentity from '@material-ui/icons/PermIdentity';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import styles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.js';

const useStyles = makeStyles(styles);

export default function ChangePassword(props) {
  const [oldPass, setOldPass] = useState('');
  const [oldPassState, setOldPassState] = useState('');
  const [newPass, setNewPass] = useState('');
  const [newPassState, setNewPassState] = useState('');
  const [cNewPass, setCNewPass] = useState('');
  const [cNewPassState, setCNewPassState] = useState('');

  /** FUNCTIONS */
  function handleInputChange(e, stateName, setFunc) {
    switch (stateName) {
      case 'old':
        if (e.target.value.length >= 8) {
          setOldPassState('success');
        } else {
          setOldPassState('error');
        }
        break;
      case 'new':
        if (e.target.value.length >= 8 && e.target.value === cNewPass) {
          setNewPassState('success');
          setCNewPassState('success');
        } else {
          setNewPassState('error');
        }
        break;
      case 'cNew':
        if (e.target.value.length >= 8 && e.target.value === newPass) {
          setCNewPassState('success');
          setNewPassState('success');
        } else {
          setCNewPassState('error');
        }
        break;
      default:
        break;
    }
    setFunc(e.target.value);
  }

  function isValidated() {
    if (
      oldPassState === 'success' &&
      newPassState === 'success' &&
      cNewPassState === 'success'
    ) {
      props.updatePassword(oldPass, newPass);
    } else {
      if (oldPassState !== 'success') {
        setOldPassState('error');
      }
      if (newPassState !== 'success') {
        setNewPassState('error');
      }
      if (cNewPassState !== 'success') {
        setCNewPassState('error');
      }
    }
  }

  const classes = useStyles();

  return (
    <div className={classes.content}>
          <div className={classes.container}>
{
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <PermIdentity />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Mudar senha</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  success={oldPassState === 'success'}
                  error={oldPassState === 'error'}
                  labelText="Senha"
                  id="oldpass"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: 'password',
                    onChange: e => handleInputChange(e, 'old', setOldPass)
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  success={newPassState === 'success'}
                  error={newPassState === 'error'}
                  labelText="Nova senha"
                  id="newpass"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: 'password',
                    onChange: e => handleInputChange(e, 'new', setNewPass)
                  }}
                />
                <CustomInput
                  success={cNewPassState === 'success'}
                  error={cNewPassState === 'error'}
                  labelText="Confirmar nova senha"
                  id="cnewpass"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: 'password',
                    onChange: e => handleInputChange(e, 'cNew', setCNewPass)
                  }}
                />
              </GridItem>
            </GridContainer>
            <Button
              color="rose"
              className={classes.updateProfileButton}
              onClick={() => isValidated()}
            >
              Mudar senha
            </Button>
            <Button
              color="white"
              className={classes.updateProfileButton}
              onClick={() => props.setChangePass(false)}
            >
              Voltar
            </Button>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
}</div></div>
  );
}
