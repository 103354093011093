export const GOOGLE_KEY = "AIzaSyDIKW_5YiUjXsBxX0JiiGpb1w73R3nJW04";
export const url = "https://hapi.beelog.app";
export const rideModel = {
  map: "https://beelog.s3.us-east-2.amazonaws.com/dev/ride-images/qgk.png",
  type: { name: "" },
  driver: { name: "" },
  distance: "",
  originAddress: "",
  destinationAddress: "",
  fare: { name: "" },
  measures: null,
  price: {
    distance_price: 1.0,
    volume_price: 1.0,
    insurance_price: 1.0,
    discount_price: 1.0,
    total_price: 1.0,
  },
  status: [
    {
      status_id: 1,
      created_at: "2019-08-14 10:56:12",
      name: "Procurando motorista",
    },
    {
      status_id: 4,
      created_at: "2019-08-14 11:01:56",
      name: "Em rota ao destinatário",
    },
  ],
};

export const cardModel = {
  brand: "",
  expirationMonth: 0,
  expirationYear: 0,
  holderName: "",
  number: "",
};
