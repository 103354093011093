import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
// @material-ui/core components
//import { makeStyles } from '@material-ui/core/styles';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import React, { useState } from 'react';

// import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.js';

// const useStyles = makeStyles(buttonsStyle);

export default function PackageOptions(props) {
  const [packBtn, setPackBtn] = useState('white');
  const [docBtn, setDocBtn] = useState('white');
  const [textPackBtn, setTextPackBtn] = useState('black');
  const [textDocBtn, setTextDocBtn] = useState('black');
  const [num_volumes, setnum_volumes] = useState(props.numVolumes);

  function clickEvent(type) {
    props.setActiveAcord(2);
    if (type === 1) {
      setPackBtn('success');
      setDocBtn('white');
      setTextDocBtn('black');
      setTextPackBtn('white');
      props.setPackageType(1);
    } else if (type === 0) {
      setDocBtn('success');
      setPackBtn('white');
      setTextDocBtn('white');
      setTextPackBtn('black');
      props.setPackageType(0);
    }
  }

  function handleChange(e) {
    if (e.target.value.match(/^[0-9]+$/)) {
      setnum_volumes(e.target.value);
      props.onChange(e.target.value);
    }

    if (e.target.value === '') setnum_volumes(e.target.value);
  }

  // const classes = useStyles();

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
      <GridItem xs={12} sm={12} lg={12}>
            <div style={{ textAlign: 'center' }}>              
              <p>
                <b>Quantos volumes serão transportados ?</b>
              </p>
              <CustomInput
              style={{ color: '#000000', textAlign: 'center' }}
              id="num_volumes"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                placeholder: 'Quantidade de volumes',
                value: num_volumes,
                onChange: (e) => handleChange(e),
                inputProps: { type: 'number' },
              }}
            />
            </div><br></br>
          </GridItem>
        <GridItem md={12}>
          <div style={{ textAlign: 'center' }}>
            <p>
              <b>E qual o tipo de conteúdo?</b>
            </p>
          </div>
          <Button
            color={packBtn}
            fullWidth={true}
            onClick={() => clickEvent(1)}
            style={{ color: textPackBtn }}
          >
            Pacote
          </Button>
          <Button
            color={docBtn}
            fullWidth={true}
            onClick={() => clickEvent(0)}
            style={{ color: textDocBtn }}
          >
            Documentos
          </Button>
        </GridItem>
        <GridContainer>         
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} lg={12}>
            
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
}
