/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
//REDUX
import { connect } from 'react-redux';
import { updatePassword, resetUser } from '../../../redux/actions/user';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
//import InputLabel from '@material-ui/core/InputLabel';
// @material-ui/icons
import PermIdentity from '@material-ui/icons/PermIdentity';
import StoreMallDirectory from '@material-ui/icons/StoreMallDirectory';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import Notification from 'components/Notification/Notification';
import Loading from 'components/Loading/Loading.jsx';

import ChangePassword from './ChangePassword';

import styles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.js';

import adminStyles from 'assets/jss/material-dashboard-pro-react/layouts/adminStyle.js';
const adminUseStyles = makeStyles(adminStyles);

const useStyles = makeStyles(styles);

function Profile(props) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [cpf, setCpf] = useState('');
  const [phone, setPhone] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [address, setAddress] = useState('');
  const [addressNote, setAddressNote] = useState('');
  const [document, setDocument] = useState('');
  const [changePass, setChangePass] = useState(false);
  const [notification, setNotification] = useState(false);
  const [message, setMessage] = useState('');
  const [color, setColor] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.userAuth !== undefined) {
      setName(props.userAuth.name);
      setEmail(props.userAuth.email);
      setCpf(props.userAuth.cpf);
      setPhone(props.userAuth.phone);
      setBusinessName(props.userAuth.business.name);
      setAddress(props.userAuth.business.address);
      setAddressNote(props.userAuth.business.addressNote || '');
      setDocument(props.userAuth.business.document);
    }
  }, [props.userAuth]);

  useEffect(() => {
    if (props.passwordUpdated !== undefined) {
      setLoading(false);
      if (props.passwordUpdated.response) {
        setNotification(true);
        setMessage('Error ao alterar a senha');
        setColor('danger');
      } else {
        props.history.push('/logout');
      }

      props.resetUser('passwordUpdated');
    }
  }, [props.passwordUpdated]);

  function updatePassword(oldPass, newPass) {
    setLoading(true);
    props.updatePassword({
      password: oldPass,
      newPassword: newPass
    });
  }

  const classes = useStyles();
  const adminClasses = adminUseStyles();

  return (
    <div className={adminClasses.content}>
          <div className={adminClasses.container}>
              {
    <div>
      {notification ? (
        <Notification
          message={message}
          color={color}
          setNotification={setNotification}
        />
      ) : (
        ''
      )}
      {loading ? (
        <Loading />
      ) : (
        <GridContainer>
          {changePass ? (
            <ChangePassword
              setChangePass={setChangePass}
              updatePassword={updatePassword}
            />
          ) : (
            <>
              <GridItem xs={12} sm={12} md={6}>
                <Card>
                  <CardHeader color="purple" icon>
                    <CardIcon color="purple">
                      <PermIdentity />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>Usuário</h4>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Nome"
                          id="company-disabled"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            value: name,
                            disabled: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Email"
                          id="company-disabled"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            value: email,
                            disabled: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="CPF"
                          id="company-disabled"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            value: cpf,
                            disabled: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Telefone"
                          id="company-disabled"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            value: phone,
                            disabled: true
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <Button
                      color="purple"
                      className={classes.updateProfileButton}
                      onClick={() => setChangePass(true)}
                    >
                      Mudar senha
                    </Button>
                  </CardBody>
                </Card>
              </GridItem>
              {/* ENTERPRISE CARD */}
              <GridItem xs={12} sm={12} md={6}>
                <Card>
                  <CardHeader color="purple" icon>
                    <CardIcon color="purple">
                      <StoreMallDirectory />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>Empresa</h4>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Nome Comercial"
                          id="company-disabled"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            value: businessName,
                            disabled: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Endereço"
                          id="company-disabled"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            value: address,
                            disabled: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Complemento"
                          id="company-disabled"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            value: addressNote,
                            disabled: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="CNPJ/CPF"
                          id="company-disabled"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            value: document,
                            disabled: true
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
            </>
          )}
        </GridContainer>
      )}
    </div>
}
</div></div>
  );
}

const mapStateToProps = state => {
  return {
    userAuth: state.user.userAuth,
    passwordUpdated: state.user.passwordUpdated
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updatePassword: model => dispatch(updatePassword(model)),
    resetUser: prop => dispatch(resetUser(prop))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile);
