/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-dupe-keys */

import React, { Component, useState, useEffect, useCallback, useRef } from 'react';
// @material-ui/icons
import Add from "@material-ui/icons/Add";
//REDUX
import { connect } from 'react-redux';
import { identifyUser } from '../../../redux/actions/user';
import { getRideHistory, getAllRideHistory, resetRide } from '../../../redux/actions/ride';
import brLocale from "date-fns/locale/pt-BR"
import {
  MuiPickersUtilsProvider,  
  DatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { QRCode } from "react-qr-svg";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import ReactTable from 'react-table';
//checkbox
import PropTypes from 'prop-types'
import { Checkbox } from '@shopify/polaris';
//print
import ReactToPrint from 'react-to-print';
import Print from '@material-ui/icons/Print';
import Search from '@material-ui/icons/SearchOutlined';
import QrCode from 'react.qrcode.generator';
import Barcode from 'react-barcode';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
// @material-ui/icons
import DirectionsCar from "@material-ui/icons/DirectionsCar";
import { formatTimeDate } from "../../../utils/functions";
import { debounce } from "lodash";
import { useDispatch, useSelector } from 'react-redux';
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';

import {CSVLink} from "react-csv";

import { format } from 'date-fns';
import moment from 'moment';
import {
  Select,
  MenuItem,
} from '@material-ui/core';

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import adminStyles from 'assets/jss/material-dashboard-pro-react/layouts/adminStyle.js';
import Dashboard from '../Dashboard/Dashboard';

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  personAdd: {
    textAlign: "right",
  },
};

const useStyles = makeStyles(styles);

const adminUseStyles = makeStyles(adminStyles);

function RideTable(props) {
  const [rideHistory, setRideHistory] = useState({ rides: [], count: 0 });
  const [filterState, setFilterState] = useState({
    ride_id: '',
    externalId: '',
    colDriver: "",
    colRequester: "",
    driver_id: null,
    created_by: null,
    date: null,
    status: '',
    page: 0    
  });

  const debounceLoadData = useCallback(debounce(fetchRides, 1000), []);
  
  const [pageState, setPageState] = useState(0);
  const [timeoutState, setTimeoutState] = useState(undefined);
  const [ allToggled, setAllToggled ] = useState(false);
  const [ toggled, setToggled ] = useState(Array.from(new Array(rideHistory.length), () => false));
  const [ selected, setSelected ] = useState([]);
  const [logo] = React.useState(require('assets/img/logo-beelog-horiz.png'));
  const [logo2] = React.useState(require('assets/img/beethermal2.png'))
  const [riomar] = React.useState(require('assets/img/riomar.png'));
  const [rec] = React.useState(require('assets/img/rec.png'));
  const [urgente] = React.useState(require('assets/img/urgente.png'));
  const [food] = React.useState(require('assets/img/food.png'));
  const [fragil] = React.useState(require('assets/img/fragil.png'));
  const [Img , setImg] = useState();

  const [csvData, setCsvData] = useState([]);
  const [csvLink, setCsvLink] = useState();
  const [count, setCount] = useState();

  const [tableLoading, setTableLoading] = useState(true);
  const [csvButton, setCsvButton] = useState('EXPORTAR');
  const [clicked, setClicked] = useState(false);

  const [csvLoading, setCsvLoading] = useState(false);

  const headers = [
    {
      label: 'Frete',
      key: 'id',
    },
    {
      label: 'Controle',
      key: 'control',
    },
    {
      label: 'Destino',
      key: 'to',
    },
    {
      label: 'Distância (m)',
      key: 'distance',
    },
    {
      label: 'Status',
      key: 'status',
    },
    {
      label: 'Motorista',
      key: 'driver',
    },
    {
      label: 'Solicitado em',
      key: 'requested_at',
    },
    {
      label: 'Aceito em',
      key: 'accepted_at',
    },
    {
      label: 'Coletado em',
      key: 'collected_at',
    },
    {
      label: 'Finalizado em',
      key: 'ended_at',
    },
    {
      label: 'BackOffice',
      key: 'bko',
    },
    {
      label: 'Data/Hora',
      key: 'date',
    },
    {
      label: 'Duração',
      key: 'duration',
    },
    {
      label: 'Tipo de conteúdo',
      key: 'type',
    },
    {
      label: 'Cubagem (m³)',
      key: 'volume',
    },
    {
      label: 'Valor do Frete - Distância',
      key: 'price_distance',
    },
    {
      label: 'Valor do Frete - Volume',
      key: 'price_volume',
    },
    {
      label: 'Valor do Frete - Total',
      key: 'price_total',
    },
    {
      label: 'Valor - Gris',
      key: 'price_gris',
    },
    {
      label: 'Observação',
      key: 'obs',
    },
  ];

  const handleToggle = index => {
    let toggledCopy = [...toggled];
    toggledCopy[index] = !toggledCopy[index];
    setToggled(toggledCopy);
    if( toggledCopy[index] === false ){
      setAllToggled(false);
    }
    else if (allToggled) {
      setAllToggled(false);
    }
  };

  useEffect(()=>{    
    if (props.allHistory !== undefined){      
      let data = [];
      if (props.allHistory.rides.length > 0){
        props.allHistory.rides.map(r=>{
          let diffM = null;
          let diffH = null;
          let diffD = null;
          let type = '';
          let d0 = moment(r.date);
          let d = moment(r.lastStatus.created_at);
          diffM = d.diff(d0,"minutes");
          if (diffM > 60){
            diffH = d.diff(d0,'hours');
          }
          if (diffH > 24){
            diffD = d.diff(d0,'days');
          }
          // Condição para verificar o tipo 
          if (r.is_food){
            type = 'Comida'
          } else if (r.volume !== null || r.measures !== null){
            type = 'Pacote'
          } else {
            type = 'Documento'
          }
          // Verificando os status das corridas
          let requested_at = ''
          let accepted_at = ''
          let collected_at = ''
          let ended_at = ''
          if (r.status.length > 0){
            r.status.map(s => {
              if ( s.status_id === 0 ) { 
                // agendado
              } else if ( s.status_id === 1 ) {
                // procurando motorista
                requested_at = moment(s.created_at).format("DD/MM/YYYY, HH:mm:ss")
              } else if ( s.status_id === 3 ) {
                // em rota ao remetente
                accepted_at = moment(s.created_at).format("DD/MM/YYYY, HH:mm:ss")
              } else if ( s.status_id === 4 ) {
                // em rota ao destinatario
                collected_at = moment(s.created_at).format("DD/MM/YYYY, HH:mm:ss")
              } else if ( s.status_id === 5 ) {
                // cancelado
              } else if ( s.status_id === 6 ) {
                // finalizado
                ended_at = moment(s.created_at).format("DD/MM/YYYY, HH:mm:ss")
              } else if ( s.status_id === 7 ) {
                // expirado
              } else if ( s.status_id === 8 ) {
                // extraviado
              } else if ( s.status_id === 9 ) {
                // nao localizado
              }
            });
          }
          data.push({
            "id": r.id,
            "control": r.external_id ? r.external_id : '',
            "to": r.destinationAddress,
            "distance": r.distance ? r.distance : '',
            'date': r.date,
            'status': r.lastStatus.name,
            'driver': r.driver ? r.driver.name : '',
            'requested_at' : requested_at,
            'accepted_at': accepted_at,
            'collected_at': collected_at,
            'ended_at': ended_at,
            'bko': r.created_by ? r.created_by.name : '',
            'duration': diffD !== null ? diffD + ' dias' 
              : diffH !== null ? diffH + ' horas'
              : diffM > 0 ? diffM + ' min': '0 min',
            'type': type,
            'volume': r.volume === null ? '' : r.volume.calc_weight ,
            'price_distance': r.price ? r.price.distance_price : '',
            'price_volume': r.price ? r.price.volume_price : '',
            'price_total': r.price ? r.price.total_price : '',
            'price_gris' : r.price ? r.price.insurance_price : '',
            'obs': r.lastStatus ? r.lastStatus.note === null ? '' : r.lastStatus.note : ''
          });
        });
        setCsvData(data);
        setCsvLoading(false);
      }
    }
  },[props.allHistory])
  
  // useEffect(()=>{
  //   if (csvData !== undefined && csvLink !== undefined){
  //     console.log('csvLink',csvLink)
  //     if (csvLink.props.data.length > 0 && csvData.length === Number(count)){
  //       setTimeout(()=>{
  //         csvLink.link.click();
  //       },500)
  //       // csvLink.link.click();
  //       props.resetRide('allHistory')
  //     }
  //   }
  // },[csvData,csvLink, count])
  
  useEffect(()=>{
    if (rideHistory !== undefined){
      if (rideHistory.rides){
        setTableLoading(false);
      } 
    } 
  },[rideHistory])

  useEffect(() => {
    if (
      props.userAuth !== undefined &&
      !props.userAuth.response &&
      props.userAuth.business
    ) {
      //fetchRides(filterState)
    }
  }, [props.userAuth]);

  useEffect(() => { 
    clearTimeout(timeoutState);  
    fetchRides(filterState);
    // debounceLoadData(filterState);
  }, [filterState]);

  useEffect(() => {
    if (props.rideHistory !== undefined) {
      
      setRideHistory(props.rideHistory);
      setTableLoading(true);
      setCount(props.rideHistory.count);
      // if (filterState.status !== '' || filterState.date !== null || filterState.page !== 0){
      //   setTimeout(() => {
      //     fetchRides(filterState);
      //   }, 5000)     
      // }
    }
  }, [props.rideHistory]);

  function fetchRides(state) {
        
      props.getRideHistory({
        id: state.ride_id === '' ? null : state.ride_id,
        externalId: state.externalId === '' ? null : state.externalId,
        index: 30 * state.page,
        size: 30,
        date: state.date ? state.date.toISOString().slice(0, 10) : null,
        status: state.status,
        requester: props.userAuth.business.id,
        createdBy: state.colRequester === "" ? null : state.colRequester,
        driver: state.colDriver === "" ? null : state.colDriver,
      });
    
  }

  // styles
  const classes = useStyles();
  const adminClasses = adminUseStyles();
  const componentRef = useRef();
  const componentRef2 = useRef();

  /** FUNCTIONS */
  function redirectRide(id) {
    window.open(`/dashboard/ride/${id}`, '_blank');
  }

  function redirectAdd() {
    props.history.push("/dashboard/ride/map/add");
  }

  function availableStatus(){
    return [
      {id: "0", name: "Agendado"},
      {id: "1", name: "Procurando motorista"},
      {id: "3", name: "Em rota ao remetente"},
      {id: "4", name: "Em rota ao destinatário"},
      {id: "5", name: "Cancelado"},
      {id: "6", name: "Finalizado"},
      {id: "9", name: "Não Localizado"}
    ]
  }
  function redirectNewTab(id){
    var tab = window.open(`${id}`);
    tab.focus();
  }
  
  function addToSelected(ride){

    var aux = selected;
    aux.push(ride);
    setSelected(aux);    
  }

  function removeFromSelected(ride){

    var selectedAux = selected;

    function filterRide(sel){
      return ride.id != sel.id;
    }

    var aux = selectedAux.filter(filterRide);
    setSelected(aux);
  }

  // Prev/Next table page
  function changePage(page) {
    setPageState(page);
    props.getRideHistory({
      index: 30 * (page - 1),
      size: 30,
      requester: props.userAuth.business.id,
    });
  }

  function getRideDriver(ride){
    return ride.driver_id !== null ? ( <h4 style={{fontFamily: "Arial, Narrow-Bold", fontWeight: "bold"}}> {ride.driver_id}  </h4>) : ('')
  }

  function getRidePlate(ride){
    return ride.driver_id ? ( <h4 style={{fontFamily: "Arial, Narrow-Bold", fontWeight: "bold"}}> {ride.driver.driver.vehicle.plate}  </h4>) : ('')
  }

  function getRideType(ride){
    return ride.driver_id !== null ? ( <h4 style={{fontFamily: "Arial, Narrow-Bold", fontWeight: "bold"}}> {ride.vehicle_type.name}  </h4>) : ('')
  }

  function getQRCode(ride){
    return ride.id !== null ? (
      <QRCode
                bgColor="#FFFFFF"
                fgColor="#000000"
                level="Q"
                style={{ width: 200 }}
                value={ride.id}
            />
    ) : ('')
  }

  function getQRCode2(ride){
    return ride.id !== null ? (
      <QRCode
                bgColor="#FFFFFF"
                fgColor="#000000"
                level="Q"
                style={{ width: 50 }}
                value={ride.id}
            />
    ) : ('')
  }

  function getBarCode(ride){
    return  ride.external_id !== null ? (<Barcode displayValue={false} width={4} background={"#ffffff"} linecolor={"#000000"} format={"CODE128"} font={"monospace"} value={ride.external_id}/>) : (<Barcode displayValue={false} width={5}  background={"#ffffff"} linecolor={"#000000"}  format={"CODE128"} font={"monospace"} value={ride.id}/>)
  }

  function getBarCode2(ride){
    return  ride.external_id !== null ? (<Barcode displayValue={false} width={1} background={"#ffffff"} linecolor={"#000000"} format={"CODE128"} font={"monospace"} value={ride.external_id}/>) : (<Barcode displayValue={false} width={1}  background={"#ffffff"} linecolor={"#000000"}  format={"CODE128"} font={"monospace"} value={ride.id}/>)
  }

  function getPeso(ride){
    return ride.volume !== null ? ride.volume.weight : ('')
  }
  
  function getPesoCubado(ride){
    return ride.volume !== null ? ride.volume.calc_weight : ('')
  }
  
  function getReceiverName(ride){
    return ride.receiver_name !== null ? ride.receiver_name : ('')
  }
  
  function getReceiverIntermediate(ride){
    return ride.receiver_intermediate !==null ?  ride.receiver_intermediate : ('')
  }

  function getInsurancetx(ride){
    return ride.insurance !==null ? ride.insurance.tax_number : ('')
  }

  function getImg(ride) {
        if (ride.is_food == 1){ 
         return <img align="center" src={food} width={150}/> 
        } 
        else if (ride.volume || ride.measures){ 
         return <img align="center" src={fragil} width={150}/> 
        }
        else  { 
         return <img align="center" src={urgente} width={150}/>
     }           
  }
  
  function getImg2(ride) {
    if (ride.is_food == 1){ 
     return <img align="center" src={food} width={50}/> 
    } 
    else if (ride.volume || ride.measures){ 
     return <img align="center" src={fragil} width={50}/> 
    }
    else  { 
     return <img align="center" src={urgente} width={50}/>
 }           
}

  function getAllRideHistory(){
    if (count !== undefined){
      setCsvLoading(true);
      console.log('filterState',filterState)
      props.getAllRideHistory({
        index: 0,
        size: count,
        requester: props.userAuth.business.id,
        date: filterState.date ? filterState.date.toISOString().slice(0, 10) : '',
        status: filterState.status ? filterState.status : '',  
        id: filterState.ride_id === '' ? null : filterState.ride_id,
        externalId: filterState.externalId === '' ? null : filterState.externalId,  
        createdBy: filterState.colRequester === "" ? null : filterState.colRequester,
        driver: filterState.colDriver === "" ? null : filterState.colDriver,  
      });
    }
  }

  function downloadCSV(){
    if (csvLink !== undefined){
      csvLink.link.click();
      props.resetRide('allHistory')
    }
    setClicked(false);
    setCsvButton("EXPORTAR")
  }

  function getAllData(){
    getAllRideHistory();
    setCsvButton('PREPARANDO');
    setClicked(true);
  }

  // console.log('csvData',csvData)
  // console.log('count',count)


return (
  <div className={adminClasses.content}>
    <div className={adminClasses.container}>
      <div style={{display: 'none'}}>
        <div ref={componentRef} >
          {
            selected.length > 0 ? (

              selected.map(function(ride, i){    
                      
                return (
                  <>
                  { ride ? (
                  <div style={{ height: "100%" , width: "100%" ,padding: "10px 10px 10px 10px"}}>
                    <table style={{width: "575" , tableLayout: "fixed" , borderCollapse: "collapse", borderSpacing: 1, margin: "2", border: "3", cellpadding: "1", cellspacing: "1"}} className="tg">
                        <tr>
                          <th style={{ width: "150px", height:"150px" , borderColor: "#000000", borderStyle: "solid", borderWidth: 2, fontFamily: "Arial, sans-serif", fontWeight: "normal", overflow: "hidden", padding: "5px 5px", position:"-webkit-sticky", position: "sticky", textAlign: "center", top:"-1px", verticalAlign: "top", willChange: "transform", wordBreak: "break-all"}}
                            >{localStorage.getItem('business') ? <img width={150} height={150} style={{objectFit: 'contain'}} src={JSON.parse(localStorage.getItem("business")).logo}/> : '' }</th>
                          <th colSpan={2} width={250} height={150} style={{borderColor: "#000000", borderStyle: "solid", borderWidth: 2, fontFamily: "Arial, sans-serif", fontWeight: "normal", padding: "5px 5px", position:"-webkit-sticky", position: "sticky", textAlign: "left", top:"-1px", verticalAlign: "top", willChange: "transform", wordBreak:"break-all"}}
                            ><h4><b>ORIGEM</b></h4>
                              {localStorage.getItem('business') ? <b><h6 style={{wordBreak: "normal"}}>Empresa: {JSON.parse(localStorage.getItem("business")).name}</h6></b> : '' }
                              <h6 style={{willChange: "transform", wordBreak: "normal"}}>{JSON.parse(localStorage.getItem("business")).address}</h6>
                              {localStorage.getItem('business') ? <b><h6>CNPJ: {JSON.parse(localStorage.getItem("business")).document}        Telefone: {ride.created_by.phone}</h6></b> : ''}
                            </th>
                        </tr>
                      <tr  >
                          <td style={{borderColor: "#000000", borderStyle: "solid", borderWidth: 2, fontFamily: "Arial, sans-serif", fontWeight: "normal", overflow: "hidden", padding: "5px 5px", position:"-webkit-sticky", position: "sticky", textAlign: "center", top:"-1px", verticalAlign: "top", willChange: "transform", wordBreak: "break-all"}}
                          ><b><label style={{color:"black"}}>NOTA FISCAL</label></b>
                          <b><h4 style={{fontFamily: "Arial, Narrow-Bold", fontWeight: "bold"}}>{getInsurancetx(ride)}</h4></b></td>
                          <td style={{borderColor: "#000000", borderStyle: "solid", borderWidth: 2, fontFamily: "Arial, sans-serif", fontWeight: "normal", overflow: "hidden", padding: "5px 5px", position:"-webkit-sticky", position: "sticky", textAlign: "center", top:"-1px", verticalAlign: "top", willChange: "transform", wordBreak: "break-all"}}
                          ><b><label style={{color:"black"}}>PEDIDO</label></b>
                          <b><h4 style={{fontFamily: "Arial, Narrow-Bold", fontWeight: "bold"}}>{ride.external_id}</h4></b></td>
                          <td style={{borderColor: "#000000", borderStyle: "solid", borderWidth: 2, fontFamily: "Arial, sans-serif", fontWeight: "normal", overflow: "hidden", padding: "5px 5px", position:"-webkit-sticky", position: "sticky", textAlign: "center", top:"-1px", verticalAlign: "top", willChange: "transform", wordBreak: "break-all"}}
                          ><b><label style={{color:"black"}}>VOLUME</label></b>
                          <b><h4 style={{fontFamily: "Arial, Narrow-Bold", fontWeight: "bold"}}>{ride.num_volumes}</h4></b></td>                            
                        </tr>
                        <tr >
                          <td style={{borderColor: "#000000", borderStyle: "solid", borderWidth: 2, fontFamily: "Arial, sans-serif", fontWeight: "normal", overflow: "hidden", padding: "5px 5px", position:"-webkit-sticky", position: "sticky", textAlign: "center", top:"-1px", verticalAlign: "top", willChange: "transform", wordBreak: "break-all"}}
                          ><b><label style={{color:"black"}}>POSTAGEM</label></b>
                          <b><h4 style={{fontFamily: "Arial, Narrow-Bold", fontWeight: "bold"}}>{formatTimeDate(ride.date)[0]} - {formatTimeDate(ride.date)[1]}</h4></b></td>
                          <td style={{borderColor: "#000000", borderStyle: "solid", borderWidth: 2, fontFamily: "Arial, sans-serif", fontWeight: "normal", overflow: "hidden", padding: "5px 5px", position:"-webkit-sticky", position: "sticky", textAlign: "center", top:"-1px", verticalAlign: "top", willChange: "transform", wordBreak: "break-all"}}
                          ><b><label style={{color:"black"}}>PESO REAL</label></b>
                          <b><h4 style={{fontFamily: "Arial, Narrow-Bold", fontWeight: "bold"}}>{getPeso(ride)} kg</h4></b>
                          </td>
                          <td style={{borderColor: "#000000", borderStyle: "solid", borderWidth: 2, fontFamily: "Arial, sans-serif", fontWeight: "normal", overflow: "hidden", padding: "5px 5px", position:"-webkit-sticky", position: "sticky", textAlign: "center", top:"-1px", verticalAlign: "top", willChange: "transform", wordBreak: "break-all"}}
                          ><b><label style={{color:"black"}}>PESO CUBADO</label></b>
                          <b><h4 style={{fontFamily: "Arial, Narrow-Bold", fontWeight: "bold"}}>{getPesoCubado(ride)} kg</h4></b>
                          </td>
                        </tr>
                        <tr >
                          <td  width={250} height={150} style={{borderColor: "#000000", borderStyle: "solid", borderWidth: 2, fontFamily: "Arial, sans-serif", fontWeight: "normal", overflow: "hidden", padding: "5px 5px", position:"-webkit-sticky", position: "sticky", textAlign: "center", top:"-1px", verticalAlign: "top", willChange: "transform", wordBreak: "word-wrap", rowspan: "4"}}
                          ><h4><b >DESTINO</b></h4>
                          {getQRCode(ride)}
                          <h5><b>{ride.id}</b></h5>
                            {getImg(ride)}
                            <hr width={'80%'} style={{borderTop: '2px solid black'}}/>
                            <img src={logo} width={150}/></td>
                          <td colSpan={2} style={{ borderColor: "#000000", borderStyle: "solid", borderWidth: 2, fontFamily: "Arial, sans-serif", fontWeight: "normal", overflow: "hidden", padding: "5px 5px", position:"-webkit-sticky", position: "sticky", textAlign: "left", top:"-1px", verticalAlign: "top", willChange: "transform", wordBreak: "word-wrap", rowspan: "4"}}
                          ><b><h3>DESTINATÁRIO: {getReceiverName(ride)}</h3></b>
                            <h3 style={{wordBreak: "normal"}}>{ride.destinationAddress}</h3>
                            <b><h3>COMPLEMENTO:</h3></b><p/>
                            <h3 style={{wordBreak: "normal"}}>{ride.destination_note}</h3>
                              <hr />
                              <b><h3>A/C: {getReceiverIntermediate(ride)}</h3></b>
                              {getBarCode(ride)}
                          </td>
                        </tr>
                        <tr >
                          <td style={{borderColor: "#000000", borderStyle: "solid", borderWidth: 2, fontFamily: "Arial, sans-serif", fontWeight: "normal", overflow: "hidden", padding: "5px 5px", position:"-webkit-sticky", position: "sticky", textAlign: "center", top:"-1px", verticalAlign: "top", willChange: "transform", wordBreak: "break-all", rowspan: "2"}}
                          ><b><label style={{color:"black"}}>TRANSPORTADOR</label></b>
                          {getRideDriver(ride)}
                          </td>
                          <td style={{borderColor: "#000000", borderStyle: "solid", borderWidth: 2, fontFamily: "Arial, sans-serif", fontWeight: "normal", overflow: "hidden", padding: "5px 5px", position:"-webkit-sticky", position: "sticky", textAlign: "center", top:"-1px", verticalAlign: "top", willChange: "transform", wordBreak: "break-all", rowspan: "2"}}
                          ><b><label style={{color:"black"}}>TIPO DE TRANSPORTE</label></b>
                          {getRideType(ride)}
                          </td>                            
                          <td style={{borderColor: "#000000", borderStyle: "solid", borderWidth: 2, fontFamily: "Arial, sans-serif", fontWeight: "normal", overflow: "hidden", padding: "5px 5px", position:"-webkit-sticky", position: "sticky", textAlign: "center", top:"-1px", verticalAlign: "top", willChange: "transform", wordBreak: "break-all", rowspan: "2"}}
                          ><b><label style={{color:"black"}}>PLACA</label></b>
                          {getRidePlate(ride)}
                          </td>
                        </tr>
                        <tr >
                          <td colSpan={3} style={{borderColor: "#000000", borderStyle: "solid", borderWidth: 2, fontFamily: "Arial, sans-serif", fontWeight: "normal", overflow: "hidden", padding: "5px 5px", position:"-webkit-sticky", position: "sticky", textAlign: "left", top:"-1px", verticalAlign: "top", willChange: "transform", wordBreak: "word-wrap"}}
                          ><b><label style={{color:"black"}}>OBSERVAÇÃO:</label></b>
                          <p>Constitui crime contra a ordem tributária surpimir ou reduzir tributo,ou contribuição social e qualquer acessório (Lei 8.137/90 ART 1,V</p>
                          </td>
                        </tr>
                    </table>
                  </div>
                ) : ( ' ' )
          } </>
              )})
            ) : ' '
          }
        </div>
    </div>

    <div style={{display: 'none'}}>
    // termica
    <div ref={componentRef2} >
          {
            selected.length > 0 ? (

              selected.map(function(ride, i){    
                      
                return (
                  <>
                  { ride ? (
                  <div style={{ height: "100%" , width: "265px" ,padding: "5px 5px 5px 15px"}}>
                       <table style={{width: "250px" , tableLayout: "fixed" , borderCollapse: "collapse", borderSpacing: 1, margin: "2", border: "3", cellpadding: "1", cellspacing: "1"}} className="tg">
                        <tr>
                          <th style={{ width: "75px", height:"50px" , borderColor: "#000000", borderStyle: "solid", borderWidth: 2, fontFamily: "DQ, LQ-Roman, LQ-Sans Serif, OCR-A / -B", fontWeight: "normal", overflow: "hidden", padding: "5px 5px", position:"-webkit-sticky", position: "sticky", textAlign: "center", top:"-1px", verticalAlign: "top", willChange: "transform", wordBreak: "break-all"}}
                            >{localStorage.getItem('business') ? <img width={50} height={50} style={{objectFit: 'contain'}} src={JSON.parse(localStorage.getItem("business")).logo}/> : '' }</th>
                          <th colSpan={2} width="175px" height="50px" style={{borderColor: "#000000", borderStyle: "solid", borderWidth: 2, fontFamily: "DQ, LQ-Roman, LQ-Sans Serif, OCR-A / -B", fontWeight: "normal", padding: "5px 5px", position:"-webkit-sticky", position: "sticky", textAlign: "left", top:"-1px", verticalAlign: "top", willChange: "transform", wordBreak:"break-all"}}
                            ><b style={{wordBreak: "normal", color:"black" , fontSize: "10px"}}>ORIGEM</b><p/>

                              {localStorage.getItem('business') ? <b style={{wordBreak: "normal", color:"black" , fontSize: "10px"}}>Empresa: {JSON.parse(localStorage.getItem("business")).name}</b> : '' }
                              <b><h6 style={{willChange: "transform", wordBreak: "normal", fontWeight: "bold", color:"black" , fontSize: "10px"}}>{JSON.parse(localStorage.getItem("business")).address}</h6></b>
                              {localStorage.getItem('business') ? <b style={{color:"black" , fontSize: "10px" }}>CNPJ: {JSON.parse(localStorage.getItem("business")).document}<p/> Telefone: {ride.created_by.phone}</b> : ''}
                            </th>
                        </tr>
                      <tr  >
                          <td style={{borderColor: "#000000", borderStyle: "solid", borderWidth: 2, fontFamily: "DQ, LQ-Roman, LQ-Sans Serif, OCR-A / -B", fontWeight: "normal", overflow: "hidden", padding: "5px 5px", position:"-webkit-sticky", position: "sticky", textAlign: "center", top:"-1px", verticalAlign: "top", willChange: "transform", wordBreak: "break-all"}}
                          ><b><label style={{wordBreak: "normal", color:"black" , fontSize: "10px", fontWeight: "bold" }}>NOTA FISCAL</label></b>
                          <b><h5 style={{color:"black" ,fontFamily: "Arial, Narrow-Bold", fontWeight: "bold", fontSize: "10px"}}>{getInsurancetx(ride)}</h5></b></td>
                          <td style={{color:"black" ,borderColor: "#000000", borderStyle: "solid", borderWidth: 2, fontFamily: "DQ, LQ-Roman, LQ-Sans Serif, OCR-A / -B", fontWeight: "normal", overflow: "hidden", padding: "5px 5px", position:"-webkit-sticky", position: "sticky", textAlign: "center", top:"-1px", verticalAlign: "top", willChange: "transform", wordBreak: "break-all"}}
                          ><b><label style={{color:"black" , fontSize: "10px",fontWeight: "bold" }}>PEDIDO</label></b>
                          <b><h5 style={{color:"black" ,fontFamily: "Arial, Narrow-Bold", fontWeight: "bold", fontSize: "10px"}}>{ride.external_id}</h5></b></td>
                          <td style={{color:"black" ,borderColor: "#000000", borderStyle: "solid", borderWidth: 2, fontFamily: "DQ, LQ-Roman, LQ-Sans Serif, OCR-A / -B", fontWeight: "normal", overflow: "hidden", padding: "5px 5px", position:"-webkit-sticky", position: "sticky", textAlign: "center", top:"-1px", verticalAlign: "top", willChange: "transform", wordBreak: "break-all"}}
                          ><b><label style={{color:"black" , fontSize: "10px",fontWeight: "bold" }}>VOLUME</label></b>
                          <b><h5 style={{color:"black" ,fontFamily: "Arial, Narrow-Bold", fontWeight: "bold", fontSize: "10px"}}>{ride.num_volumes}</h5></b></td>                            
                        </tr>
                        <tr >
                          <td style={{borderColor: "#000000", borderStyle: "solid", borderWidth: 2, fontFamily: "DQ, LQ-Roman, LQ-Sans Serif, OCR-A / -B", fontWeight: "normal", overflow: "hidden", padding: "5px 5px", position:"-webkit-sticky", position: "sticky", textAlign: "center", top:"-1px", verticalAlign: "top", willChange: "transform", wordBreak: "break-all"}}
                          ><b><label style={{color:"black" , fontSize: "10px",fontWeight: "bold" }}>POSTAGEM</label></b>
                          <b><h5 style={{color:"black" ,fontFamily: "Arial, Narrow-Bold", fontWeight: "bold", fontSize: "10px"}}>{formatTimeDate(ride.date)[0]} - {formatTimeDate(ride.date)[1]}</h5></b></td>
                          <td style={{borderColor: "#000000", borderStyle: "solid", borderWidth: 2, fontFamily: "DQ, LQ-Roman, LQ-Sans Serif, OCR-A / -B", fontWeight: "normal", overflow: "hidden", padding: "5px 5px", position:"-webkit-sticky", position: "sticky", textAlign: "center", top:"-1px", verticalAlign: "top", willChange: "transform", wordBreak: "break-all"}}
                          ><b><label style={{wordBreak: "normal", color:"black" , fontSize: "10px",fontWeight: "bold" }}>PESO REAL</label></b>
                          <b><h5 style={{color:"black" ,fontFamily: "Arial, Narrow-Bold", fontWeight: "bold", fontSize: "10px"}}>{getPeso(ride)} kg</h5></b>
                          </td>
                          <td style={{borderColor: "#000000", borderStyle: "solid", borderWidth: 2, fontFamily: "DQ, LQ-Roman, LQ-Sans Serif, OCR-A / -B", fontWeight: "normal", overflow: "hidden", padding: "5px 5px", position:"-webkit-sticky", position: "sticky", textAlign: "center", top:"-1px", verticalAlign: "top", willChange: "transform", wordBreak: "break-all"}}
                          ><b><label style={{wordBreak: "normal", color:"black" , fontSize: "10px",fontWeight: "bold" }}>PESO CUBADO</label></b>
                          <b><h5 style={{color:"black" ,fontFamily: "Arial, Narrow-Bold", fontWeight: "bold", fontSize: "10px"}}>{getPesoCubado(ride)} kg</h5></b>
                          </td>
                        </tr>
                        <tr >
                          <td width={100} height={50} style={{borderColor: "#000000", borderStyle: "solid", borderWidth: 2, fontFamily: "DQ, LQ-Roman, LQ-Sans Serif, OCR-A / -B", fontWeight: "normal", overflow: "hidden", padding: "5px 5px", position:"-webkit-sticky", position: "sticky", textAlign: "center", top:"-1px", verticalAlign: "top", willChange: "transform", wordBreak: "word-wrap", rowspan: "4"}}
                          ><b style={{color:"black" , fontSize: "10px"}}>DESTINO</b><p/>
                          {getQRCode2(ride)}<p/>
                          <b style={{color:"black" , fontSize: "10px"}}>{ride.id}</b>
                            {getImg2(ride)}
                            <img src={logo2} width={50}/>
                            </td>
                          <td colSpan={2} style={{ borderColor: "#000000", borderStyle: "solid", borderWidth: 2, fontFamily: "DQ, LQ-Roman, LQ-Sans Serif, OCR-A / -B", fontWeight: "normal", overflow: "hidden", padding: "5px 5px", position:"-webkit-sticky", position: "sticky", textAlign: "left", top:"-1px", verticalAlign: "top", willChange: "transform", wordBreak: "word-wrap", rowspan: "4"}}
                          ><b style={{color:"black" ,fontSize: "12px" }}>DESTINATÁRIO: {getReceiverName(ride)}</b><p/>
                            <b style={{color:"black" ,wordBreak: "normal",fontWeight: "bold", fontSize: "10px"}}>{ride.destinationAddress}</b><p/>
                            <b style={{color:"black" ,fontSize: "12px" }}>COMPLEMENTO:</b><p/>
                            <b style={{color:"black" ,wordBreak: "normal",fontWeight: "bold", fontSize: "10px"}}>{ride.destination_note}</b><p/>
                              <hr />
                              <b style={{color:"black" ,fontSize: "10px",fontWeight: "bold" }}>A/C: {getReceiverIntermediate(ride)}</b><p/>
                              {getBarCode2(ride)}
                          </td>
                        </tr>
                        <tr >
                          <td style={{borderColor: "#000000", borderStyle: "solid", borderWidth: 2, fontFamily: "DQ, LQ-Roman, LQ-Sans Serif, OCR-A / -B", fontWeight: "normal", overflow: "hidden", padding: "5px 5px", position:"-webkit-sticky", position: "sticky", textAlign: "center", top:"-1px", verticalAlign: "top", willChange: "transform", wordBreak: "break-all", rowspan: "2"}}
                          ><b><label style={{fontSize: "10px", color:"black",fontWeight: "bold" }}>MOTORISTA</label></b>
                          <b style={{fontSize: "10px", color:"black" ,fontWeight: "bold" }}>{getRideDriver(ride)}</b>
                          </td>
                          <td style={{borderColor: "#000000", borderStyle: "solid", borderWidth: 2, fontFamily: "DQ, LQ-Roman, LQ-Sans Serif, OCR-A / -B", fontWeight: "normal", overflow: "hidden", padding: "5px 5px", position:"-webkit-sticky", position: "sticky", textAlign: "center", top:"-1px", verticalAlign: "top", willChange: "transform", wordBreak: "break-all", rowspan: "2"}}
                          ><b><label style={{color:"black" , fontSize: "10px",textAlign: "left",wordBreak: "normal",fontWeight: "bold" }}>TRANSPORTE</label></b>
                          <b style={{fontSize: "10px",color:"black" ,fontWeight: "bold" }}>{getRideType(ride)}</b>
                          </td>                            
                          <td style={{borderColor: "#000000", borderStyle: "solid", borderWidth: 2, fontFamily: "DQ, LQ-Roman, LQ-Sans Serif, OCR-A / -B", fontWeight: "normal", overflow: "hidden", padding: "5px 5px", position:"-webkit-sticky", position: "sticky", textAlign: "center", top:"-1px", verticalAlign: "top", willChange: "transform", wordBreak: "break-all", rowspan: "2"}}
                          ><b><label style={{color:"black" , fontSize: "10px",fontWeight: "bold" }}>PLACA</label></b>
                          <b style={{fontSize: "10px",color:"black" ,fontWeight: "bold" }}>{getRidePlate(ride)}</b>
                          </td>
                        </tr>
                        <tr >
                          <td colSpan={3} style={{borderColor: "#000000", borderStyle: "solid", borderWidth: 2, fontFamily: "DQ, LQ-Roman, LQ-Sans Serif, OCR-A / -B", fontWeight: "normal", overflow: "hidden", padding: "5px 5px", position:"-webkit-sticky", position: "sticky", textAlign: "left", top:"-1px", verticalAlign: "top", willChange: "transform", wordBreak: "word-wrap"}}
                          ><b><label style={{color:"black" ,fontWeight: "bold" , fontSize: "10px" }}>OBSERVAÇÃO:</label></b>
                          <b style={{fontSize: "10px",color:"black" ,fontWeight: "bold" }}>Constitui crime contra a ordem tributária surpimir ou reduzir tributo,ou contribuição social e qualquer acessório (Lei 8.137/90 ART 1,V</b>
                          </td>
                        </tr>
                    </table>



                 </div>
                    ) : ( ' ' )
                  } </>
                      )})
                    ) : ' '
                  }
                    </div>
                    //termica
                    </div>

      {
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="purple" icon>
                <CardIcon color="purple">
                  <DirectionsCar />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Fretes</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={7}>
                    <div style={{textAlign:'initial', marginTop:15}}>
                      <Typography variant='caption'>Os valores exportados serão iguais aos presentes na tabela no momento da exportação.</Typography>
                    </div>
                  </GridItem>
                  <GridItem xs={5}>
                    <div className={classes.personAdd}>                
                      <Button simple color={'purple'} onClick={() => redirectAdd()}>
                        <Add />
                      </Button>
                      <div style={{float: 'right', marginTop:5}}>                                 
                        { (csvData.length === Number(count) && clicked) ? 
                        <>
                          <CSVLink
                            data={csvData}
                            headers={headers}
                            separator={";"}
                            filename="dataRides.csv"
                            className="hidden"
                            ref={(r) => setCsvLink(r)}
                            target="_blank"/>
                          <Button
                            color="success"
                            size="sm"
                            onClick={()=>downloadCSV()}>
                            BAIXAR
                          </Button>
                        </>
                        :
                        <div style={{
                          position:'relative',
                          margin:1
                        }}>
                          <Button
                            outline
                            size="sm"
                            color="purple"
                            disabled={csvLoading}
                            onClick={()=>getAllData()}>
                            {csvLoading ? "PREPARANDO" : "EXPORTAR"}
                          </Button>
                          {csvLoading && <CircularProgress size={24} style={{
                              color: '#FFF',
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              marginTop: -12,
                              marginLeft: -12}} 
                            />
                          }
                        </div>
                        }
                      </div>
                      <div style={{float: 'right'}}>
                      <CustomDropdown                     
                        buttonText={<Print />}
                        buttonProps={{
                          simple: true,
                            color: "purple"
                        }}
                        dropdownList={[
                            <ReactToPrint
                              trigger={() => <span>Etiqueta A4</span>}
                              content={() => componentRef.current}
                            />,
                            <ReactToPrint
                            trigger={() => <span>Etiqueta Térmica</span>}
                            content={() => componentRef2.current}
                          />
                        ]}
                        />                   
                      </div>                                   
                    </div>
                  </GridItem>
                </GridContainer>
                <ReactTable
                  className="-striped -highlight"
                  noDataText="Nenhum frete encontrado."
                  data={rideHistory.rides}
                  resizable={false}
                  sortable={false}
                  filterable={false}
                  manual
                  loadingText={'Carregando...'}
                  loading={tableLoading}
                  previousText={'Anterior'}
                  nextText={'Próximo'}
                  showPageSizeOptions={false}
                  defaultPageSize={rideHistory.rides ? rideHistory.rides.length : []}
                  pages={Math.ceil(rideHistory.count / 30)}                    
                  getTrProps={(state, rowInfo, column, instance) => {
                    if (rowInfo && rowInfo.row) {                      
                        return {
                            onClick: (e, handleOriginal) => {
                                let present = selected.map(x => x.id).indexOf(rowInfo.original.id);
                                // let selectedCopy = selected;

                                if (present === -1){                                    
                                  addToSelected(rowInfo.original);                                                                            
                                }

                                if (present > -1){
                                  removeFromSelected(rowInfo.original);                                      
                                }

                                handleToggle(rowInfo.index);
                            },
                            style: {
                                background: selected.map(x => x.id).indexOf(rowInfo.original.id)  > -1 ? 'purple' : 'white',
                                color: selected.map(x => x.id).indexOf(rowInfo.original.id) > -1 ? 'white' : 'black'
                            },
                        }
                    }
                    else {
                        return {}
                    }
                  }}
                  onFetchData={(state) => {
                    
                    const obj = {
                      ...filterState,
                      page: state.page,
                      ride_id: '',
                      externalId: '',
                      colDriver: '',
                      colRequester: '',
                    };
    
                    state.filtered.forEach((filter) => {
                      obj[filter.id] = filter.value;
                    });
                    
                    setFilterState(obj);
                  }}
                  columns={[
                    {
                      width: 40,
                  Cell: row => (
                      <input
                          type="checkbox"
                          checked={selected.map(x => x.id).indexOf(row.original.id)  > -1}
                          onChange={() => handleToggle(row.index)}
                      />
                  ),},
                      {  
                      id: 'ride_id',
                      Header: 'Frete',
                      filterable: true,
                      accessor: (row) => {
                        if (row.id) {
                          return row.id;
                        }
                        return '-';
                      },
                    },
                    {
                      id: 'externalId',
                      Header: 'Controle',
                      filterable: true,
                      accessor: (row) => {
                        if (row.id) {
                          return row.external_id;
                        }
                        return '-';
                      },
                    },
                    {
                      Header: 'Destino',
                      accessor: 'destinationAddress',
                    },
                    {
                      id: 'colDistance',
                      Header: 'Distância',
                      accessor: (row) =>
                        `${(row.distance / 1000)
                          .toFixed(1)
                          .replace('.', ',')} km`,
                    },
                    {
                      id: 'colStatus',
                      Header: 'Status',
                      filterable: true,
                      Filter: (
                        <Select
                          id="status-select"
                          fullWidth
                          value={filterState.status}
                          onChange={(e) =>
                            setFilterState({
                              ...filterState,
                              status: e.target.value,
                            })
                          }
                        >
                          <MenuItem value="">Qualquer</MenuItem>
                          {availableStatus().map((status) => (
                            <MenuItem key={status.id} value={status.id}>
                              {status.name}
                            </MenuItem>
                          ))}
                          </Select>)
                        ,
                            accessor: (row) =>
                              row.lastStatus ? row.lastStatus.name : '-',
                    },
                    {
                      id: "colDriver",
                      Header: "Motorista",
                      filterable: true,
                      accessor: (row) => {
                        if (row.driver_id) {
                          return row.driver.name;
                        }
                        return "-";
                      },
                    },
                    {
                      id: "colRequester",
                      Header: "BackOffice",
                      filterable: true,
                      accessor: (row) => {
                        if (row.created_by) {
                          return row.created_by.name;
                        }
                        return "-";
                      },
                    },
                    {
                      id: 'colCreatedAt',
                      Header: 'Data',                        
                      filterable: true,
                      Filter: (
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
                          <DatePicker
                            style={{marginTop: "-10px"}}                   
                            variant="dialog"
                            format="yyyy-MM-dd"
                            id="date-picker-inline"                              
                            value={filterState.date}
                            emptyLabel=""
                            okLabel="OK"
                            cancelLabel="Fechar"
                            clearLabel="Limpar"
                            labelFunc={(date, invalidLavel) => {
                              return date ? format(date, 'dd/MM/yyyy') : ''
                            }}
                            onChange={(date) => {                                
                              setFilterState(
                                {
                                  ...filterState,
                                  date: date
                                }
                              )
                            }}
                            clearable={true}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />

                        </MuiPickersUtilsProvider>
                      ),
                      accessor: (row) =>
                        row.lastStatus
                          ? `${
                              formatTimeDate(row.lastStatus.created_at)[0]
                            } \n ${
                              formatTimeDate(row.lastStatus.created_at)[1]
                            }`
                          : '~',
                    },
                    {
                      id: 'Detalhamento',
                      width: 75,
                      accessor: (row) =>
                          <Button simple color={'purple'} onClick={() => redirectNewTab(row.id)}><Search/></Button>
                      },
                      ]}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      }
    </div>
  </div>
  );
}
const mapStateToProps = (state) => {
  return {
    userAuth: state.user.userAuth,
    rideHistory: state.ride.rideHistory,
    allHistory: state.ride.allHistory,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    identifyUser: () => dispatch(identifyUser()),
    getRideHistory: (model) => dispatch(getRideHistory(model)),
    getAllRideHistory: (model) => dispatch(getAllRideHistory(model)),
    resetRide: (prop) => dispatch(resetRide(prop))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RideTable);