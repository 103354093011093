/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useReducer } from "react";
//REDUX
import { connect } from "react-redux";
import { identifyUser } from "../../../redux/actions/user";
//import { setLoading } from '../../../redux/actions/common';
import {
  simulateRide,
  requestRide,
  resetRide,
  scheduleRide,
  validateRide,
  batchSimulateRide,
} from "../../../redux/actions/ride";
//core components
import FullScreenMap from "components/Ride/FullScreenMap";
import Destination from "components/Ride/Destination";
import VehicleOptions from "components/Ride/VehicleOptions";
import PackageOptions from "components/Ride/PackageOptions";
import VolumeOptions from "components/Ride/VolumeOptions";
import ScheduleOptions from "components/Ride/ScheduleOptions";
import Insurance from "components/Ride/Insurance";
import RideCheckout from "components/Ride/RideCheckout";
import RideOptimize from "components/Ride/RideOptimize";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Notification from "components/Notification/Notification";
import Loading from "components/Loading/Loading.jsx";
import CustomInput from "components/CustomInput/CustomInput.js";
import "../../../extra.css";
import Origin from "../../../components/Ride/Origin";
import CanceledRide from "../../../components/Ride/CanceledRide";
// import { makeStyles } from "@material-ui/core/styles";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { format, parse } from "date-fns";

import styles from "assets/jss/material-dashboard-pro-react/components/accordionStyle.js";
import { usePrevious } from "../../../utils/hooks";

// const useStyles = makeStyles(styles);

const formatter = new Intl.NumberFormat("pt-BR", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const cstyles = {
  btnDiv: {
    textAlign: "right",
  },
};

/*
 * basicamente o fluxo de implementacao para multiplas corridas eh
 * - criar um array de corridas
 * - modificar o mesmo de acordo com as acoes do usuario
 */

/**
 *
 * @param {{rides: Array}} state
 * @param {{type: string, id: number, ride: object}} action
 *
 * o reducer eh responsavel por, de acordo com a acao do usuario, manipular de alguma forma o array de rides
 */
function reducer(state, action) {
  switch (action.type) {
    case "add":
      state.rides.push(action.ride);
      return state;
    case "edit":
      state.rides[action.id] = { ...state.rides[action.id], ...action.ride };
      if (
        state.rides.length > 1 &&
        action.id !== state.rides.length - 1 &&
        state.rides[action.id].destinationAddress !==
          state.rides[action.id + 1].originAddress
      ) {
        state.rides[action.id + 1].originAddress =
          state.rides[action.id].destinationAddress;
        state.rides[action.id + 1].origin = state.rides[action.id].destination;
      }
      return state;
    case "reset":
      state = { rides: [{ ...initialRide, ...action.ride }] };
      return state;
    default:
      return state;
  }
}

const initialRide = {
  origin: undefined,
  originAddress: "",
  destination: undefined,
  destinationAddress: "",
  destinationNote: "",
  distance: undefined,
  distanceInt: undefined,
  estimated_duration: undefined,
  vehicle: undefined,
  packageType: undefined,
  weight: undefined,
  isFood: undefined,
  simulation: undefined,
  insurance: 0,
  phone: "",
  date: undefined,
  path: undefined,
  externalId: undefined,
  receiverName: undefined,
  receiverIntermediate: undefined,
  numVolumes: 1,
  taxNumber: undefined,
  note: "",
  measures: {
    width: null,
    length: null,
    height: null,
    weight: null,
    calcWeight: null,
  },
};

const initialState = {
  ...initialRide,
  checkout: false,
  optimize: false,
  card: undefined,
  notification: false,
  message: "",
  loading: false,
  activeAcord: -1,
  color: "danger",
};

function RideAdd(props) {
  const [state, setState] = useReducer(
    (state, newState) => ({
      ...state,
      ...newState,
    }),
    initialState
  );

  // ridesState é a nossa variavel que contem todas as rides
  const [ridesState, dispatch] = useReducer(reducer, {
    rides: [initialRide],
  });

  // index controlador de qual ride esta na tela no momento
  const [currentRide, setCurrentRide] = useState(0);
  const prevCurrentRide = usePrevious(currentRide);
  const [saveIndex, setSaveIndex] = useState(0);

  const [batchSimulation, setBatchSimulation] = useState();
  const [optimized, setOptimized] = useState(false);

  // Pegar as infos da corrida cancelada
  const [rideCanceledDetails, setRideCanceledDetails] = useState();
  const [canceledSimulation, setCanceledSimulation] = useState();
  const [canceledBatchSimulation, setCanceledBatchSimulation] = useState();
   
  // funcao controller de tudo que deve acontecer quando o usuario deseja inserir outra ride
  function handleAddRide() {
    const { destination, destinationAddress, vehicle } = state;
    // adicionamos uma nova ride a origem no atual destino
    dispatch({
      type: "add",
      ride: {
        ...initialRide,
        origin: destination,
        originAddress: destinationAddress,
        vehicle: vehicle,
      },
    });

    // mudamos o index de ride na tela
    setCurrentRide(currentRide + 1);
  }

  const [flag, setFlag] = useState("");

  // funcao para salvar as informacoes da tela em uma determidada ride no array
  function saveRide(index, flag) {
    const {
      origin,
      originAddress,
      destination,
      destinationAddress,
      destinationNote,
      distance,
      distanceInt,
      estimated_duration,
      vehicle,
      taxNumber,
      externalId,
      numVolumes,
      date,
      packageType,
      weight,
      simulation,
      insurance,
      phone,
      receiverName,
      receiverIntermediate,
      path,
      isFood,
      note,
      measures,
    } = state;

    dispatch({
      type: "edit",
      id: index,
      ride: {
        origin,
        originAddress,
        destination,
        destinationAddress,
        destinationNote,
        distance,
        distanceInt,
        estimated_duration,
        vehicle,
        taxNumber,
        date,
        externalId,
        numVolumes,
        packageType,
        weight,
        isFood,
        simulation,
        insurance,
        phone,
        receiverName,
        receiverIntermediate,
        path,
        note,
        measures,
      },
    });

    setSaveIndex(saveIndex + 1);
    if (flag === "save") setFlag(flag);
  }

  // funcao para carregar em tela alguma ride do array
  function loadRide(index) {
    setState({
      ...initialState,
      ...ridesState.rides[index],
      simulation: undefined,
    });
  }

  // Pegar as infos da corrida cancelada
  useEffect(()=>{
    if (props.location.state !== undefined){
      setRideCanceledDetails(props.location.state.rideCanceledDetails);
      setCanceledSimulation(true);
    }
  },[props.location.state])

  useEffect(()=>{
    if (canceledSimulation !== undefined){
      if (!canceledSimulation){
        setRideCanceledDetails();
      }
    }
  },[canceledSimulation])

  useEffect(() => {
    if (props.userAuth)
      dispatch({
        type: "reset",
        ride: {
          origin: {
            lat: props.userAuth.business.latitude,
            lng: props.userAuth.business.longitude,
          },
          originAddress: props.userAuth.business.address,
          originNote: props.userAuth.business.addressNote,
        },
      });
  }, []);

  // observador para quando o usuario trocar de ride, salvar as informacoes da ride anterior no array e carregar a proxima ride em tela
  useEffect(() => {
    if (currentRide >= 0) {
      saveRide(prevCurrentRide);
    }
  }, [currentRide]);

  useEffect(() => {
    if (saveIndex >= 1) {
      loadRide(currentRide);
    }
  }, [saveIndex]);

  useEffect(() => {
    if (flag === "save") requestRide();
  }, [flag]);

  useEffect(() => {
    if (props.userAuth !== undefined) {
      if (!props.userAuth.response && props.userAuth.business) {
        setState({
          origin: {
            lat: props.userAuth.business.latitude,
            lng: props.userAuth.business.longitude,
          },
          originAddress: props.userAuth.business.address,
        });
      }

      return function cleanup() {
        props.resetRide("rideSimulated");
      };
    }
  }, [props.userAuth]);

  useEffect(() => {
    if (props.rideSimulated !== undefined) {
      console.log(`props.rideSimulated`,props.rideSimulated)
      setState({ loading: false });
      if (props.rideSimulated.response) {
        setState({
          notification: true,
          color: "danger",
          message: "Erro ao obter preços",
        });
        //props.resetRide('rideSimulated');
      } else {
        setState({ simulation: props.rideSimulated });
      }
    }
  }, [props.rideSimulated]);

  // observador da request de validacao
  useEffect(() => {
    if (props.rideStatus !== undefined && props.rideStatus !== null) {
      setState({ loading: false });

      // abaixo pseudo codigo para reacao da request de validacao
      if (props.rideStatus.data === true) handleAddRide();
      else
        setState({
          notification: true,
          color: "danger",
          message: "Erro ao validar frete. Tente novamente.",
        });
    }
    return function cleanup() {
      props.resetRide("rideStatus");
    };
  }, [props.rideStatus]);

  useEffect(() => {
    if (props.rideRequested !== undefined) {
      setState({ loading: false });
      if (props.rideRequested.response) {
        if (props.rideRequested.response.data.error === "INSUFICIENT_BALANCE") {
          setState({
            notification: true,
            color: "danger",
            message: "Saldo insuficiente",
          });
        } else {
          setState({
            notification: true,
            color: "danger",
            message: "Erro ao solicitar frete",
          });
        }
      } else {
        setState({
          notification: true,
          color: "success",
          message: "Frete solicitado com sucesso.",
        });
        setTimeout(3000, props.history.push("/dashboard/ride/table"));
      }

      props.resetRide("rideRequested");
    }
  }, [props.rideRequested]);

  useEffect(() => {
    if (props.scheduledRide !== undefined) {
      setState({ loading: false });
      if (props.scheduledRide.response) {
        setState({
          notification: true,
          message: "Error ao agendar frete",
        });
      } else {
        props.history.push("/dashboard/ride/table");
      }

      props.resetRide("scheduledRide");
    }
  }, [props.scheduledRide]);

  useEffect(() => {
    if (state.simulation !== undefined) {
      openCheckout();
    }
  }, [state.simulation]);

  useEffect(() => {
    if (props.rideBatched !== undefined) {
      setBatchSimulation(props.rideBatched);
      setState({ loading: false });
      if (rideCanceledDetails !== undefined){
        setCanceledBatchSimulation(props.rideBatched);
        setState({ loading: false });
      }
    }
  }, [props.rideBatched]);

  const openCheckout = () => {
    setState({ checkout: !state.checkout });
  };

  useEffect(() => {
    if (ridesState.rides.length > 1) {
      openOptimize();
    }
  }, [ridesState]);

  const openOptimize = () => {
    setState({ optimize: !state.optimize });
  };

  function vehicleName(value) {
    return value === 0 ? "Moto" : "Carro";
  }

  function packageName(value) {
    return value === 0 ? "Documentos" : "Pacote";
  }

  const handleChange = (panel) => (event, newExpanded) => {
    setState({ activeAcord: state.activeAcord === panel ? -2 : panel });
  };

  function simulateRide() {
    setState({ loading: true });

    props.simulateRide({
      distance: state.distanceInt,
      estimated_duration: state.duration,
      type_of_content: state.packageType,
      product_price: parseFloat(state.insurance),
      vehicle_type: state.vehicle,
      volume: state.measures.calcWeight ? state.measures : null,
      food: state.isFood,
      requester: props.userAuth.business.id,
      origin_latitude: state.origin.lat,
      origin_longitude: state.origin.lng,
    });
  }

  function batchSimulateRide() {
    setState({ loading: true });

    let ridesArr = [];
    ridesState.rides.map((r) => {
      ridesArr.push({
        origin_latitude: r.origin.lat,
        origin_longitude: r.origin.lng,
        destination_latitude: r.destination.lat,
        destination_longitude: r.destination.lng,
        originAddress: r.originAddress,
        destinationAddress: r.destinationAddress,
        distance: r.distanceInt,
        measures_id: "",
        product_price: r.insurance,
        type_of_content: r.packageType,
        vehicle_type: r.vehicle,
      });
    });

    props.batchSimulateRide({
      requester: props.userAuth.business.id,
      rides: ridesArr,
    });
  }

  function batchSimulateCanceledRide() {
    setState({ loading: true });

    let ridesArr = [];
    rideCanceledDetails.map((r) => {
      let type = (r.measures || r.volume || r.is_food == 1
        ? 1
        : 0
      ) // Documento -> 0, Pacote -> 1
      ridesArr.push({
        origin_latitude: r.origin_latitude,
        origin_longitude: r.origin_longitude,
        destination_latitude: r.destination_latitude,
        destination_longitude: r.destination_longitude,
        originAddress: r.originAddress,
        destinationAddress: r.destinationAddress,
        distance: r.distance,
        measures_id: "",
        product_price: r.insurance !== null && type != 0 ? parseFloat(r.insurance.product_price) : null,
        is_food: r.is_food,
        type_of_content: type,
        vehicle_type: r.vehicle_type.id,
      });
    });

    props.batchSimulateRide({
      requester: props.userAuth.business.id,
      rides: ridesArr,
    });
  }

  useEffect(()=>{
    if (rideCanceledDetails !== undefined){
      batchSimulateCanceledRide();
    }
  },[rideCanceledDetails])

  function formatRideForBack(ride) {
    var phoneRegex = /^\([1-9]{2}\) (?:9[1-9])[0-9]{2,3}\-[0-9]{4}$/;
    let phoneNumber = "";
    if (phoneRegex.test(ride.phone)) {
      phoneNumber = ride.phone;
    } else {
      phoneNumber = "";
    }

    return {
      origin_latitude: ride.origin.lat,
      origin_longitude: ride.origin.lng,
      destination_latitude: ride.destination.lat,
      destination_longitude: ride.destination.lng,
      destination_note: ride.destinationNote,
      originAddress: ride.originAddress,
      destinationAddress: ride.destinationAddress,
      distance: ride.distanceInt,
      estimated_duration: ride.estimated_duration,
      payment_method: 3,
      measures_id: ride.weight,
      external_id: ride.externalId,
      num_volumes: ride.numVolumes,
      tax_number: ride.taxNumber,
      date: ride.date,
      path: ride.path,
      is_food: ride.isFood,
      product_price: parseFloat(ride.insurance),
      receiver_phone: phoneNumber,
      receiver_name: ride.receiverName,
      receiver_intermediate: ride.receiverIntermediate,
      type_of_content: ride.packageType,
      vehicle_type: ride.vehicle,
      note: ride.note,
      card: ride.card,
      requester: props.userAuth.business.id,
      volume: ride.measures.calcWeight ? ride.measures : null,
    };
  }

  function requestRide(optimize = false) {
    setState({ loading: true });
    const rides = {
      rides: ridesState.rides.map(formatRideForBack),
      optimize: optimized ? optimized : optimize,
    };
    props.requestRide(rides);

    // if (schedule === 'now' && schedule !== 'error') {
    //   props.requestRide(ride);
    // } else {
    //   ride.date = schedule;
    //   props.scheduleRide(ride);
    // }
  }

  function formatCanceledRideForBack(ride) {
    var phoneRegex = /^\([1-9]{2}\) (?:9[1-9])[0-9]{2,3}\-[0-9]{4}$/;
    let phoneNumber = "";
    if (phoneRegex.test(ride.phone)) {
      phoneNumber = ride.phone;
    } else {
      phoneNumber = "";
    }
    let type = (ride.measures || ride.volume || ride.is_food == 1
      ? 1
      : 0)

    return {
      origin_latitude: ride.origin_latitude,
      origin_longitude: ride.origin_longitude,
      destination_latitude: ride.destination_latitude,
      destination_longitude: ride.destination_longitude,
      destination_note: ride.destinationNote,
      originAddress: ride.originAddress,
      destinationAddress: ride.destinationAddress,
      distance: ride.distance,
      estimated_duration: ride.estimated_duration,
      payment_method: 3,
      measures_id: ride.measures !== null ? ride.measures : '',
      external_id: ride.external_id,
      num_volumes: ride.num_volumes,
      tax_number: ride.insurance.tax_number,
      date: null,
      path: ride.path,
      is_food: ride.is_food,
      product_price: ride.insurance !== null && type != 0 ? parseFloat(ride.insurance.product_price) : null,
      receiver_phone: phoneNumber,
      receiver_name: ride.receiver_name,
      receiver_intermediate: ride.receiver_intermediate,
      type_of_content: type,
      vehicle_type: ride.vehicle_type.id,
      note: ride.note,
      card: ride.card,
      requester: props.userAuth.business.id,
      volume: ride.volume !== null ? ride.volume : null,
    };
  }

  function requestCanceledRide(optimize) {
    setState({ loading: true });
    const rides = {
      rides: rideCanceledDetails.map(formatCanceledRideForBack),
      optimize: optimize,
    };
    console.log("requestCanceledRide rides", rides)
    props.requestRide(rides);
  }

  const map = useMemo(
    () => (
      <FullScreenMap
        origin={state.origin}
        destination={state.destination}
        setDistance={(v) => setState({ distance: v })}
        setDistanceInt={(v) => setState({ distanceInt: v })}
        setPath={(v) => setState({ path: v })}
        setDuration={(v) => setState({ estimated_duration: v })}
      />
    ),
    [state.origin, state.destination]
  );

  return (
    <>
      {state.notification ? (
        <Notification
          message={state.message}
          color={state.color}
          setNotification={(v) => setState({ notification: v })}
        />
      ) : (
        ""
      )}
      {state.loading ? (
        <Loading />
      ) : state.origin ? (
        <div>
          <div style={{ position: "relative", zIndex: 2 }}>{map}</div>
          <div
            className="overflowRideAdd"
            style={{
              position: "absolute",
              top: "70px",
              left: "2.5%",
              width: "95%",
              maxHeight: "90%",
              overflowY: "auto",
              scrollbarWidth: "none",
              overflowX: "hidden",
              zIndex: 3,
            }}
          >
            <GridContainer>
              {rideCanceledDetails !== undefined  && canceledSimulation ? (
                <CanceledRide 
                  rides={rideCanceledDetails}
                  canceledBatchSimulation={canceledBatchSimulation}
                  requestCanceledRide={requestCanceledRide}
                  setCanceledSimulation={setCanceledSimulation}
                />
              ):('')}
              {state.optimize ? (
                <RideOptimize
                  openOptimize={openOptimize}
                  openCheckout={openCheckout}
                  rides={ridesState.rides}
                  batchSimulation={batchSimulation}
                  optimized={setOptimized}
                  requestRide={() => {
                    saveRide(currentRide, "save");
                  }}
                />
              ) : (
                ""
              )}
              {state.checkout ? (
                <div>
                  <GridItem xs={12} sm={12} md={12} lg={4}></GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={4}>
                    <RideCheckout
                      batchSimulation={batchSimulation}
                      rides={ridesState.rides}
                      history={props.history}
                      openCheckout={openCheckout}
                      openOptimize={openOptimize}
                      simulation={state.simulation}
                      externalId={state.externalId}
                      receiverName={state.receiverName}
                      receiverIntermediate={state.receiverIntermediate}
                      num_volumes={state.num_volumes}
                      note={state.note}
                      setState={setState}
                      setExternalId={(v) => setState({ externalId: v })}
                      setPhone={(v) => setState({ phone: v })}
                      setNote={(v) => setState({ note: v })}
                      setCard={(v) => setState({ card: v })}
                      setReceiverName={(v) => setState({ receiverName: v })}
                      setReceiverIntermediate={(v) =>
                        setState({ receiverIntermediate: v })
                      }
                      requestRide={() => {
                        saveRide(currentRide, "save");
                      }}
                      addRideCallback={() => {
                        if (state.externalId && state.externalId.length > 0) {
                          props.validateRide(
                            formatRideForBack(ridesState.rides[currentRide])
                          );
                        } else {
                          setState({
                            notification: true,
                            color: "danger",
                            message: "Número do pedido obrigatório",
                          });
                        }
                      }}
                      editCallback={() => {
                        if (state.externalId && state.externalId.length > 0) {
                          saveRide(currentRide);
                        } else {
                          setState({
                            notification: true,
                            color: "danger",
                            message: "Número do pedido obrigatório",
                          });
                        }
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={4}></GridItem>
                </div>
              ) : (
                ""
              )}

              <GridItem xs={12} sm={12} md={12} lg={5}>
                <Card>
                  <CardHeader>
                    {ridesState.rides.length > 1 ? (
                      ridesState.rides.map((ride, i) => (
                        <Button
                          key={i}
                          color={i === currentRide ? "primary" : "default"}
                          round
                          onClick={() => setCurrentRide(i)}
                        >
                          {i + 1}
                        </Button>
                      ))
                    ) : (
                      <div />
                    )}
                  </CardHeader>
                  <CardBody>
                    <Origin
                      disabled={state.originAddress && currentRide > 0}
                      setOrigin={(v) => setState({ origin: v })}
                      setOriginAddress={(v) => setState({ originAddress: v })}
                      originAddress={state.originAddress}
                      userAuth={props.userAuth}
                    />
                    <Destination
                      setDestination={(v) => setState({ destination: v })}
                      setDestinationAddress={(v) =>
                        setState({ destinationAddress: v })
                      }
                      destinationAddress={state.destinationAddress}
                      userAuth={props.userAuth}
                    />

                    {state.destinationAddress ? (
                      <CustomInput
                        style={{ color: "#000000" }}
                        labelText="O endereço possui complemento?"
                        id="destination_note"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: state.destinationNote,
                          onChange: (e) => {
                            setState({
                              destinationNote:
                                e.target.value.length <= 200
                                  ? e.target.value
                                  : state.destinationNote,
                            });
                          },
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </CardBody>
                </Card>

                {state.destinationAddress ? (
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      {/* <ScheduleRide setSchedule={setSchedule} /> */}
                      <ExpansionPanel
                        square
                        expanded={state.activeAcord === -1}
                        onChange={handleChange(-1)}
                      >
                        <ExpansionPanelSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1d-content"
                          id="panel1d-header"
                        >
                          <Typography>
                            {" "}
                            {state.date == null
                              ? "Frete Imediato"
                              : `Frete agendado para: ${format(
                                  parse(
                                    state.date,
                                    "yyyy-MM-dd HH:mm:ss",
                                    new Date()
                                  ),
                                  "dd/MM/yyyy HH:mm"
                                )}`}
                          </Typography>
                        </ExpansionPanelSummary>
                        {currentRide == 0 ? (
                          <ExpansionPanelDetails>
                            <ScheduleOptions
                              date={state.date}
                              setDate={(v) => setState({ date: v })}
                              setActiveAcord={(v) =>
                                setState({ activeAcord: v })
                              }
                            />
                          </ExpansionPanelDetails>
                        ) : (
                          ""
                        )}
                      </ExpansionPanel>
                      <ExpansionPanel
                        square
                        expanded={state.activeAcord === 0}
                        onChange={handleChange(0)}
                      >
                        <ExpansionPanelSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1d-content"
                          id="panel1d-header"
                        >
                          <Typography>
                            {" "}
                            {state.vehicle == null
                              ? "Qual o tipo de veículo?"
                              : `Veículo: ${
                                  state.vehicle != null
                                    ? vehicleName(state.vehicle)
                                    : ""
                                }`}
                          </Typography>
                        </ExpansionPanelSummary>
                        {currentRide == 0 ? (
                          <ExpansionPanelDetails>
                            <VehicleOptions
                              vehicle={state.vehicle}
                              setVehicle={(v) => setState({ vehicle: v })}
                              setActiveAcord={(v) =>
                                setState({ activeAcord: v })
                              }
                            />
                          </ExpansionPanelDetails>
                        ) : (
                          ""
                        )}
                      </ExpansionPanel>

                      {state.vehicle != null ? (
                        <ExpansionPanel
                          square
                          expanded={state.activeAcord === 1}
                          onChange={handleChange(1)}
                        >
                          <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2d-content"
                            id="panel2d-header"
                          >
                            <Typography>
                              {" "}
                              {state.packageType == null
                                ? "E o tipo de conteúdo?"
                                : `Conteúdo: ${
                                    state.packageType != null
                                      ? packageName(state.packageType)
                                      : ""
                                  }`}
                            </Typography>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails>
                            <PackageOptions
                              numVolumes={state.numVolumes.toString()}
                              onChange={(v) =>
                                setState({ numVolumes: +v > 0 ? +v : 1 })
                              }
                              setPackageType={(v) =>
                                setState({ packageType: v })
                              }
                              setActiveAcord={(v) =>
                                setState({ activeAcord: v })
                              }
                            />
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      ) : (
                        ""
                      )}

                      {state.packageType == 1 ? (
                        <ExpansionPanel
                          square
                          expanded={state.activeAcord === 2}
                          onChange={handleChange(2)}
                        >
                          <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3d-content"
                            id="panel3d-header"
                          >
                            <Typography>
                              {state.measures.calcWeight == null
                                ? state.isFood
                                  ? "Transporte de Alimentos"
                                  : "Quais as dimensões do pacote?"
                                : `Cubagem: ${formatter.format(
                                    state.measures.calcWeight
                                  )}kg`}
                            </Typography>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails>
                            <VolumeOptions
                              weight={state.weight}
                              setWeight={(v) => setState({ weight: v })}
                              vehicle={state.vehicle}
                              isFood={state.isFood}
                              setIsFood={(v) => setState({ isFood: v })}
                              setMeasures={(v) => setState({ measures: v })}
                              setActiveAcord={(v) =>
                                setState({ activeAcord: v })
                              }
                            />
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      ) : (
                        ""
                      )}

                      {state.packageType == 1 ? (
                        <ExpansionPanel
                          square
                          expanded={state.activeAcord === 3}
                          onChange={handleChange(3)}
                        >
                          <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3d-content"
                            id="panel3d-header"
                          >
                            <Typography>
                              {state.insurance == null
                                ? "Qual o valor dos produtos transportados?"
                                : `Valor dos produtos: R$${formatter.format(
                                    state.insurance
                                  )}`}
                            </Typography>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails>
                            <Insurance
                              insurance={formatter.format(state.insurance)}
                              taxNumber={state.taxNumber}
                              setTaxNumber={(v) => setState({ taxNumber: v })}
                              setInsurance={(v) => setState({ insurance: v })}
                              setActiveAcord={(v) =>
                                setState({ activeAcord: v })
                              }
                            />
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      ) : (
                        ""
                      )}

                      {state.destination &&
                      state.distance &&
                      (state.vehicle === 1 || state.vehicle === 0) &&
                      ((state.packageType === 1 &&
                        state.insurance >= 0 &&
                        (state.isFood || state.measures.calcWeight)) ||
                        state.packageType === 0) ? (
                        <div style={cstyles.btnDiv}>
                          <Button
                            color="success"
                            onClick={() => {
                              saveRide(currentRide);
                              simulateRide();
                              batchSimulateRide();
                            }}
                          >
                            Próximo
                          </Button>
                        </div>
                      ) : (
                        ""
                      )}
                    </GridItem>
                  </GridContainer>
                ) : (
                  <div style={{ height: "300px" }}></div>
                )}
              </GridItem>
              <GridItem xs={12} sm={12} md={12} lg={4}></GridItem>
              <GridItem xs={12} sm={12} md={12} lg={3}>
                {state.distance ? (
                  <Card>
                    <CardBody>
                      <Typography>Distância: {state.distance}</Typography>
                    </CardBody>
                  </Card>
                ) : (
                  ""
                )}
              </GridItem>
            </GridContainer>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.user.userAuth,
    rideSimulated: state.ride.rideSimulated,
    rideRequested: state.ride.rideRequested,
    scheduledRide: state.ride.scheduledRide,
    rideStatus: state.ride.rideStatus,
    rideBatched: state.ride.rideBatched,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    identifyUser: () => dispatch(identifyUser()),
    simulateRide: (model) => dispatch(simulateRide(model)),
    requestRide: (model) => dispatch(requestRide(model)),
    resetRide: (prop) => dispatch(resetRide(prop)),
    scheduleRide: (model) => dispatch(scheduleRide(model)),
    validateRide: (model) => dispatch(validateRide(model)),
    batchSimulateRide: (model) => dispatch(batchSimulateRide(model)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RideAdd);
