import React from 'react';
import { withStyles, Tooltip, Typography } from '@material-ui/core';
import './index.css';

export default function VehicleMarker(props) {
  const { text, tooltip, icon } = props;

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
      '& b': {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  }))(Tooltip);

  return (
    <HtmlTooltip
      title={
        <React.Fragment>
          <Typography component="h3">{text}</Typography>
          <em>{tooltip}</em>
        </React.Fragment>
      }
    >
      <img src={icon} width={36} height={36} />
    </HtmlTooltip>
  );
}
