/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
//REDUX
import { connect } from 'react-redux';
import { logout } from '../redux/actions/common';

function Logout(props) {
  useEffect(() => {
    props.logout();
  }, []);

  useEffect(() => {
    if (props.logoutSuccess !== undefined) {
      props.history.push('/auth');
    }
  }, [props.logoutSuccess]);

  return <div>Saindo...</div>;
}

const mapStateToProps = state => {
  return {
    logoutSuccess: state.common.logout
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Logout);
