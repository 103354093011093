import initialState from '../initialState';
import * as types from '../actions/actionTypes';

export default function userReducer(state = initialState.user, action) {
  switch (action.type) {
    case types.IDENTIFY_USER_SUCCESS: {
      return {
        ...state,
        userAuth: action.data,
      };
    }
    case types.IDENTIFY_USER_ERROR: {
      return {
        ...state,
        userAuth: action.error,
      };
    }
    case types.ADD_USER_SUCCESS: {
      return {
        ...state,
        createdUser: action.data,
      };
    }

    case types.ADD_USER_ERROR: {
      return {
        ...state,
        createdUser: action.error,
      };
    }

    case types.UPDATE_PASSWORD_SUCCESS: {
      return {
        ...state,
        passwordUpdated: action.data,
      };
    }

    case types.UPDATE_PASSWORD_ERROR: {
      return {
        ...state,
        passwordUpdated: action.error,
      };
    }

    // case types.GET_BUSINESS_LIST_SUCCESS: {
    //   return {
    //     ...state,
    //     businessList: action.data
    //   };
    // }

    // case types.GET_BUSINESS_LIST_ERROR: {
    //   return {
    //     ...state,
    //     passwordUpdated: action.error
    //   };
    // }

    case types.GET_INCOME_SUCCESS: {
      return {
        ...state,
        income: action.data,
      };
    }
    case types.GET_INCOME_ERROR: {
      return {
        ...state,
        income: action.error,
      };
    }

    case types.GET_ALL_INCOMES_SUCCESS: {
      return {
        ...state,
        allIncomes: action.data
      };
    }

    case types.GET_ALL_INCOMES_ERROR: {
      return {
        ...state,
        allIncomes: action.error
      };
    }

    case types.GENERATE_BANK_SLIP_SUCCESS: {
      return {
        ...state,
        bankSlipGenerated: action.data    
      };
    }

    case types.GENERATE_BANK_SLIP_ERROR: {
      return {
        ...state,
        bankSlipGenerated: action.error    
      };
    }

    case types.GET_TOTAL_CASH_SUCCESS: {
      return {
        ...state,
        totalCash: action.data
      };
    }

    case types.GET_TOTAL_CASH_ERROR: {
      return {
        ...state,
        totalCash: action.error
      };
    }

    case types.GET_PENDING_BANK_SLIPS_SUCCESS: {
      return {
        ...state,
        bankSlipsList: action.data
      };
    }

    case types.GET_PENDING_BANK_SLIPS_ERROR: {
      return {
        ...state,
        bankSlipsList: action.error
      };
    }

    case types.GET_DRIVERS_QUEUE_ERROR:{
      return {
        ...state,
        driversQueue: action.error
      };
    }

    case types.GET_DRIVERS_QUEUE_SUCCESS:{
      return {
        ...state,
        driversQueue: action.data
      };
    }

    case types.RESET_USER: {
      return {
        ...state,
        [action.prop]: undefined,
      };
    }

    default:
      return state;
  }
}
