/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

//REDUX
import { connect } from 'react-redux';
import { addCard, resetCards } from '../../../redux/actions/card';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import Notification from 'components/Notification/Notification';
import Loading from 'components/Loading/Loading.jsx';
//import FormHelperText from '@material-ui/core/FormHelperText';
// @material-ui/icons
import CreditCard from '@material-ui/icons/CreditCard';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import styles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.js';
import {
  verifyLength,
  verifyCardNumber,
  verifyExactLength
} from '../../../utils/validators';

const useStyles = makeStyles(styles);

function CardAdd(props) {
  const [holderName, setHolderName] = useState('');
  const [holderNameState, setHolderNameState] = useState('');
  const [number, setNumber] = useState('');
  const [numberState, setNumberState] = useState('');
  const [expirationMonth, setExpirationMonth] = useState('');
  const [expirationMonthState, setExpirationMonthState] = useState('');
  const [expirationYear, setExpirationYear] = useState('');
  const [expirationYearState, setExpirationYearState] = useState('');
  const [securityCode, setSecurityCode] = useState('');
  const [securityCodeState, setSecurityCodeState] = useState('');
  const [notification, setNotification] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.cardAdd !== undefined) {
      setLoading(false);
      if (props.cardAdd.response) {
        setNotification(true);
      } else {
        redirect();
      }
      props.resetCards('cardAdd');
    }
  }, [props.cardAdd]);

  // styles
  const classes = useStyles();

  /** FUNCTIONS */
  function handleInputChange(e, stateName, setFunc) {
    switch (stateName) {
      case 'holderName':
        if (verifyLength(e.target.value, 1)) {
          setHolderNameState('success');
        } else {
          setHolderNameState('error');
        }
        break;
      case 'month':
        if (verifyExactLength(e.target.value, 2)) {
          setExpirationMonthState('success');
        } else {
          setExpirationMonthState('error');
        }
        break;
      case 'year':
        if (verifyExactLength(e.target.value, 4)) {
          setExpirationYearState('success');
        } else {
          setExpirationYearState('error');
        }
        break;
      case 'code':
        if (
          verifyExactLength(e.target.value, 3) ||
          verifyExactLength(e.target.value, 4)
        ) {
          setSecurityCodeState('success');
        } else {
          setSecurityCodeState('error');
        }
        break;
      case 'number':
        if (verifyCardNumber(e.target.value)) {
          setNumberState('success');
        } else {
          setNumberState('error');
        }
        break;
      default:
        break;
    }
    setFunc(e.target.value);
  }

  function redirect() {
    props.history.push('/dashboard/card/table');
  }

  function isValidated() {
    if (
      holderNameState === 'success' &&
      numberState === 'success' &&
      expirationMonthState === 'success' &&
      expirationYearState === 'success' &&
      securityCodeState === 'success'
    ) {
      addCard();
    } else {
      if (holderNameState !== 'success') {
        setHolderNameState('error');
      }
      if (numberState !== 'success') {
        setNumberState('error');
      }
      if (expirationMonthState !== 'success') {
        setExpirationMonthState('error');
      }
      if (expirationYearState !== 'success') {
        setExpirationYearState('error');
      }
      if (securityCodeState !== 'success') {
        setSecurityCodeState('error');
      }
    }
  }

  function addCard() {
    setLoading(true);
    props.addCard({
      holderName,
      number,
      expirationMonth,
      expirationYear,
      securityCode
    });
  }

  return (
    <>
      {loading ? <Loading /> : ''}
      {notification ? (
        <Notification
          message={'Error ao adicionar novo cartão'}
          color={'danger'}
          setNotification={setNotification}
        />
      ) : (
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="purple" icon>
                <CardIcon color="purple">
                  <CreditCard />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Adicionar novo cartão</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      success={holderNameState === 'success'}
                      error={holderNameState === 'error'}
                      labelText="Nome do titular"
                      id="holderName"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: e =>
                          handleInputChange(e, 'holderName', setHolderName)
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      success={numberState === 'success'}
                      error={numberState === 'error'}
                      labelText="Número"
                      id="number"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: e => handleInputChange(e, 'number', setNumber)
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={4} sm={4} md={4}>
                    <CustomInput
                      success={expirationMonthState === 'success'}
                      error={expirationMonthState === 'error'}
                      labelText="Mês de expiração"
                      id="expirationMonth"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: e =>
                          handleInputChange(e, 'month', setExpirationMonth)
                      }}
                    />
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4}>
                    <CustomInput
                      success={expirationYearState === 'success'}
                      error={expirationYearState === 'error'}
                      labelText="Ano de expiração"
                      id="expirationYear"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: e =>
                          handleInputChange(e, 'year', setExpirationYear)
                      }}
                    />
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4}>
                    <CustomInput
                      success={securityCodeState === 'success'}
                      error={securityCodeState === 'error'}
                      labelText="Código de segurança"
                      id="securityCode"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: e =>
                          handleInputChange(e, 'code', setSecurityCode)
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <div>
                  <Button
                    color="success"
                    className={classes.updateProfileButton}
                    onClick={() => isValidated()}
                  >
                    Adicionar
                  </Button>
                  <Button
                    color="white"
                    className={classes.updateProfileButton}
                    onClick={() => redirect()}
                  >
                    Voltar
                  </Button>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      )}
    </>
  );
}

const mapStateToProps = state => {
  return {
    cardAdd: state.card.cardAdd
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addCard: model => dispatch(addCard(model)),
    resetCards: prop => dispatch(resetCards(prop))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardAdd);
