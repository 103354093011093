import Dashboard from '../views/pages/Dashboard/Dashboard';
import Auth from '../views/pages/Auth/Auth';
import Home from '../views/Home';
import Logout from '../views/Logout';
import Register from '../views/pages/Register/Register';

const indexRoutes = [
  { path: '/auth', name: 'auth', component: Auth, layout: '' },
  { path: '/register', name: 'register', component: Register, layout: '' },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    layout: '',
    protected: true
  },
  { path: '/logout', name: 'logout', component: Logout, layout: '' },
  { path: '/', name: 'home', component: Home, layout: '' }
];

export default indexRoutes;
