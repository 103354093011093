import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
// @material-ui/icons
import Check from '@material-ui/icons/Check';
import StoreMallDirectory from '@material-ui/icons/StoreMallDirectory';
import Fingerprint from '@material-ui/icons/Fingerprint';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';

import InputMask from 'inputmask';
// UTILS
import {
  verifyLength,
  verifyCPF,
  verifyCnpj
} from '../../../../utils/validators';

import styles from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js';

const style = {
  ...styles,
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center'
  },
  inputAdornmentIcon: {
    color: '#555'
  },
  inputAdornment: {
    position: 'relative'
  }
};

class EnterpriseStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enterprise: true,
      businessName: '',
      businessNameState: '',
      document: '',
      documentState: ''
    };
  }

  /** FUNCTIONS */
  sendState() {
    return this.state;
  }

  // handleToggle = value => {
  //   const currentIndex = checked.indexOf(value);
  //   const newChecked = [...checked];

  //   if (currentIndex === -1) {
  //     newChecked.push(value);
  //   } else {
  //     newChecked.splice(currentIndex, 1);
  //   }
  //   setChecked(newChecked);
  // };

  handleInputChange(e, stateName) {
    switch (stateName) {
      case 'businessName':
        this.setState({ [stateName]: e.target.value });
        if (verifyLength(e.target.value, 3)) {
          this.setState({ [stateName + 'State']: 'success' });
        } else {
          this.setState({ [stateName + 'State']: 'error' });
        }
        break;
      case 'document':
        const mask = InputMask({
          mask: ['999.999.999-99', '99.999.999/9999-99']
        }).mask(e.target);

        this.setState({ [stateName]: mask.maskset.buffer.join('') });

        if (verifyCPF(e.target.value)) {
          this.setState({ [stateName + 'State']: 'success' });
        } else if (verifyCnpj(e.target.value)) {
          this.setState({ [stateName + 'State']: 'success' });
        } else {
          this.setState({ [stateName + 'State']: 'error' });
        }
        break;
      case 'enterprise':
        this.setState({ [stateName]: e.target.checked });
        break;
      default:
        break;
    }
  }

  isValidated() {
    if (
      this.state.businessNameState === 'success' &&
      this.state.documentState === 'success'
    ) {
      return true;
    } else {
      if (this.state.businessNameState !== 'success') {
        this.setState({ businessNameState: 'error' });
      }
      if (this.state.documentState !== 'success') {
        this.setState({ documentState: 'error' });
      }
    }
    return false;
  }

  render() {
    const { classes } = this.props;

    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12}>
          <h4 className={classes.infoText}>
            Preencha aqui seus dados da sua empresa.
          </h4>
        </GridItem>
        <GridItem xs={12} sm={6}>
          <CustomInput
            success={this.state.businessNameState === 'success'}
            error={this.state.businessNameState === 'error'}
            labelText={
              <span>
                Nome comercial <small>(obrigatório)</small>
              </span>
            }
            id="businessName"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.handleInputChange(event, 'businessName'),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <StoreMallDirectory className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
          <CustomInput
            success={this.state.documentState === 'success'}
            error={this.state.documentState === 'error'}
            labelText={
              <span>
                CPF/CNPJ <small>(obrigatório)</small>
              </span>
            }
            id="document"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.handleInputChange(event, 'document'),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <Fingerprint className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                tabIndex={-1}
                onChange={event => this.handleInputChange(event, 'enterprise')}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot
                }}
              />
            }
            classes={{
              label: classes.label,
              root: classes.labelRoot
            }}
            label="É uma empresa?"
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={10}></GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(style)(EnterpriseStep);
