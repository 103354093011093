import axios from "axios";
import { url } from "./constants";

class Axios {
  constructor() {
    this.baseURL = url;

    this.token = axios.create({
      baseURL: this.baseURL,
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    });

    this.HttpGet = this.HttpGet.bind(this);
    this.HttpPost = this.HttpPost.bind(this);
    this.HttpPatch = this.HttpPatch.bind(this);
    this.HttpDelete = this.HttpDelete.bind(this);
    this.setToken = this.setToken.bind(this);
  }

  HttpGet(url) {
    return this.token.get(url);
  }

  HttpPost(url, model) {
    return this.token.post(url, model);
  }

  HttpPatch(url, model) {
    return this.token.patch(url, model);
  }

  HttpDelete(url) {
    return this.token.delete(url);
  }

  /**
   * Sets the Token in the HTTP header for the auth user
   * @param {string} token - OAuth 2.0 / Bearer Token
   */
  setToken(token) {
    this.token = axios.create({
      baseURL: this.baseURL,
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

const instance = new Axios();

export default instance;
