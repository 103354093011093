/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
//REDUX
import { connect } from "react-redux";
import { getListCards } from "../../redux/actions/card";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// core components
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomMasks from "components/CustomMasks/Masks";

import InputMask from "inputmask";

import { makeStyles } from "@material-ui/core/styles";

import { formatMoney } from "../../utils/functions";

function RideCheckout(props) {
  const [open, setOpen] = useState(true);
  const [openSelect, setOpenSelect] = React.useState(false);
  const [value, setValue] = React.useState("");
  const [cardsList, setCardsList] = useState();
  const [optimize, setOptimize] = useState(false);
  // const [phone, setPhone] = useState();
  // const [validPhone, setValidPhone] = useState(true);

  useEffect(() => {
    props.getListCards();
  }, []);

  useEffect(() => {
    if (props.cardsList !== undefined) {
      setCardsList(props.cardsList.cards);
    }
  }, [props.cardsList]);

  // useEffect(()=>{
  //   if (phone !== undefined){
  //     console.log('phone ',phone)
  //     var phoneRegex = /^\([1-9]{2}\) (?:9[1-9])[0-9]{2,3}\-[0-9]{4}$/
  //     console.log('phoneRegex.test(phone)',phoneRegex.test(phone))
  //     if (phoneRegex.test(phone)){
  //       props.setPhone(phone);
  //       setValidPhone(true);
  //     } else if (phone === '(__) _____-____'){
  //       props.setPhone('');
  //       setValidPhone(true);
  //     } else {
  //       setValidPhone(false);
  //     }
  //   }
  // },[phone])

  useEffect(() => {
    if (props.batchSimulation !== undefined) {
      if (props.batchSimulation.optimized_price) {
        if (
          props.batchSimulation.optimized_price.total_price >
          props.batchSimulation.normal_price.total_price
        ) {
          setOptimize(false);
        } else {
          setOptimize(true);
        }
        //setOptimize(true);
      } else {
        setOptimize(false);
      }
    }
  }, [props.batchSimulation]);

  const classes = useStyles();

  const handleClickListItem = () => {
    setOpenSelect(true);
  };

  const handleCloseList = (newValue) => {
    setOpenSelect(false);

    if (newValue) {
      setValue(newValue);
      props.setCard(newValue);
    }
  };

  const handleClose = () => {
    setOpen(false);
    props.openCheckout();
  };

  function handleInputChange(e, prop) {
    if (prop === "note") {
      props.setNote(e.target.value);
    } else if (prop === "phone") {
      const mask = InputMask({
        mask: "(99) 99999-9999",
      }).mask(e.target);
      // setPhone(mask.maskset.buffer.join(''))
      props.setPhone(mask.maskset.buffer.join(""));
    } else if (prop === "externalid") {
      props.setExternalId(e.target.value);
    } else if (prop === "receiverName") {
      props.setReceiverName(e.target.value);
    } else if (prop === "receiverIntermediate") {
      props.setReceiverIntermediate(e.target.value);
    }
  }

  function mapCardNumber(id) {
    let number = (cardsList || []).filter((card) => {
      return card.id === id;
    });

    if (number[0]) {
      return number[0].number;
    } else {
      return "";
    }
  }

  function addCard() {
    props.history.push("/dashboard/wallet/add");
  }

  const { simulation, requestRide, addRideCallback, editCallback } = props;

  function gris(props) {
    return simulation.insurance_price > 3000
      ? 3000
      : simulation.insurance_price;
  }

  return (
    <>
      <GridContainer>
        <GridItem xs={1} sm={1} md={2} lg={4}></GridItem>
        <GridItem xs={10} sm={10} md={8} lg={4}>
          <Dialog
            maxWidth="md"
            fullWidth
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Informações adicionais
            </DialogTitle>
            <DialogContent>
              <Card>
                <CardBody>
                  <List component="div" role="list">
                    <ListItem button divider disabled role="listitem">
                      <ListItemText
                        style={{ color: "#000000" }}
                        primary="Valor referente à distância"
                        secondary={`R$ ${formatMoney(
                          simulation.distance_price
                        )}`}
                      />
                    </ListItem>
                    <ListItem button divider disabled role="listitem">
                      <ListItemText
                        style={{ color: "#000000" }}
                        primary="Valor referente à cubagem"
                        secondary={`R$ ${formatMoney(simulation.volume_price)}`}
                      />
                    </ListItem>
                    <ListItem button divider disabled role="listitem">
                      <ListItemText
                        style={{ color: "#000000" }}
                        primary="Valor referente ao seguro"
                        secondary={`R$ ${formatMoney(
                          simulation.insurance_price
                        )}`}
                        //secondary={`R$ ${formatMoney(gris())}`}
                      />
                    </ListItem>
                    <ListItem divider role="listitem">
                      <ListItemText
                        style={{ color: "green" }}
                        primary="Total"
                        secondary={`R$ ${formatMoney(simulation.total_price)}`}
                      />
                    </ListItem>
                  </List>
                </CardBody>
              </Card>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={6}>
                  <Card>
                    <CardBody>
                      <div style={{ textAlign: "center" }}>
                        <p>
                          Número do pedido ou controle interno{" "}
                          <b>(Obrigatório)</b>.
                        </p>
                      </div>
                      <CustomInput
                        style={{ color: "#000000" }}
                        labelText="Número do pedido"
                        id="externalid"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        error={
                          props.externalId == null ||
                          props.externalId.length == 0
                        }
                        inputProps={{
                          value: props.externalId,
                          required: true,
                          onChange: (e) => {
                            if (e.target.value.length <= 20) {
                              handleInputChange(e, "externalid");
                            }
                          },
                        }}
                      />
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={6}>
                  <Card>
                    <CardBody>
                      <div style={{ textAlign: "center" }}>
                        <p>
                          Número do <b>celular</b> do cliente para recebimento
                          de SMS
                        </p>
                      </div>
                      <CustomInput
                        style={{ color: "#000000" }}
                        labelText="Número do cliente"
                        id="phone"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        // error={validPhone}
                        inputProps={{
                          onChange: (e) => handleInputChange(e, "phone"),
                        }}
                      />
                      <br></br>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={6}>
                  <Card>
                    <CardBody>
                      <div style={{ textAlign: "center" }}>
                        <p>
                          Coloque abaixo o <b>nome do cliente</b>
                        </p>
                      </div>
                      <CustomInput
                        style={{ color: "#000000" }}
                        labelText="Nome do cliente"
                        id="client"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: props.receiverName,
                          onChange: (e) => {
                            if (e.target.value.length <= 50) {
                              handleInputChange(e, "receiverName");
                            }
                          },
                        }}
                      />
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={6}>
                  <Card>
                    <CardBody>
                      <div style={{ textAlign: "center" }}>
                        <p>
                          Outra pessoa também está autorizada a receber a
                          encomenda?
                        </p>
                      </div>
                      <CustomInput
                        style={{ color: "#000000" }}
                        labelText="A/C"
                        id="receiverIntermediate"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: props.receiverIntermediate,
                          onChange: (e) => {
                            if (e.target.value.length <= 50) {
                              handleInputChange(e, "receiverIntermediate");
                            }
                          },
                        }}
                      />
                      <br></br>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <Card>
                    <CardBody>
                      <div style={{ textAlign: "center" }}>
                        <p>
                          No campo abaixo você pode colocar observações
                          adicionais para o motorista, como por exemplo: a
                          presença de pedágios no trajeto.
                        </p>
                        <CustomInput
                          style={{ color: "#000000" }}
                          labelText="Observações"
                          id="note"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: props.note,
                            onChange: (e) => {
                              if (e.target.value.length <= 200) {
                                handleInputChange(e, "note");
                              }
                            },
                          }}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
              <ConfirmationDialogRaw
                classes={{
                  paper: classes.paper,
                }}
                id="ringtone-menu"
                keepMounted
                open={openSelect}
                onClose={handleCloseList}
                value={value}
                cardsList={cardsList || []}
                addCard={addCard}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="white">
                Voltar
              </Button>
              {props.rides.length <= 15 ? (
                <Button onClick={addRideCallback} color="warning">
                  Adicionar outro frete
                </Button>
              ) : (
                ""
              )}
              <Button
                onClick={() => {
                  if (props.externalId && props.externalId.length > 0) {
                    if (props.rides.length > 1) {
                      if (optimize) {
                        setOpen(false);
                        props.openOptimize();
                        props.openCheckout();
                      } else {
                        requestRide(optimize);
                        setOpen(false);
                      }
                    } else {
                      requestRide(optimize);
                      setOpen(false);
                    }
                  } else {
                    props.setState({
                      notification: true,
                      color: "danger",
                      message: "Número do pedido obrigatório",
                    });
                  }
                }}
                color="success"
                autoFocus
              >
                {props.rides.length > 1 ? "Próximo" : "Concluir"}
              </Button>
            </DialogActions>
          </Dialog>
        </GridItem>
        <GridItem xs={1} sm={1} md={2} lg={4}></GridItem>
      </GridContainer>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    cardsList: state.card.cardsList,
    rideSimulated: state.ride.rideSimulated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListCards: () => dispatch(getListCards()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RideCheckout);

function ConfirmationDialogRaw(props) {
  const {
    onClose,
    value: valueProp,
    open,
    cardsList,
    addCard,
    ...other
  } = props;
  const [value, setValue] = useState(valueProp);
  const radioGroupRef = useRef(null);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  useEffect(() => {
    if (cardsList !== undefined) {
      setOptions(cardsList);
    }
  }, [cardsList]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      onEntering={handleEntering}
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">Meus cartões</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="ringtone"
          name="ringtone"
          value={value}
          onChange={handleChange}
        >
          {options ? (
            options.map((option, index) => (
              <FormControlLabel
                value={option.id}
                key={index}
                control={<Radio color="default" />}
                label={option.number}
              />
            ))
          ) : (
            <Button onClick={addCard} color="white">
              Adicionar cartão
            </Button>
          )}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="white">
          Cancel
        </Button>
        <Button onClick={handleOk} color="success">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: "80%",
    maxHeight: 435,
  },
}));
