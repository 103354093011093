import React from 'react';
import { withStyles, Tooltip, Typography } from '@material-ui/core';
import './index.css';

export default function Marker(props) {
  const { text, tooltip, available } = props;

  let border = '5px solid #000';
  switch (available) {
    case 'available':
      border = '2px solid #00FF00';
      break;
    case 'inRide':
      border = '2px solid #0000FF';
      break;
    case 'unavailable':
      border = '2px solid #ff0000';
      break;
    default:
      break;
  }

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
      '& b': {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  }))(Tooltip);

  return (
    <HtmlTooltip
      title={
        <React.Fragment>
          <Typography component="h3">{text}</Typography>
          <em>{tooltip}</em>
        </React.Fragment>
      }
    >
      <div className="mark" style={{ borderColor: props.color }}>
        {text}
      </div>
    </HtmlTooltip>
  );
}
