import { combineReducers } from 'redux';
import common from './common';
import user from './user';
import card from './card';
import ride from './ride';

const appReducer = combineReducers({ common, user, card, ride });

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    localStorage.removeItem('token');
    localStorage.removeItem('business');
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
