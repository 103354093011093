import React, { useState, useEffect } from "react";
import axios from "axios";

import { connect } from "react-redux";
import {
  generateBankSlip,
  getTotalCash,
  getPendingBankSlips,
} from "../../../redux/actions/user";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Loading from "components/Loading/Loading.jsx";
import Notification from "components/Notification/Notification";

// import BankSlipsList from './BankSlipsList';

import ReactTable from "react-table";
import PrintIcon from "@material-ui/icons/Print";
import IconButton from "@material-ui/core/IconButton";

import moment from "moment";
import "moment/locale/pt-br";
import Datetime from "react-datetime";

import { formatMoney } from "../../../utils/functions";
import { url } from "../../../utils/constants";

// import MaskedInput from 'react-text-mask'
// import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const formatter = new Intl.NumberFormat("pt-BR", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

var mathjs = require("mathjs");

function Payments(props) {
  const [balance, setBalance] = useState();
  // const [rechargeAux, setRechargeAux] = useState(0);
  const [recharge, setRecharge] = useState(0);
  const [date, setDate] = useState("");
  const [dbDate, setDbDate] = useState();
  const [loading, setLoading] = useState(false);
  const [openBankSlips, setOpenBankSlipes] = useState([]);
  const [admin, setAdmin] = useState(false);
  const [cpf, setCpf] = useState();
  const [businessName, setBusinessName] = useState();
  const [ownerId, setOwnerId] = useState();
  const [notification, setNotification] = useState(false);
  const [message, setMessage] = useState("");
  const [color, setColor] = useState("");
  const [loadingAux, setLoadingAux] = useState(false);

  // const picker = document.getElementsByClassName("rdtPicker")[0];
  // console.log('picker',picker)

  // const styleSheet = document.styleSheets[4];
  // console.log('styleSheet',styleSheet)
  // let beforeParams;

  // for(let i = 0; i < styleSheet.cssRules.length; i++) {
  //   if(styleSheet.cssRules[i].selectorText === '.rdtPicker') {
  //     beforeParams = styleSheet.cssRules[i];
  //   }
  // }

  // console.log('beforeParams',beforeParams)

  // if (picker) {
  //   picker.style.marginTop = '-80px';
  //   picker.style.marginLeft = '105%';

  //   const before = getComputedStyle(picker,':before');

  //   // before.right = '0px';
  //   // before.left = '-5px';
  //   // before.borderBottom = '0.4em solid transparent';
  //   // before.borderTop = '0.4em solid transparent';
  //   // before.borderRight = '0.4em solid';

  //   // arrowAfter("picker:after",{
  //   //   top:'70px',
  //   //   right: '0px',
  //   //   left: '-5px',
  //   //   borderBottom: '0.4em solid transparent',
  //   //   borderTop: '0.4em solid transparent',
  //   //   borderRight: '0.4em solid',
  //   // }  )
  // }

  /** EFFECTS */
  useEffect(() => {
    if (props.userAuth !== undefined) {
      props.getTotalCash(props.userAuth.business.id);
      props.getPendingBankSlips(props.userAuth.business.id);
      if (props.userAuth.is_admin === 1) {
        setAdmin(true);
      }
      setBusinessName(props.userAuth.business.name);
      setCpf(props.userAuth.business.document);
      setOwnerId(props.userAuth.business.id);
    }
  }, [props.userAuth]);

  useEffect(() => {
    if (props.totalCash !== undefined) {
      setBalance(props.totalCash.balance);
    }
  }, [props.totalCash]);

  useEffect(() => {
    if (props.bankSlipsList !== undefined) {
      if (props.bankSlipsList.bank_slips.length > 0) {
        let slips = props.bankSlipsList.bank_slips;
        let arr = [];
        slips.map((s) => {
          arr.unshift(s);
        });
        setOpenBankSlipes(arr);
      }
    }
  }, [props.bankSlipsList]);

  useEffect(() => {
    if (loadingAux) {
      setTimeout(() => {
        setLoadingAux(false);
      }, 1000);
    }
  }, [loadingAux]);

  /** FUNCTIONS */
  // Boleto para o próximo dia
  var today = Datetime.moment().add(-1, "day");

  var valid = function(current) {
    return current.isAfter(today);
    // Para remover os fins de semana:
    // return current.isAfter(tomorrow) && current.day() !== 0 && current.day() !== 6;
  };

  function moneyToFloat(value) {
    return value.replace(/[.]/g, "", "").replace(/[,]/g, ".", ".");
  }

  function handleInputChange(value, label) {
    if (label === "recharge") {
      let inp = value.target.value;
      // console.log('inp',inp)
      // var str = inp.replace(/R\$|\./g, "")
      // var res = str.replace(/,/g, ".");
      // console.log(res)

      var cents = inp.replace(/[^0-9]/g, "", "");
      cents = mathjs.divide(cents, 100);
      if (cents > 5000) {
        cents = 5000;
      }
      setRecharge(moneyToFloat(formatter.format(cents)));

      // setRecharge(res);
    } else if (label === "date") {
      let d = moment(value._d).format("YYYY-MM-DD");
      d = d.toString();
      let dn = d + " 23:59:59";
      let showD = moment(value._d).format("DD/MM/YYYY");
      setDate(showD);
      setDbDate(dn);
    }
  }

  function handlePayment() {
    if (recharge < 10 || recharge === undefined) {
      setNotification(true);
      setMessage("O valor mínimo do boleto é de 10,00 reais.");
      setColor("danger");
    } else if (dbDate === undefined) {
      setNotification(true);
      setMessage(
        "Ops! Parece que você esqueceu de colocar a data de vencimento do boleto."
      );
      setColor("danger");
    } else {
      setLoadingAux(true);
      const token = JSON.parse(localStorage.getItem("token"));
      axios
        .post(
          `${url}/account/generate-bankslip`,
          {
            name: businessName,
            taxDocument: cpf,
            amount: recharge,
            dueDate: dbDate,
            requester_id: ownerId,
          },
          {
            responseType: "blob",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setLoadingAux(false);
          const file = new Blob([response.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        })
        .catch((error) => {
          setLoadingAux(false);
          console.log(error);
        });
      // props.generateBankSlip({
      //   taxDocument: cpf,
      //   amount: recharge,
      //   dueDate: dbDate,
      //   requester_id: ownerId
      // });
    }
  }

  function handleGetSecondBankSlip(id) {
    const token = JSON.parse(localStorage.getItem("token"));
    axios
      .get(`${url}/account/duplicate-bankslip?bankslipId=${id}`, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setLoadingAux(true);
        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch((error) => {
        console.log(error);
        setNotification(true);
        setMessage(
          "Ops! Parece que algo deu errado. Talvez esse boleto já tenha vencido."
        );
        setColor("danger");
      });
  }

  return (
    <>
      {notification ? (
        <Notification
          message={message}
          color={color}
          setNotification={setNotification}
        />
      ) : (
        ""
      )}
      {loading || loadingAux ? (
        <Loading />
      ) : (
        <>
          <br />
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <Card>
                <CardBody>
                  <h3 style={{ fontWeight: "bold", marginTop: 0 }}>
                    R$ {formatMoney(balance)}
                  </h3>
                  <p style={{ color: "#888", marginBottom: 0 }}>Saldo</p>
                </CardBody>
              </Card>
              {/* </GridItem>
        <GridItem xs={12} sm={12} md={6}> */}
              <Card>
                <CardHeader>
                  <h4 style={{ fontWeight: "bold", color: "#444" }}>
                    Recarga de Beelog Cash
                  </h4>
                </CardHeader>
                <CardBody>
                  <p style={{ color: "#888", marginTop: -10 }}>
                    Informe o valor que você deseja realizar a recarga. Nosso{" "}
                    <b>valor mínimo</b> é 10,00 reais.
                  </p>
                  {/* <GridContainer> */}
                  {/* <GridItem xs={12} sm={12} md={6}> */}
                  <div style={{ marginTop: 12 }}>
                    <p
                      style={{ color: "#999", fontSize: 11, marginBottom: -10 }}
                    >
                      Data do vencimento
                    </p>
                    <Datetime
                      locale="pt-br"
                      isValidDate={valid}
                      onChange={(e) => handleInputChange(e, "date")}
                      timeFormat={"HH:mm"}
                      dateFormat="DD/MM/YYYY"
                      timeFormat={false}
                      inputProps={{
                        value: date,
                      }}
                    />
                  </div>
                  {/* </GridItem>
              <GridItem xs={12} sm={12} md={6}> */}
                  <CustomInput
                    style={{ color: "#000000" }}
                    labelText="Valor"
                    id="recharge"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: `R$${formatter.format(recharge)}`,
                      autoComplete: "off",
                      onChange: (e) => handleInputChange(e, "recharge"),
                    }}
                  />
                  {/* </GridItem> */}
                  {/* </GridContainer> */}
                  {/* <div style={{
                marginBottom:10
              }}>
                <p style={{fontSize:11, color:"#777"}}>Valor da recarga</p>
                <MaskedInput 
                  mask={currencyMask}
                  placeholder="R$0,00" 
                  type="text" 
                  id="recharge"
                  onChange= {(e) => handleInputChange(e, 'recharge')}
                  style={{
                    border:0,
                    fontSize:14,
                  }}  
                />
              </div> */}

                  <div style={{ marginTop: 25 }}>
                    <p style={{ color: "#999", fontSize: 11 }}>
                      *Aguardar no mínimo 30 minutos para efetuar o pagamento
                    </p>
                    <p style={{ color: "#999", fontSize: 11 }}>
                      *O pagamento pode demorar até <b>2 dias úteis</b> para ser
                      compensado.
                    </p>
                  </div>
                  <div style={{ marginTop: 25 }}>
                    <Button
                      color="purple"
                      fullWidth={true}
                      onClick={() => handlePayment()}
                    >
                      Boleto
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </GridItem>
            {/* </GridContainer>
      <GridContainer > */}
            <GridItem xs={12} sm={12} md={6}>
              <Card>
                <CardHeader>
                  <h4 style={{ fontWeight: "bold", color: "#444" }}>
                    Boletos pendentes
                  </h4>
                </CardHeader>
                <CardBody>
                  {/* <BankSlipsList 
                openBankSlips={openBankSlips} 
                setLoadingAux={setLoadingAux}
              /> */}
                  <ReactTable
                    className="-striped -highlight"
                    noDataText="Nenhum boleto encontrado."
                    data={openBankSlips ? openBankSlips : []}
                    resizable={false}
                    sortable={false}
                    loadingText={"Carregando..."}
                    loading={openBankSlips ? false : true}
                    previousText={"Anterior"}
                    nextText={"Próximo"}
                    pageText={"Página"}
                    ofText={"de"}
                    rowsText={"linhas"}
                    showPageSizeOptions={false}
                    defaultPageSize={10}
                    pages={Math.ceil(openBankSlips.length / 10)}
                    columns={[
                      {
                        Header: "Valor",
                        id: "amount",
                        accessor: (row) => `R$${formatMoney(row.amount)}`,
                        width: 90,
                      },
                      {
                        Header: "Vencimento",
                        id: "due_date",
                        accessor: (row) =>
                          moment(row.due_date).format("DD/MM/YYYY"),
                      },
                      {
                        Header: "Gerado em",
                        id: "created_at",
                        accessor: (row) =>
                          moment(row.created_at).format("DD/MM/YYYY"),
                      },
                      {
                        Header: "Imprimir",
                        id: "print",
                        width: 90,
                        accessor: (row) =>
                          moment(row.due_date).isAfter() ? (
                            <IconButton
                              aria-label="info"
                              size="small"
                              onClick={() =>
                                handleGetSecondBankSlip(row.pagg_id)
                              }
                            >
                              <PrintIcon />
                            </IconButton>
                          ) : (
                            "Boleto vencido"
                          ),
                      },
                    ]}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.user.userAuth,
    bankSlipGenerated: state.user.bankSlipGenerated,
    totalCash: state.user.totalCash,
    bankSlipsList: state.user.bankSlipsList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    generateBankSlip: (model) => dispatch(generateBankSlip(model)),
    getTotalCash: (id) => dispatch(getTotalCash(id)),
    getPendingBankSlips: (id) => dispatch(getPendingBankSlips(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Payments);
