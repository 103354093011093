import { dispatch } from '../../utils/functions';
import * as types from './actionTypes';

export function getListCards() {
  return dispatch(types.GET_LIST_CARDS, '/account/cards');
}

export function addCard(model) {
  return dispatch(types.ADD_CARD, '/account/save-card', model);
}

export function deleteCard({ id }) {
  return dispatch(types.DELETE_CARD, `/account/delete-card?id=${id}`);
}

export function resetCards(prop) {
  return { type: types.RESET_CARDS, prop };
}
