/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import cx from "classnames";
import { Switch } from "react-router-dom";
import { getRoutes, verifyFlag } from "../../../utils/getRoutes";
//REDUX
import { connect } from "react-redux";
import { identifyUser } from "../../../redux/actions/user";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
// import Loading from 'components/Loading/Loading.jsx';

import routes from "../../../routes/dashboard";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import { CircularProgress } from "@material-ui/core";

var ps;

const useStyles = makeStyles(styles);

const urls = ["/card/add", "/ride/:id", "/card/:id", "/profile", "/payments"];

function Dashboard(props) {
  const [loading, setLoading] = React.useState(true);

  const { ...rest } = props;
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const [image] = React.useState(require("assets/img/sidebar-2.jpg"));
  const [color] = React.useState("purple");
  const [bgColor] = React.useState("black");
  const [logo] = React.useState(require("assets/img/logo-beelog.png"));
  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Windows") > -1,
    });
  // ref for main panel div
  const mainPanel = React.createRef();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    if (navigator.platform.indexOf("Windows") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Windows") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  });

  React.useEffect(() => {
    setLoading(true);
    props.identifyUser();
  }, []);

  React.useEffect(() => {
    if (props.userAuth !== undefined) {
      setLoading(false);
      if (props.userAuth.response || !props.userAuth.business) {
        props.history.push("/logout");
      }
    }
  }, [props.userAuth]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  const transformRoutes = (routes) => {
    const filterRoute = (route) => {
      if (
        urls.includes(route.path) ||
        (props.userAuth &&
          route.flag &&
          !verifyFlag(route.flag, props.userAuth))
      ) {
        return false;
      } else {
        if (route.path === "/queue" && !props.userAuth.business.owns_fleet) {
          return false;
        }

        return true;
      }
    };

    return routes.filter(filterRoute);
  };

  return (
    <div className={classes.wrapper}>
      {loading ? (
        <CircularProgress
          color="secondary"
          style={{ position: "absolute", top: "50%", left: "50%" }}
        />
      ) : (
        <>
          <Sidebar
            routes={transformRoutes(routes)}
            logoText={"Beelog Business"}
            logo={logo}
            image={image}
            handleDrawerToggle={handleDrawerToggle}
            open={mobileOpen}
            color={color}
            bgColor={bgColor}
            miniActive={miniActive}
            userAuth={props.userAuth}
            {...rest}
          />
          <div className={mainPanelClasses} ref={mainPanel}>
            <AdminNavbar
              sidebarMinimize={sidebarMinimize.bind(this)}
              miniActive={miniActive}
              brandText={getActiveRoute(routes)}
              handleDrawerToggle={handleDrawerToggle}
              {...rest}
            />
            {!props.location.pathname.includes("map") ? (
              <div className={classes.content}>
                <div className={classes.container}>
                  <Switch>
                    {getRoutes("/dashboard", routes, props.userAuth)}
                  </Switch>
                </div>
              </div>
            ) : (
              <div className={classes.map}>
                <Switch>
                  {getRoutes("/dashboard", routes, props.userAuth)}
                </Switch>
              </div>
            )}
            {/* <div>
          <div style={{ width: '99%' }}>
            <Switch>{getRoutes('/dashboard', routes)}</Switch>
          </div>
        </div> */}
          </div>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.user.userAuth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    identifyUser: () => dispatch(identifyUser()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);
