/*global google*/
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  withGoogleMap,
  GoogleMap,
  DirectionsRenderer,
  Marker,
} from 'react-google-maps';

export default function FullScreenMap(props) {
  const [origin, setOrigin] = useState();
  const [destination, setDestination] = useState();
  const [directions, setDirections] = useState();

  useEffect(() => {
    if (props.origin !== undefined) {
      setOrigin({
        lat: parseFloat(props.origin.lat),
        lng: parseFloat(props.origin.lng),
      });
    } else {
      setOrigin(undefined);
      setDirections(undefined);
    }
  }, [props.origin]);

  useEffect(() => {
    if (props.destination !== undefined) {
      setDestination({
        lat: parseFloat(props.destination.lat),
        lng: parseFloat(props.destination.lng),
      });
    } else {
      setDestination(undefined);
      setDirections(undefined);
    }
  }, [props.destination]);

  useEffect(() => {
    if (origin !== undefined && destination !== undefined) {
      const directionsService = new google.maps.DirectionsService();
      new google.maps.DirectionsRenderer({ suppressMarkers: true });

      directionsService.route(
        {
          origin: origin,
          destination: destination,
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            setDirections(result);
            props.setDistanceInt(result.routes[0].legs[0].distance.value);
            props.setDistance(result.routes[0].legs[0].distance.text);
            props.setPath(result.routes[0].overview_polyline);
            props.setDuration(result.routes[0].legs[0].duration.value);
          } else {
            console.error(`error fetching directions ${result}`);
          }
        }
      );
    }
  }, [origin, destination]);

  const GoogleMapExample = withGoogleMap((props) => (
    <GoogleMap
      defaultOptions={{
          mapTypeControl: false,
          fullscreenControl: true,
        }
      }
      defaultCenter={origin}
      defaultZoom={16}      
    >
      <Marker
        defaultLabel={'O'}
        defaultIcon={null}
        position={origin || { lat: 0, lng: 0 }}
      />
      <Marker
        defaultLabel={'D'}
        defaultIcon={null}
        position={destination || { lat: 0, lng: 0 }}
      />
      <DirectionsRenderer
        directions={directions}
        options={{
          //preserveViewport: true,
          suppressMarkers: true,
          icon: { scale: 3 },
          polylineOptions: {
            strokeColor: 'rgb(93, 44, 76)',
          },
        }}
      />
    </GoogleMap>
  ));

  return (
    <GoogleMapExample
      containerElement={<div style={{ width: '100%'}} />}
      mapElement={<div style={{ height: `100vh`}} />}
    />
  );
}
