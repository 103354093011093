/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
//REDUX
import { connect } from 'react-redux';
import { addUser, resetUser } from '../../../redux/actions/user';
// core components
import Wizard from 'components/Wizard/Wizard.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Notification from 'components/Notification/Notification';
import AddressStep from './RegisterSteps/AddressStep.jsx';
import EnterpriseStep from './RegisterSteps/EnterpriseStep.jsx';
import UserStep from './RegisterSteps/UserStep.jsx';

function Register(props) {
  const [notification, setNotification] = useState(false);

  useEffect(() => {
    if (props.createdUser !== undefined) {
      if (props.createdUser.response) {
        setNotification(true);
      } else {
        redirect();
      }
      props.resetUser('createdUser');
    }
  }, [props.createdUser]);

  function redirect() {
    props.history.push('/auth');
  }

  /** FUNCTIONS */
  function registerUser(obj) {
    props.addUser({
      name: obj.user.name,
      phone: obj.user.phone,
      email: obj.user.email,
      password: obj.user.password,
      cpf: obj.user.cpf,
      document: obj.enterprise.document,
      enterprise: obj.enterprise.enterprise,
      businessName: obj.enterprise.businessName,
      address: obj.address.value,
      addressNote: obj.address.line2,
      latitude: obj.address.latitude,
      longitude: obj.address.longitude
    });
  }

  return (
    <>
      {notification ? (
        <Notification
          message={'Error ao adicionar novo usuário'}
          color={'danger'}
          setNotification={setNotification}
        />
      ) : (
        ''
      )}
      <GridContainer justify="center">
        <GridItem xs={12} sm={8}>
          <Wizard
            validate
            steps={[
              {
                stepName: 'Dados Pessoais',
                stepComponent: UserStep,
                stepId: 'user'
              },
              {
                stepName: 'Empresa',
                stepComponent: EnterpriseStep,
                stepId: 'enterprise'
              },
              {
                stepName: 'Endereço',
                stepComponent: AddressStep,
                stepId: 'address'
              }
            ]}
            title="Entregue seus produtos com Beelog"
            subtitle="Faça o seu cadastro agora mesmo e garanta mais essa comodidade."
            finishButtonClick={e => registerUser(e)}
          />
        </GridItem>
      </GridContainer>
    </>
  );
}

const mapStateToProps = state => {
  return {
    createdUser: state.user.createdUser
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addUser: model => dispatch(addUser(model)),
    resetUser: prop => dispatch(resetUser(prop))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Register);
