import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router, Switch } from 'react-router-dom';
import indexRoutes from './routes/index';
import { getRoutes } from './utils/getRoutes';
import configStore from './redux/configureStore';
import { Provider } from 'react-redux';
import './assets/scss/material-dashboard-pro-react.scss?v=1.5.0';

const hist = createBrowserHistory();
const store = configStore();

ReactDOM.render(
  <Provider store={store}>
    <Router history={hist}>
      <Switch>{getRoutes('', indexRoutes)}</Switch>
    </Router>
  </Provider>,
  document.getElementById('root')
);
