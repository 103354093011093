import { takeEvery } from 'redux-saga/effects';
import { put } from 'redux-saga/effects';
import api from '../../utils/api';
import * as types from '../actions/actionTypes';

function* sagaPost({ type, url, args }) {
  try {
    if (localStorage.getItem('token')) {
      const token = JSON.parse(localStorage.getItem('token'));

      api.setToken(token);
    }

    const response = yield api.HttpPost(url, args);

    yield put({ type: `${type}_SUCCESS`, data: response.data });
  } catch (error) {
    yield put({ type: `${type}_ERROR`, error });
  }
}

function* sagaGet({ type, url, args }) {
  try {
    if (localStorage.getItem('token')) {
      const token = JSON.parse(localStorage.getItem('token'));

      api.setToken(token);
    }

    const response = yield api.HttpGet(url);

    yield put({ type: `${type}_SUCCESS`, data: response.data });
  } catch (error) {
    yield put({ type: `${type}_ERROR`, error });
  }
}

export function* watchEntity() {
  yield takeEvery(types.LOGIN, sagaPost);
  yield takeEvery(types.IDENTIFY_USER, sagaGet);
  yield takeEvery(types.GET_LIST_CARDS, sagaGet);
  yield takeEvery(types.ADD_CARD, sagaPost);
  yield takeEvery(types.GET_RIDE_HISTORY, sagaGet);
  yield takeEvery(types.GET_ALL_HISTORY, sagaGet);
  yield takeEvery(types.GET_RIDE, sagaGet);
  yield takeEvery(types.GET_PARENTS, sagaGet);
  yield takeEvery(types.GET_DRIVER_LOCATION, sagaGet);
  yield takeEvery(types.SIMULATE_RIDE, sagaPost);
  yield takeEvery(types.REQUEST_RIDE, sagaPost);
  yield takeEvery(types.VALIDATE_RIDE, sagaPost);
  yield takeEvery(types.DELETE_CARD, sagaPost);
  yield takeEvery(types.ADD_USER, sagaPost);
  yield takeEvery(types.UPDATE_PASSWORD, sagaPost);
  yield takeEvery(types.SCHEDULE_RIDE, sagaPost);
  yield takeEvery(types.TRACK_RIDE, sagaGet);
  yield takeEvery(types.GET_INCOME, sagaPost);
  yield takeEvery(types.GET_ALL_INCOMES, sagaPost);
  yield takeEvery(types.CANCEL_RIDE, sagaPost);
  yield takeEvery(types.GENERATE_BANK_SLIP, sagaPost);
  yield takeEvery(types.GET_TOTAL_CASH, sagaGet);
  yield takeEvery(types.GET_PENDING_BANK_SLIPS, sagaGet);
  yield takeEvery(types.BATCH_SIMULATE_RIDE, sagaPost);
  yield takeEvery(types.GET_DRIVERS_QUEUE, sagaGet);
}
