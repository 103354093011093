/* global google */
import React, { useState } from 'react';
//import ReactGoogleMapLoader from 'react-google-maps-loader';
import ReactGooglePlacesSuggest from 'react-google-places-suggest';
// @material-ui/core components
//import { makeStyles } from '@material-ui/core/styles';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import { useEffect } from 'react';
//import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

//const useStyles = makeStyles(styles);
export default function Destination(props) {
  const [search, setSearch] = useState('');
  const [value, setValue] = useState(props.destinationAddress);

  function handleInputChange(e) {
    setSearch(e.target.value);
    setValue(e.target.value);
  }

  function handleNoResult() {
    console.log('No result for', search);
  }

  function handleSelectSuggest(geocodedPrediction, originalPrediction) {
    props.setDestination({
      lat: geocodedPrediction.geometry.location.lat(),
      lng: geocodedPrediction.geometry.location.lng(),
    });
    props.setDestinationAddress(geocodedPrediction.formatted_address);
    setSearch('');
    setValue(geocodedPrediction.formatted_address);
  }

  useEffect(() => {
    setValue(props.destinationAddress);
  }, [props.destinationAddress]);

  //const classes = useStyles();

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <form>
          <ReactGooglePlacesSuggest
            googleMaps={google.maps}
            autocompletionRequest={{
              input: search,
              location: props.userAuth.business.latitude
                ? new google.maps.LatLng(
                    props.userAuth.business.latitude,
                    props.userAuth.business.longitude
                  )
                : null,
              radius: props.userAuth.business.latitude ? 70 : null,
              componentRestrictions: { country: 'br' },
            }}
            onNoResult={handleNoResult}
            onSelectSuggest={handleSelectSuggest}
            textNoResults="Nenhum endereço encontrado." // null or "" if you want to disable the no results item
            //customRender={prediction => (
            // <div className="customWrapper">
            //
            //   {prediction
            //    ? prediction.description
            //    : 'Nenhum endereço encontrado.'}
            //
            //</div>
            //)}
          >
            <CustomInput
              style={{ color: '#000000' }}
              labelText="Qual o local de destino?"
              id="destination"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                maxLength: 200,
                value: value,
                autoComplete: 'off',
                onChange: (e) => handleInputChange(e),
              }}
            />
          </ReactGooglePlacesSuggest>
        </form>
      </GridItem>
    </GridContainer>
  );
}
