/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";

import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

import { formatMoney } from "../../utils/functions";
import { Typography } from "@material-ui/core";

function RideOptimize(props) {
  const [open, setOpen] = useState(true);
  const [normalPrice, setNormalPrice] = useState();
  // const [normalTime, setNormalTime] = useState(0);
  const [optimizedPrice, setOptimizedPrice] = useState();
  // const [optimizedTime, setOptimizedTime] = useState(0);
  const [selected, setSelected] = useState(false);
  const [normalButton, setNormalButton] = useState(false);
  const [optimizedButton, setOptimizedButton] = useState(false);
  const [optimized, setOptimized] = useState(false);

  const { classes } = props;

  /** EFFECTS */
  useEffect(() => {
    if (props.batchSimulation !== undefined) {
      setNormalPrice(props.batchSimulation.normal_price);
      setOptimizedPrice(props.batchSimulation.optimized_price);
    }
  }, [props.batchSimulation]);

  /** FUNCTIONS */
  function handleClose() {
    setOpen(false);
    props.openCheckout();
    props.openOptimize();
  }

  function handleNormal() {
    setSelected(true);
    setNormalButton(true);
    if (optimizedButton) setOptimizedButton(false);
  }

  function handleOptimal() {
    setSelected(true);
    setOptimizedButton(true);
    if (normalButton) setNormalButton(false);
    setOptimized(true);
  }

  function handleComplete() {
    props.requestRide();
    props.optimized(optimized);
  }

  // TOOLTIP
  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }))(Tooltip);

  return (
    <>
      <GridContainer>
        <GridItem xs={1} sm={1} md={2} lg={4}></GridItem>
        <GridItem xs={10} sm={10} md={8} lg={4}>
          <Dialog
            maxWidth="md"
            fullWidth
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Finalizar corrida</DialogTitle>
            <DialogContent>
              <GridContainer
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <GridItem xs={12} sm={12} md={6}>
                  <Card>
                    <CardBody>
                      <Typography variant="h5" align="center">
                        Sua rota
                      </Typography>
                      <br />
                      <Typography variant="caption">
                        Valor da corrida:
                      </Typography>
                      <br />
                      <Typography variant="h6" align="center">
                        {normalPrice !== undefined
                          ? `R$${formatMoney(normalPrice.total_price)}`
                          : ""}
                      </Typography>
                      <br />
                      {/* <Typography>Tempo estimado: {normalTime}</Typography> */}
                      <div
                        style={{
                          textAlign: "center",
                          width: "100%",
                          marginTop: 10,
                          marginBottom: 10,
                        }}
                      >
                        <Button
                          onClick={() => handleNormal()}
                          outline
                          // color={normalButton ? 'primary' : 'purple'}
                          color={"primary"}
                          style={{ width: "100%" }}
                        >
                          {normalButton ? "Selecionado" : "Selecionar"}
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <Card>
                    <CardBody>
                      <Typography variant="h5" align="center">
                        Rota otimizada{" "}
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <Typography color="inherit">
                                Rota otimizada
                              </Typography>
                              {
                                "Quando uma corrida possui múltiplos destinos, nós realizamos uma otimização para reduzir o tempo da corrida, mudando a ordem dos destinos."
                              }
                            </React.Fragment>
                          }
                        >
                          <HelpOutlineIcon />
                        </HtmlTooltip>
                      </Typography>
                      <br />
                      <Typography variant="caption">
                        Valor da corrida:
                      </Typography>
                      <br />
                      <Typography variant="h6" align="center">
                        {optimizedPrice !== undefined
                          ? `R$${formatMoney(optimizedPrice.total_price)}`
                          : ""}
                      </Typography>
                      <br />
                      {/* <Typography>Tempo estimado: {optimizedTime}</Typography> */}
                      <div
                        style={{
                          textAlign: "center",
                          width: "100%",
                          marginTop: 10,
                          marginBottom: 10,
                        }}
                      >
                        <Button
                          onClick={() => handleOptimal()}
                          outline
                          color={"primary"}
                          // color={optimizedButton ? 'primary' : 'purple'}
                          style={{ width: "100%" }}
                        >
                          {optimizedButton ? "Selecionado" : "Selecionar"}
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
              <DialogActions>
                <Button onClick={() => handleClose()} color="white">
                  Voltar
                </Button>
                {selected ? (
                  <Button onClick={() => handleComplete()} color="success">
                    Concluir
                  </Button>
                ) : (
                  ""
                )}
              </DialogActions>
            </DialogContent>
          </Dialog>
        </GridItem>
      </GridContainer>
    </>
  );
}

export default RideOptimize;
