/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

//REDUX
import { connect } from 'react-redux';
import { deleteCard, resetCards } from '../../../redux/actions/card';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
// @material-ui/icons
import CreditCard from '@material-ui/icons/CreditCard';
// react component used to create sweet alerts
import SweetAlert from 'react-bootstrap-sweetalert';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { cardModel } from '../../../utils/constants';

import userStyles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.js';
import alertStyles from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js';

const styles = {
  ...userStyles,
  ...alertStyles
};

const useStyles = makeStyles(styles);

function CardShow(props) {
  const [card, setCard] = useState([cardModel]);
  const [deleteAlert, setDeleteAlert] = useState();

  useEffect(() => {
    if (props.cardsList !== undefined) {
      setCard(getCard(props.match.params.id));
    }
  }, [props.cardsList]);

  useEffect(() => {
    if (props.cardDeleted !== undefined) {
      if (props.cardDeleted.response) {
        cancelDelete();
      } else {
        successDelete();
      }

      props.resetCards('cardDeleted');
    }
  }, [props.cardDeleted]);

  // styles
  const classes = useStyles();

  /** FUNCTIONS */
  function getCard(id) {
    const filterCard = card => {
      if (card.id === id) {
        return true;
      } else {
        return false;
      }
    };

    return props.cardsList.cards.filter(filterCard);
  }

  function deleteCard() {
    props.deleteCard({ id: props.match.params.id });
  }

  function redirect() {
    props.history.push('/dashboard/card/table');
  }

  function hideAlert() {
    setDeleteAlert(null);
  }

  function warningWithConfirmMessage() {
    setDeleteAlert(
      <SweetAlert
        warning
        style={{ display: 'block', marginTop: '-100px' }}
        title="Você tem certeza?"
        onConfirm={() => deleteCard()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
        cancelBtnCssClass={classes.button + ' ' + classes.danger}
        confirmBtnText="Sim"
        cancelBtnText="Cancelar"
        showCancel
      ></SweetAlert>
    );
  }

  function successDelete() {
    setDeleteAlert(
      <SweetAlert
        success
        style={{ display: 'block', marginTop: '-100px' }}
        title="Deletado!"
        onConfirm={() => redirect()}
        // onCancel={() => ()}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
      >
        Cartão deletado com sucesso!
      </SweetAlert>
    );
  }

  function cancelDelete() {
    setDeleteAlert(
      <SweetAlert
        danger
        style={{ display: 'block', marginTop: '-100px' }}
        title="Error"
        onConfirm={() => hideAlert()}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
      >
        Não foi possivel deletar o cartão
      </SweetAlert>
    );
  }

  return (
    <div className={classes.content}>
          <div className={classes.container}>
{
    <>
      {deleteAlert}
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="purple" icon>
              <CardIcon color="purple">
                <CreditCard />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Cartão</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Nome do titular"
                    id="holderName"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: card[0].holderName
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Número"
                    id="number"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: card[0].number
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={6} sm={6} md={6}>
                  <CustomInput
                    labelText="Mês de expiração"
                    id="expirationMonth"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: card[0].expirationMonth
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <CustomInput
                    labelText="Ano de expiração"
                    id="expirationYear"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: card[0].expirationYear
                    }}
                  />
                </GridItem>
              </GridContainer>
              <div>
                <Button
                  color="danger"
                  className={classes.updateProfileButton}
                  onClick={() => warningWithConfirmMessage()}
                >
                  Deletar
                </Button>
                <Button
                  color="white"
                  className={classes.updateProfileButton}
                  onClick={() => redirect()}
                >
                  Voltar
                </Button>
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
}
</div></div>  
  );
}

const mapStateToProps = state => {
  return {
    cardsList: state.card.cardsList,
    cardDeleted: state.card.cardDeleted
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteCard: id => dispatch(deleteCard(id)),
    resetCards: prop => dispatch(resetCards(prop))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardShow);
