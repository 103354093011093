import React from 'react';

// import { Container } from './styles';

function HeaderItem({ title, value }) {
  return (
    <>
      <div style={{ fontSize: '17px', fontWeight: 'bold', color: '#AFAFAF' }}>
        {title}
      </div>
      <br></br>
      <div style={{ fontSize: '30px', fontWeight: 'bold' }}>{value}</div>
    </>
  );
}

export default HeaderItem;
