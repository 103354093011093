/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

// @material-ui/core components
//import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from 'components/Snackbar/Snackbar.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

// @material-ui/icons
import Warning from '@material-ui/icons/Warning';

//import notificationsStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx";

function Notification(props) {
  /** STATES */
  const [tc, setTc] = useState(true);

  /** EFFECTS */
  useEffect(() => {
    let timeOutFunction = setTimeout(function() {
      setTc(false);
      props.setNotification(false);
    }, 4000);

    return function cleanup() {
      clearTimeout(timeOutFunction);
      timeOutFunction = null;
    };
  }, []);

  function closeNotification() {
    props.setNotification(false);
    setTc(false);
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={3}>
          <Snackbar
            place="tc"
            color={props.color}
            icon={Warning}
            message={props.message}
            open={tc}
            closeNotification={() => closeNotification()}
            close
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default Notification;
