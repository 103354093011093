import React, { useState } from 'react';
// @material-ui/core components
//import { makeStyles } from '@material-ui/core/styles';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import Button from 'components/CustomButtons/Button.js';

//import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.js';

//const useStyles = makeStyles(buttonsStyle);

export default function VehicleOptions(props) {
  const [carBtn, setCarBtn] = useState('white');
  const [textCarBtn, setTextCarBtn] = useState('black');
  const [textBikeBtn, setTextBikeBtn] = useState('black');
  const [bikeBtn, setBikeBtn] = useState('white');

  function clickEvent(type) {
    props.setActiveAcord(1);
    if (type === 1) {
      setCarBtn('success');
      setBikeBtn('white');
      setTextCarBtn('white')
      setTextBikeBtn('black')
      props.setVehicle(1);
    } else if (type === 0) {
      setBikeBtn('success');
      setCarBtn('white');
      setTextCarBtn('black')
      setTextBikeBtn('white')
      props.setVehicle(0);      
    }    
  }

  //const classes = useStyles();

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>                
        <GridItem md={12}>
          <Button
            color={carBtn}
            fullWidth={true}
            onClick={() => clickEvent(1)}
            style={{color: textCarBtn}}
          >
            Carro
          </Button>
          <Button
            color={bikeBtn}
            fullWidth={true}
            onClick={() => clickEvent(0)}
            style={{color: textBikeBtn}}
          >
            Moto
          </Button>
        </GridItem>         
      </GridItem>
    </GridContainer>
  );
}