/* eslint-disable react-hooks/exhaustive-deps */
import React, { createRef, useState, useEffect } from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
//REDUX
import { connect } from 'react-redux';
import { login, resetCommon } from '../../../redux/actions/common';
import { identifyUser } from '../../../redux/actions/user';

import Notification from 'components/Notification/Notification.js';
import Login from './Login';

import styles from 'assets/jss/material-dashboard-pro-react/layouts/authStyle.js';
import loginBg from 'assets/img/login.jpeg';

const useStyles = makeStyles(styles);

function Auth(props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState(false);

  /** EFFECTS */
  useEffect(() => {
    if (localStorage.getItem('token')) {
      redirect();
    }
  }, []);

  useEffect(() => {
    setLoading(false);
    if (props.loginSuccess) {
      if (props.loginSuccess.response) {
        setNotification(true);
      }

      if (props.loginSuccess === true) {
        props.identifyUser();
      }

      props.resetCommon('loginSuccess');
    }
  }, [props.loginSuccess]);

  useEffect(() => {
    if (props.userAuth !== undefined) {
      if (props.userAuth.business) {
        localStorage.setItem(
          'business',
          JSON.stringify(props.userAuth.business)
        );
        redirect();
      } else {
        localStorage.removeItem('token');
        localStorage.removeItem('business');
        setNotification(true);
      }
    }
  }, [props.userAuth]);

  // ref for the wrapper div
  const wrapper = createRef();
  // styles
  const classes = useStyles();

  /** FUNCTIONS */
  function getBgImage() {
    if (window.location.pathname.indexOf('/auth') !== -1) {
      return loginBg;
    }
  }

  function login() {
    setLoading(true);
    props.login({
      email: email,
      password: password,
    });
  }

  function redirect() {
    props.history.push('/dashboard/ride/map/add');
  }

  function handleChange(prop, event) {
    if (prop === 'password') {
      setPassword(event.target.value);
    } else if (prop === 'email') {
      setEmail(event.target.value);
    }
  }

  return (
    <div>
      {notification ? (
        <Notification
          message={'Senha ou Email incorreto'}
          color={'danger'}
          setNotification={setNotification}
        />
      ) : (
        ''
      )}
      {/* <AuthNavbar brandText={getActiveRoute(routes)} {...rest} /> */}
      <div className={classes.wrapper} ref={wrapper}>
        <div
          className={classes.fullPage}
          style={{ backgroundImage: 'url(' + getBgImage() + ')' }}
        >
          <Login login={login} handleChange={handleChange} loading={loading} />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loginSuccess: state.common.loginSuccess,
    userAuth: state.user.userAuth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetCommon: () => dispatch(resetCommon('loginSuccess')),
    login: (model) => dispatch(login(model)),
    identifyUser: () => dispatch(identifyUser()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Auth);
