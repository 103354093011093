/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

function Home(props) {
  useEffect(() => {
    props.history.push('/auth');
  }, []);

  return <div />;
}

export default Home;
