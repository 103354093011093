/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
//core components
import { withStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import RideMap from '../Maps/RideMap';
import CardBody from 'components/Card/CardBody';
import Timeline from 'components/Timeline/Timeline'; 
import HiddenRide from 'components/Ride/HiddenRide'; 
import Loading from 'components/Loading/Loading.jsx';
import Button from 'components/CustomButtons/Button.js';
// @material-ui/core components
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import styles from 'assets/jss/material-dashboard-pro-react/cardImagesStyles';
import { withScriptjs } from 'react-google-maps';
import { GOOGLE_KEY } from '../../../utils/constants';
import { formatMoney } from '../../../utils/functions';
import Typography from '@material-ui/core/Typography';
import adminStyles from 'assets/jss/material-dashboard-pro-react/layouts/adminStyle.js';
import Avatar from '@material-ui/core/Avatar';
import ReactToPrint from 'react-to-print';
import Print from '@material-ui/icons/Print';
import { formatTimeDate } from '../../../utils/functions';
import { cancelRide } from '../../../redux/actions/ride';
import { Button as CoreButton } from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Notification from 'components/Notification/Notification';
// romaneio
import Table from 'components/Table/Table.js';
import { identifyUser } from '../../../redux/actions/user';
import { getParents } from '../../../redux/actions/ride';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import RidePrint from '../../../components/Ride/RidePrint';

const adminUseStyles = makeStyles(adminStyles);

let MapLoader;

export default function Ride(props) {
  const dispatch = useDispatch();
  const [ride, setRide] = useState();
  const [parents, setParents] = useState([]);
  const [status, setStatus] = useState([]);
  const canceledRide = useSelector((state) => state.ride.canceledRide);
  const waypoints = useSelector((state) => state.ride.waypoints);
  const [origin, setOrigin] = useState({ lat: 0, lng: 0 });
  const [destination, setDestination] = useState({ lat: 0, lng: 0 });
  const [driver, setDriver] = useState();
  const [driverLoc, setDriverLoc] = useState({ lat: 0, lng: 0 });
  const [loading, setLoading] = useState(false);
  // const [logo] = React.useState(require('assets/img/logo-beelog-horiz.png'));
  const [open, setOpen] = React.useState(false);
  const [color, setColor] = React.useState('');
  const [notification, setNotification] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [arrivalTime, setArrivalTime] = useState(null);

  const printStyle = `table {
    border: 2px solid white;
    border-radius: 10px;
    overflow: hidden;
    text-align: center;
  }
  
  thead {
    background-color: rgb(97, 0, 89);
    border-color: rgb(97, 0, 89);
    color: white;
  }
  
  tbody tr:nth-child(even) {
    background-color: rgb(223, 223, 223);
    border-color: gray;
  }
  @media print {
    @page {
      size: landscape;
    }
  }
  `;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(
    () => () => {
      document.title = `Beelog Business`;
    },
    []
  );

  useEffect(() => {
    document.title = `Frete: ${props.match.params.id}`;
    MapLoader = withScriptjs(RideMap);
  }, []);

  useEffect(() => {
    if (canceledRide !== undefined) {
      if (canceledRide.response && canceledRide.response.data.error) {
        setNotification(true);
        setColor('danger');

        if (canceledRide.response.data.error === 'NOT_SAME_RIDE_STATUS') {
          setMessage(
            'O status da corrida foi alterado. Por favor tente novamente.'
          );
        } else if (
          canceledRide.response.data.error === 'FORBIDDEN_RIDE_STATUS'
        ) {
          setMessage('Desculpe. Não é mais possível cancelar este frete.');
        } else {
          setMessage('Não foi possível cancelar o frete.');
        }
      } else if (canceledRide.id) {
        setNotification(true);
        setColor('success');
        setMessage('Frete cancelado.');
        setTimeout(function() {
          window.location.reload();
        }, 3000);
      } else {
        setNotification(true);
        setColor('danger');
        setMessage('Não foi possível cancelar o frete.');
      }
    }
  }, [canceledRide]);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      width: theme.spacing(10),
      height: theme.spacing(10),
    }
  }));

  function openWhatsApp() {
    window.open(
      `https://api.whatsapp.com/send?1=pt_BR&phone=55${driver.phone}`,
      '_blank'
    );
  }

  function myCancelRide() {
    setOpen(false);
    dispatch(
      cancelRide(
        {
          ride_id: ride.parent_id ? ride.parent_id : ride.id,
        },
        ride.lastStatus.status_id
      )
    );
  }

  function handleReOrder(){
    props.history.push('/dashboard/ride/map/add',{rideCanceledDetails: parents})
  }

  useEffect(() => {
    if (waypoints !== undefined) {
      if (waypoints.response && waypoints.response.data.error) {
        console.log(waypoints.response.data.error);
      } else {
        console.log('waypoints', waypoints);
        setParents(waypoints.rides);
      }
    }
  }, [waypoints]);

  useEffect(() => {
    if (ride) {
      console.log('ride',ride)
      dispatch(getParents(ride.parent_id ? ride.parent_id : ride.id));
    }
  }, [ride]);

  useEffect(() => {
    console.log('parents', parents);
  }, [parents]);

  useEffect(()=>{
    console.log('arrivalTime',arrivalTime)    
  },[arrivalTime])


  // styles
  const classes = useStyles();
  const adminClasses = adminUseStyles();
  const componentRef = useRef();

  return (
    <div className={adminClasses.content}>
      <div className={adminClasses.container}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Cancelamento de frete'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <p>
                Você tem certeza que deseja cancelar este frete? Uma taxa de
                cancelamento poderá ser cobrada caso um motorista já esteja em
                rota ao remetente.
              </p>
              <p>
                <i>
                  Se este frete pertencer a um frete com múltiplos destinos
                  todos os fretes solicitados juntos a este serão também
                  cancelados.
                </i>
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Fechar</Button>
            <Button
              onClick={() => {
                myCancelRide();
              }}
              color="danger"
              autoFocus
            >
              Cancelar Frete
            </Button>
          </DialogActions>
        </Dialog>
        {
          <div>
            {notification ? (
              <Notification
                message={message}
                color={color}
                setNotification={(v) => setNotification(v)}
              />
            ) : (
              ''
            )}
            {loading ? (
              <Loading />
            ) : (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  {MapLoader ? (
                    <div
                      style={{
                        height: 350,
                        width: '100%',
                        display: 'flex',
                        flexFlow: 'row nowrap',
                        justifyContent: 'center',
                        padding: 0,
                      }}
                    >
                      <MapLoader
                        driverLoc={driverLoc}
                        driver={driver}
                        origin={origin}
                        destination={destination}
                        className={classes.cardImgTop}
                        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_KEY}`}
                        loadingElement={<div style={{ height: `100%` }} />}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </GridItem>

                <GridItem xs={6} sm={6} md={6}>
                  {ride ? (
                    <div>
                      <Card>
                        <CardBody>
                          <ReactToPrint                          
                            trigger={() => (
                              <Button
                                style={{ float: 'right' }}
                                round
                                color="purple"
                              >
                                <Print />
                                Romaneio
                              </Button>
                            )}
                            content={() => componentRef.current}
                          />
                          {ride.lastStatus.status_id == 5 ? (
                            <Button
                              onClick={() => handleReOrder()}
                              color="purple"
                              round
                            >
                              Pedir novamente
                            </Button>
                          ) : (
                            ''
                          )}
                          <h3>Código: {props.match.params.id}</h3>
                          {ride.createdBy ? (
                            <Typography>
                              Frete solicitado por: {ride.createdBy.name}
                            </Typography>
                          ) : (
                            ''
                          )}
                          <Typography>
                            {formatTimeDate(ride.date)[0]} -{' '}
                            {formatTimeDate(ride.date)[1]}
                          </Typography>
                          <br></br>
                          {ride.lastStatus.status_id <= 3 ? (
                            <Button
                              onClick={() => handleClickOpen()}
                              color="danger"
                              round
                            >
                              Cancelar Frete
                            </Button>
                          ) : (
                            ''
                          )}
                          <br></br>
                          <h4>
                            <b>Coleta em:</b> {ride.originAddress}
                          </h4>
                          <h4>
                            <b>Destino:</b> {ride.destinationAddress}
                          </h4>
                          <h4>{ride.destinationNote}</h4>
                          {ride.receiver_name ? (
                            <h4>
                              <b>Destinatário:</b> {ride.receiver_name}
                            </h4>
                          ) : (
                            ''
                          )}
                          {ride.receiver_intermediate ? (
                            <h4>
                              <b>A/C:</b> {ride.receiver_intermediate}
                            </h4>
                          ) : (
                            ''
                          )}
                          <hr />

                          {driver ? (
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={12} lg={12}>
                                <div
                                  align="center"
                                  style={{ width: '100%', align: 'center' }}
                                >
                                  <Avatar
                                    alt={driver.name}
                                    src={driver.picture}
                                    className={classes.large}
                                  />
                                  <br></br>
                                </div>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={12} lg={6}>
                                <h4>
                                  <b>Motorista</b>
                                </h4>
                                <h4>{driver.name}</h4>
                                <Button
                                  onClick={() => openWhatsApp()}
                                  color="success"
                                  round
                                >
                                  WhatsApp
                                </Button>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={12} lg={6}>
                                <h4>
                                  <b>Veículo</b>
                                </h4>
                                <h4>Placa: {driver.driver.vehicle.plate}</h4>
                                <h4>{driver.driver.vehicle.description}</h4>
                              </GridItem>
                            </GridContainer>
                          ) : (
                            ''
                          )}
                          <hr />

                          <h4>
                            <b>Informações do frete</b>
                          </h4>

                          <h4>
                            <b>Distância:</b>{' '}
                            {(ride.distance / 1000)
                              .toFixed(2)
                              .replace('.', ',')}
                             km
                          </h4>
                          <h4>
                            <b>Tipo de veículo: </b> {ride.vehicleType.name}
                          </h4>
                          <h4>
                            <b>Quantidade de volumes: </b> {ride.num_volumes}
                          </h4>
                          <h4>
                            <b>Tipo de conteúdo: </b>{' '}
                            {ride.measures || ride.volume || ride.is_food == 1
                              ? 'Pacote'
                              : 'Documentos'}
                          </h4>
                          {ride.is_food == 1 ? (
                            <div>
                              <h4>Food</h4>
                            </div>
                          ) : (
                            ''
                          )}
                          {ride.volume !== null ? (
                            <div>
                              <h4>{`Dimesões do pacote:`}</h4>
                              <h4>{`${ride.volume.length} (C) x ${ride.volume.width} (L) x ${ride.volume.height} (A)`}</h4>
                              <h4>{`Peso:`}</h4>
                              <h4>{`${ride.volume.weight}kg`}</h4>
                              <h4>{`Cubagem do pacote:`}</h4>
                              <h4>{`${ride.volume.calc_weight}kg`}</h4>
                            </div>
                          ) : (
                            ''
                          )}
                          <hr />

                          <h4>
                            <b>Composição do preço</b>
                          </h4>
                          {ride.lastStatus.status_id === 5 ? (
                            <>
                              {ride.amount_paid !== null ? (
                              <h4>
                                {`Taxa de cancelamento: R$ ${formatMoney(
                                  ride.amount_paid
                                )}`}
                              </h4>
                              ) : (
                                <h4>Não houve cobrança </h4>
                              )}
                            </>
                          ) : (
                            <>
                              <h4>{`Distância: R$ ${formatMoney(
                                ride.price.distance_price
                              )}`}</h4>
                              <h4>{`Volume: R$ ${formatMoney(
                                ride.price.volume_price
                              )}`}</h4>
                              <h4>{`Gris: R$ ${formatMoney(
                                ride.price.insurance_price
                              )}`}</h4>
                              <h4 style={{ color: 'green' }}>
                                <b>{`Total: R$ ${formatMoney(
                                  ride.price.total_price
                                )}`}</b>
                              </h4>
                            </>
                          )}
                          <hr />
                          {ride.signature ? (
                            <div>
                              <h4>
                                <b>Assinatura do recebedor</b>
                              </h4>
                              <img src={`${ride.signature}`} width={'100%'} />
                            </div>
                          ) : (
                            ''
                          )}
                        </CardBody>
                      </Card>
                      <div style={{ display: 'none' }}>
                        <div style={{ padding: '4%' }} ref={componentRef}>
                          {parents && parents.length > 0 ? (
                            <RidePrint ride={{ ...ride, parents }} />
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  {arrivalTime !== null ?
                    <Card>
                      <CardBody>
                        <div style={{textAlign:'center'}}>
                          <h5 style={{color:'#4a3385', fontWeight:'bold'}}>
                            Tempo estimado de chegada
                          </h5>
                          <h4 style={{color:'#4a3385', fontWeight:'bold'}}>
                            {arrivalTime}.
                          </h4>
                        </div>            
                      </CardBody>
                    </Card>
                    :
                  ""}
                  <Card plain>
                    {/* <CardBody plain> */}
                      <Timeline stories={status} />
                    {/* </CardBody> */}
                  </Card>
                </GridItem>
                <HiddenRide
                  id={props.match.params.id}
                  origin={origin}
                  destination={destination}
                  setOrigin={setOrigin}
                  setDestination={setDestination}
                  setStatus={setStatus}
                  status={status}
                  setRide={setRide}
                  myRide={ride}
                  setDriver={setDriver}
                  driver={driver}
                  setDriverLoc={setDriverLoc}
                  driverLoc={driverLoc}
                  setLoading={setLoading}
                  setArrivalTime={setArrivalTime}
                />
              </GridContainer>
            )}
          </div>
        }
      </div>
    </div>
  );
}
