import React from 'react';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import Button from 'components/CustomButtons/Button.js';

var mathjs = require('mathjs');

export default function Insurance(props) {

  const formatter = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2});

  function moneyToFloat(value){

    return value.replace(/[.]/g, "",'').replace(/[,]/g, ".",'.')
  }

  function handleInputChange(e) {

    var cents = e.target.value.replace(/[^0-9]/g, "",'');      
    cents = mathjs.divide(cents, 100);
    if(cents > 3000){
      cents = 3000;
    }
    props.setInsurance(moneyToFloat(formatter.format(cents)));
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardBody>
            <CustomInput
              style={{color: '#000000'}}
              labelText="Valor dos produtos (R$)"
              id="product_price"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                value: props.insurance,
                onChange: e => handleInputChange(e)
              }}
            />
            <br></br>
            <CustomInput
              style={{ color: '#000000' }}
              labelText="Número da Nota Fiscal"
              id="tax_number"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: props.taxNumber,
                onChange: (e) => {
                  props.setTaxNumber(e.target.value.length <= 20 ? e.target.value : props.taxNumber)
              },
              }}
            />
          </CardBody>
          {moneyToFloat(props.insurance) > 0 ? (
            <Button color="success" onClick={() => props.setActiveAcord(-2)}>
              Próximo
            </Button>
          ) : ''}          
        </Card>
      </GridItem>
    </GridContainer>
  );
}
