import * as types from './actionTypes';
import { dispatch } from '../../utils/functions';

export function login(model) {
  return dispatch(types.LOGIN, '/account/signin', model);
}
export function logout() {
  return { type: types.LOGOUT };
}
export function setLoading(value) {
  return { type: types.SET_LOADING, value };
}
export function resetCommon(prop) {
  return { type: types.RESET_COMMON, prop };
}
