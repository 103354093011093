/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
//REDUX
import { connect } from 'react-redux';
import {
  getRide,
  resetRide,
  getDriverLocation
} from '../../redux/actions/ride';
import { formatTimeDate } from '../../utils/functions';
//ICONS
import Car from '@material-ui/icons/CallMergeTwoTone';

const bdgColorHelper = statusId => {
  switch (statusId) {
    case 0:
      return 'info';
    case 1:
      return 'info';
    case 2:
      return 'info';
    case 3:
      return 'warning';
    case 4:
      return 'success';
    case 5:
      return 'danger';
    case 6:
      return 'success';
    case 7:
      return 'warning';
    case 8:
      return 'danger';
    case 9:
      return 'danger';
    default:
      return 'danger';
  }
};

function HiddenRide(props) {
  const [status, setStatus] = useState();

  useEffect(() => {
    if (props.id !== undefined) {
      //props.setLoading(true);
      callRide(props.id);      
    }    
  }, [props.id]);

  useEffect(() => {
    //props.setLoading(false);
    if(props.ride && props.ride.lastStatus.status_id < 5 && props.ride.driver_id)
      props.getDriverLocation({ id: props.id });
    if (props.ride !== undefined) {      
      if (
        props.ride.origin_latitude !== props.origin.lat &&
        props.ride.origin_longitude !== props.origin.lng
      ) {
        props.setOrigin({
          lat: props.ride.origin_latitude,
          lng: props.ride.origin_longitude
        });
      }

      if (
        props.ride.destination_latitude !== props.destination.lat &&
        props.ride.destination_longitude !== props.destination.lng
      ) {
        props.setDestination({
          lat: props.ride.destination_latitude,
          lng: props.ride.destination_longitude
        });
      }

      setStatus(props.ride.lastStatus.status_id);

      if (props.ride.status.length !== props.status.length) {
        props.setStatus(makeTimelineStatus(props.ride.status));
      }

      if (props.myRide === undefined) {
        props.setRide({
          id: props.ride.id,
          amount_paid: props.ride.amount_paid,
          typeName: props.ride.type.name,
          createdBy: props.ride.created_by,
          distance: props.ride.distance,
          originAddress: props.ride.originAddress,
          destinationAddress: props.ride.destinationAddress,
          destinationNote: props.ride.destination_note,
          fare: props.ride.fare.name,
          num_volumes: props.ride.num_volumes,
          measures: props.ride.measures,
          volume: props.ride.volume,
          is_food: props.ride.is_food,
          external_id: props.ride.external_id,
          receiver_name: props.ride.receiver_name,
          receiver_intermediate: props.ride.receiver_intermediate,
          date: props.ride.date,
          parent_id: props.ride.parent_id,
          vehicleType: props.ride.vehicle_type,
          lastStatus: props.ride.lastStatus,
          signature: props.ride.signature,
          price: {
            distance_price: props.ride.price.distance_price,
            volume_price: props.ride.price.volume_price,
            insurance_price: props.ride.price.insurance_price,
            discount_price: props.ride.price.discount_price,
            total_price: props.ride.price.total_price,
            driver_price: props.ride.price.driver_price,
          }
        });
      }

      if (props.ride.driver !== undefined) {
        if (props.driver === undefined) {
          props.setDriver({
            name: props.ride.driver.name,
            phone: props.ride.driver.phone,
            picture: props.ride.driver.picture,
            driver:  props.ride.driver.driver,
            vehicle: props.ride.driver.driver.vehicle.type.id
          });
        }
      }

      return function cleanup() {
        props.resetRide('ride');
      };
    }
  }, [props.ride]);

  useEffect(() => {
    if (props.driverLocation !== undefined) {
      if (
        props.driverLocation.latitude !== null &&
        props.driverLocation.longitude !== null
      ) {
        if (
          props.driverLocation.latitude !== props.driverLoc.lat ||
          props.driverLocation.longitude !== props.driverLoc.lng
        ) {
          props.setDriverLoc({
            lat: props.driverLocation.latitude,
            lng: props.driverLocation.longitude
          });
        }
      }
      if (props.driverLocation.arrival_time !== null){
        props.setArrivalTime(props.driverLocation.arrival_time)
      }

      return function cleanup() {
        props.resetRide('driverLocation');
      };
    }
  }, [props.driverLocation]);

  useEffect(() => {
    if (status !== undefined) {
      let interval = setInterval(function() {
        if (status === 1) {
          callRide(props.id);
        } else if (status >= 2 && status <= 4) {
          props.getDriverLocation({ id: props.id });
        } else if (status >= 5) {
          clearInterval(interval);
          return;
        }
      }, 10000);

      return function cleanup() {
        clearInterval(interval);
        interval = null;
      };
    }
  }, [status]);

  /** FUNCTIONS */
  function makeTimelineStatus(status) {
    const newStatus = status.map(item => ({
      inverted: item.status_id % 2 === 0,
      badgeColor: bdgColorHelper(item.status_id),
      badgeIcon: Car,
      title: item.name,
      titleColor: bdgColorHelper(item.status_id),
      body: (
        <div>
          <b>Data: </b>
          {`${formatTimeDate(item.created_at)[0]}`}
          <br />
          <b>Hora: </b>
          {`${formatTimeDate(item.created_at)[1]}`}
          <br />
        </div>
      ),
      footerTitle: item.status_id == 9 && item.note ? item.note : '',
    }));

    return newStatus;
  }

  function callRide(id) {
    props.getRide({ id });
  }

  return <div />;
}

const mapStateToProps = state => {
  return {
    ride: state.ride.ride,
    driverLocation: state.ride.driverLocation
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getRide: id => dispatch(getRide(id)),
    resetRide: prop => dispatch(resetRide(prop)),
    getDriverLocation: id => dispatch(getDriverLocation(id))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HiddenRide);
