/* global google */

import React, { useEffect, useState } from 'react';
import polyline from '@mapbox/polyline';
//REDUX
import { useSelector, useDispatch } from 'react-redux';
import { trackRide } from '../../../../redux/actions/ride';

import GoogleMapReact from 'google-map-react';
import Marker from './Marker';
import VehicleMarker from './VehicleMarker';
import { K_SIZE } from '../../../../components/Marker/styles';
import { getRandomColor } from '../../../../utils/functions';
import RideCard from './RideCard';
import car from 'assets/img/pin_car.png';
import bike from 'assets/img/pin_bike.png';
import { Polyline } from 'google-maps-react';

export default function RideWatch() {
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.user.userAuth);
  const rides = useSelector((state) => state.ride.trackRide.rides);
  const [loading, setLoading] = useState(true);
  const [map, setMap] = useState(undefined);
  const [maps, setMaps] = useState(undefined);
  const [center, setCenter] = useState({ lat: -10.9498019, lng: -37.0843285 });
  var currentDrivers = [];
  var [polylines, setPolylines] = useState([]);

  useEffect(() => {
    if (userAuth) {
      setCenter({
        lat: userAuth.business.latitude,
        lng: userAuth.business.longitude,
      });
      dispatch(trackRide(userAuth.business.id));

      const interval = setInterval(function() {
        dispatch(trackRide(userAuth.business.id));
      }, 10000);

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAuth]);

  useEffect(() => {
    currentDrivers = [];
    if (rides !== null) {
      rides.forEach((ride) => {
        ride.color = getRandomColor();
      });
      setLoading(false);
      if (map && maps) {
        renderPolylines(map, maps);
      }
    }
  }, [rides]);

  function getVehicleIcon(vehicle) {
    if (vehicle === '1') {
      return car;
    } else {
      return bike;
    }
  }

  function parseRidesToCoords(rides) {
    return rides.map((ride) => {
      return polyline
        .decode(ride.path)
        .map((coord) => ({ lat: coord[0], lng: coord[1] }));
    });
  }

  function includeDriver(ride) {
    if (!currentDrivers.includes(ride.driver_id)) {
      currentDrivers.push(ride.driver_id);
      return (
        <VehicleMarker
          key={ride.driver_id}
          lat={ride.current_latitude}
          icon={getVehicleIcon(ride.vehicle_type)}
          lng={ride.current_longitude}
          text={``}
          tooltip={ride.name + ' - ' + ride.plate}
          color={ride.color}
        />
      );
    } else {
      return '';
    }
  }

  function fitBounds(map, maps, rides) {
    var bounds = new maps.LatLngBounds();
    for (let marker of rides.map((x) => ({
      lat: x[0].lat,
      lng: x[0].lng,
    }))) {
      bounds.extend(new maps.LatLng(marker.lat, marker.lng));
    }
    map.fitBounds(bounds);
  }

  function renderPolylines(map, maps) {
    setMap(map);
    setMaps(maps);
    const parsedRides = parseRidesToCoords(rides);

    polylines.map((element) => {
      element.setMap(null);
    });

    var currentPolylines = [];
    parsedRides.forEach((rideCoords, i) => {
      let geodesicPolyline = new maps.Polyline({
        path: rideCoords,
        geodesic: true,
        strokeColor: rides[i].color,
        strokeOpacity: 1.0,
        strokeWeight: 3,
      });
      geodesicPolyline.setMap(map);
      currentPolylines.push(geodesicPolyline);
    });

    setPolylines(currentPolylines);

    // fitBounds(map, maps, parsedRides);
  }

  function getMap() {
    return (
      <GoogleMapReact
        style={{ zIndex: 2 }}
        bootstrapURLKeys={{
          key: 'AIzaSyDIKW_5YiUjXsBxX0JiiGpb1w73R3nJW04',
        }}
        defaultZoom={14}
        // defaultCenter={[-10.9498019, -37.0843285]}
        center={center}
        layerTypes={[]}
        hoverDistance={K_SIZE / 2}
        onGoogleApiLoaded={({ map, maps, ref }) => renderPolylines(map, maps)}
      >
        {rides.map((ride) => {
          return [
            includeDriver(ride),
            <Marker
              key={'destino' + ride.id}
              lat={ride.destination_latitude}
              lng={ride.destination_longitude}
              text={``}
              tooltip={ride.id + ' - Destino: ' + ride.destinationAddress}
              color={ride.color}
            />,
            <Marker
              key={'inicio' + ride.id}
              lat={ride.origin_latitude}
              lng={ride.origin_longitude}
              text={``}
              tooltip={ride.id + ' - Origem: ' + ride.originAddress}
              color={ride.color}
            />,
          ];
        })}
      </GoogleMapReact>
    );
  }

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      {!loading ? (
        <>
          {/* <RideCard
            rides={rides}
            onClick={(id) => {
              const ride = rides.find((ride) => ride.id === id);
              setCenter({
                lat: +ride.origin_latitude,
                lng: +ride.origin_longitude,
              });
            }}
          /> */}
          {getMap()}
        </>
      ) : (
        ''
      )}
    </div>
  );
}
