import React, { useState, useEffect } from 'react';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import Button from 'components/CustomButtons/Button.js';
import Tooltip from '@material-ui/core/Tooltip';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Check from '@material-ui/icons/Check';
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import brLocale from "date-fns/locale/pt-BR"
import {
  MuiPickersUtilsProvider,  
  DateTimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';

var mathjs = require('mathjs');

// import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.js';

const useStyles = makeStyles(styles);

export default function ScheduleOptions(props) {
  const [date, setDate] = useState(null);  
  const [isScheduled, setScheduled] = useState(false);  
  const [displayedDate, setDisplayedDate] = useState();
  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} lg={12}>
        <FormControlLabel
          control={
            <Checkbox
              tabIndex={-1}
              checked={isScheduled}
              onChange={event => {
                setScheduled(
                  event.target.checked
                )
                props.setDate(date && event.target.checked ? date.toISOString().slice(0, 19).replace('T', ' ') : null)
              }}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />
          }
          classes={{
            label: classes.label,
            root: classes.labelRoot
          }}
          label="O frete será agendado?"
        />
        <Card>
          {isScheduled ? 
          <CardBody>          
          <div style={{textAlign: 'center'}}>              
              <p>Em qual horário devemos buscar um motorista para o seu frete?</p>
            </div>
            <GridContainer>
              <GridItem xs={12} sm={12} lg={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
                  <DateTimePicker
                    style={{marginTop: "10px", width: '100%'}}                   
                    variant="dialog"
                    ampm={false}
                    inputProps={{
                      style:{
                        textAlign: "center"
                      }
                    }}
                    format="yyyy-MM-dd HH:mm"
                    id="date-time-picker-inline"                              
                    value={date}
                    emptyLabel=""
                    okLabel="OK"
                    disablePast
                    cancelLabel="Fechar"
                    minDate={new Date().setHours(new Date().getHours() + 1)}
                    maxDate={new Date().setHours(new Date().getHours() + 48)}
                    labelFunc={(date, invalidLavel) => {
                      return date ? format(date, 'dd/MM/yyyy HH:mm') : ''
                    }}
                    onChange={(date) => {                                
                      setDate(date)                      
                      props.setDate(format(date, 'yyyy-MM-dd HH:mm:ss'))
                      setDisplayedDate(date ? format(date, 'dd/MM/yyyy HH:mm') : '')
                      props.setActiveAcord(0)                      
                    }}                    
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />

                </MuiPickersUtilsProvider>
              </GridItem>              
            </GridContainer>
            <br></br>
            <div style={{textAlign: 'center'}}>
              <p><i>Se o pedido tiver mais de um destino será considerado o mesmo horário para todos.</i></p>
            </div>            
          </CardBody> : ''}                    
        </Card>
      </GridItem>
    </GridContainer>
  );
}