/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

// @material-ui/icons
import Add from '@material-ui/icons/Add';
//REDUX
import { connect } from 'react-redux';
import { getListCards } from '../../../redux/actions/card';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardHeader from 'components/Card/CardHeader.js';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import ReactTable from 'react-table';
// @material-ui/icons
import CreditCard from '@material-ui/icons/CreditCard';

import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js';

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  },
  personAdd: {
    textAlign: 'right'
  }
};

const useStyles = makeStyles(styles);

function CardTable(props) {
  const [cardsList, setCardsList] = useState();

  useEffect(() => {
    props.getListCards();
  }, []);

  useEffect(() => {
    if (props.cardsList !== undefined) {
      setCardsList(props.cardsList);
    }
  }, [props.cardsList]);
  // styles
  const classes = useStyles();

  /** FUNCTIONS */
  function addWallet() {
    props.history.push('/dashboard/card/add');
  }

  function goToCard(id) {
    props.history.push(`/dashboard/card/${id}`);
  }

  return (
    <div className={classes.content}>
          <div className={classes.container}>
{
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="purple" icon>
            <CardIcon color="purple">
              <CreditCard />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Cartões</h4>
          </CardHeader>
          <CardBody>
            <div className={classes.personAdd}>
              <Button color={'purple'} onClick={() => addWallet()}>
                <Add />
              </Button>
            </div>
            <ReactTable
              className="-striped -highlight"
              noDataText="Nenhum cartão cadastrado."
              data={cardsList ? cardsList.cards : []}
              resizable={false}
              sortable={false}
              manual
              loadingText={'Carregando...'}
              loading={cardsList ? false : true}
              previousText={'Anterior'}
              nextText={'Próximo'}
              showPageSizeOptions={false}
              defaultPageSize={cardsList ? cardsList.count : 0}
              getTdProps={(state, rowInfo, column, instance) => {
                return {
                  onClick: e => {
                    goToCard(rowInfo.original.id);
                    //redirectUser(rowInfo.original.id);
                  }
                };
              }}
              columns={[
                {
                  Header: 'Número',
                  accessor: 'number'
                },
                {
                  Header: 'Bandeira',
                  accessor: 'brand'
                }
              ]}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
}
</div></div>
  );
}

const mapStateToProps = state => {
  return {
    cardsList: state.card.cardsList
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getListCards: () => dispatch(getListCards())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardTable);
