import initialState from '../initialState';
import * as types from '../actions/actionTypes';

export default function rideReducer(state = initialState.ride, action) {
  switch (action.type) {
    case types.GET_RIDE_HISTORY_SUCCESS: {
      return {
        ...state,
        rideHistory: action.data,
      };
    }

    case types.GET_RIDE_HISTORY_ERROR: {
      return {
        ...state,
        rideHistory: action.error,
      };
    }

    case types.GET_ALL_HISTORY_SUCCESS: {
      return {
        ...state,
        allHistory: action.data
      };
    }

    case types.GET_ALL_HISTORY_ERROR: {
      return {
        ...state,
        allHistory: action.error
      };
    }

    case types.GET_RIDE_SUCCESS: {
      return {
        ...state,
        ride: action.data,
      };
    }

    case types.GET_RIDE_ERROR: {
      return {
        ...state,
        ride: action.error,
      };
    }
    
    case types.GET_PARENTS_SUCCESS: {
       return {
         ...state,
         waypoints: action.data
       }
     }
     
     case types.GET_PARENTS_ERROR: {
      return {
        ...state,
        waypoints: action.error
      }
    }

    case types.SCHEDULE_RIDE_SUCCESS: {
      return {
        ...state,
        scheduledRide: action.data,
      };
    }

    case types.SCHEDULE_RIDE_ERROR: {
      return {
        ...state,
        scheduledRide: action.error,
      };
    }

    case types.GET_DRIVER_LOCATION_SUCCESS: {
      return {
        ...state,
        driverLocation: action.data,
      };
    }

    case types.GET_DRIVER_LOCATION_ERROR: {
      return {
        ...state,
        driverLocation: action.error,
      };
    }

    case types.SIMULATE_RIDE_SUCCESS: {
      return {
        ...state,
        rideSimulated: action.data,
      };
    }

    case types.SIMULATE_RIDE_ERROR: {
      return {
        ...state,
        rideSimulated: action.error,
      };
    }

    case types.REQUEST_RIDE_SUCCESS: {
      return {
        ...state,
        rideRequested: action.data,
      };
    }

    case types.REQUEST_RIDE_ERROR: {
      return {
        ...state,
        rideRequested: action.error,
      };
    }

    case types.VALIDATE_RIDE_SUCCESS: {
      return {
        ...state,
        rideStatus: {
          data: action.data ? true : false,
          ax: state.rideStatus ? state.rideStatus.ax + 1 : 0,
        },
      };
    }

    case types.VALIDATE_RIDE_ERROR: {
      return {
        ...state,
        rideStatus: {
          data: action.error,
          ax: state.rideStatus ? state.rideStatus.ax + 1 : 0,
        },
      };
    }
    case types.TRACK_RIDE_SUCCESS: {
      return {
        ...state,
        trackRide: action.data,
      };
    }
    case types.TRACK_RIDE_ERROR: {
      return {
        ...state,
        trackRide: action.error,
      };
    }

    case types.SAVE_REQUEST_RIDE_FORM: {
      return {
        ...state,
        requestRideForm: action.model,
      };
    }

    case types.RESET_RIDE: {
      return {
        ...state,
        [action.prop]: undefined,
      };
    }

    case types.CANCEL_RIDE_SUCCESS: {
      return {
        ...state,
        canceledRide: action.data
      };
    }

    case types.CANCEL_RIDE_ERROR: {
      return {
        ...state,
        canceledRide: action.error
      };
    }

    case types.BATCH_SIMULATE_RIDE_SUCCESS: {
      return {
        ...state,
        rideBatched: action.data
      };
    }

    case types.BATCH_SIMULATE_RIDE_ERROR: {
      return {
        ...state,
        rideBatched: action.error
      };
    }

    default:
      return state;
  }
}
