import React, { useState, useEffect } from 'react';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import Button from 'components/CustomButtons/Button.js';
import Tooltip from '@material-ui/core/Tooltip';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Check from '@material-ui/icons/Check';
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

var mathjs = require('mathjs');

// import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.js';

const useStyles = makeStyles(styles);

export default function VolumeOptions(props) {
  const [width, setWidth] = useState();
  const [length, setLength] = useState();
  const [height, setHeight] = useState();
  const [weight, setWeight] = useState();
  const [isFood, setIsFood] = useState();
  const [error, setError] = useState();
  const [vehicle, setVehicle] = useState();

  const formatter = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2});

  const classes = useStyles();


  useEffect(() => {
    if (props.vehicle !== undefined) {
      setVehicle(props.vehicle);
    }
  }, [props.vehicle]);

  function moneyToFloat(value){

    return value.replace(/[.]/g, "",'').replace(/[,]/g, ".",'.')
  }

  function handleInputChange(e, dim) {
    
    if(dim == 0){      
      var cents = e.target.value.replace(/[^0-9]/g, "",'');      
      cents = mathjs.divide(cents, 100);
      setLength(formatter.format(cents))
    }else if(dim == 1){
      var cents = e.target.value.replace(/[^0-9]/g, "",'');      
      cents = mathjs.divide(cents, 100);
      setWidth(formatter.format(cents))
    }else if(dim == 2){
      var cents = e.target.value.replace(/[^0-9]/g, "",'');      
      cents = mathjs.divide(cents, 100);
      setHeight(formatter.format(cents))
    }else{
      var cents = e.target.value.replace(/[^0-9]/g, "",'');      
      cents = mathjs.divide(cents, 100);
      setWeight(formatter.format(cents))
    }
  }

  function calcHeight(){

    if(width && length && height){

      var calc = mathjs.multiply(moneyToFloat(width), moneyToFloat(length));
      calc = mathjs.multiply(calc, moneyToFloat(height));
      calc = mathjs.multiply(calc, 300);
      
      return calc
    }

    return null
  }
  
function confirmMeasures(){

  if(isFood){
    props.setIsFood(true)
    props.setMeasures({})
    props.setActiveAcord(3)
    return
  }

  if(props.vehicle == 0){

    if(moneyToFloat(width) > 0.6 || moneyToFloat(length) > 0.6 || moneyToFloat(height) > 0.6 || moneyToFloat(weight) > 20){
      setError('As dimensões máximas para o veículo escolhido são de 0,60m de largura, 0,60m de comprimento, 0,60m de altura e um peso máximo de 20kg.')
      return
    }else{
      setError(null)
    }

  }else{

    if(moneyToFloat(width) > 1.20 || moneyToFloat(length) > 1.20 || moneyToFloat(height) > 1.20 || moneyToFloat(weight) > 80){
      setError('As dimensões máximas para o veículo escolhido são de 1,20m de largura, 1,20m de comprimento, 1,20m de altura e um peso máximo de 80kg.')
      return
    }else{
      setError(null)
    }
  }

  if(width && length && height){

    var calc = mathjs.multiply(moneyToFloat(width), moneyToFloat(length));
    calc = mathjs.multiply(calc, moneyToFloat(height));
    calc = mathjs.multiply(calc, 300);

    props.setMeasures({
      width: moneyToFloat(width),
      length: moneyToFloat(length),
      height: moneyToFloat(height),
      weight: moneyToFloat(weight),
      calcWeight: calc
    });
    props.setIsFood(undefined)
    props.setActiveAcord(3)    
  }
}

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} lg={12}>
        <FormControlLabel
          control={
            <Checkbox
              tabIndex={-1}
              checked={isFood}
              onChange={event => setIsFood(event.target.checked)}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />
          }
          classes={{
            label: classes.label,
            root: classes.labelRoot
          }}
          label="Transporte de alimentos (FOOD)."
        />
        <Card>
          {!isFood ? 
          <CardBody>          
          <div style={{textAlign: 'center'}}>              
              <p>Qual o peso real do pacote em <b>quilos?</b></p>
            </div>
            <GridContainer>
              <GridItem xs={12} sm={12} lg={12}>
                <CustomInput
                  style={{color: '#000000', textAlign: 'center'}}
                  labelText="Peso real"
                  id="weight"                  
                  formControlProps={{
                    fullWidth: true
                  }}              
                  inputProps={{                  
                    value: weight,                    
                    onChange: e => handleInputChange(e, 3)
                  }}
                />              
              </GridItem>              
            </GridContainer>
            <div style={{textAlign: 'center'}}>
              <p>Quais as dimensões, em <b>metros</b>, do pacote?</p>
            </div>
            <GridContainer style={{textAlign: 'center'}}>
              <GridItem xs={12} sm={12} lg={4}>
                <CustomInput
                  style={{color: '#000000', textAlign: 'center'}}
                  labelText="Comprimento"
                  id="length"
                  formControlProps={{
                    fullWidth: true
                  }}                
                  inputProps={{                  
                    value: length,
                    onChange: e => handleInputChange(e, 0)
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} lg={4}>
                <CustomInput
                  style={{color: '#000000', textAlign: 'center'}}
                  labelText="Largura"
                  id="width"
                  formControlProps={{
                    fullWidth: true
                  }}                
                  inputProps={{                  
                    value: width,
                    onChange: e => handleInputChange(e, 1)
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} lg={4}>
                <CustomInput
                  style={{color: '#000000', textAlign: 'center'}}
                  labelText="Altura"
                  id="height"
                  formControlProps={{
                    fullWidth: true
                  }}                
                  inputProps={{                  
                    value: height,
                    onChange: e => handleInputChange(e, 2)
                  }}
                />
              </GridItem>
              <p style={{textAlign: 'center', width: '100%'}}><b>{calcHeight() ? `Peso cubado: ${formatter.format(calcHeight())}kg` : ''}</b></p><br></br>              
            </GridContainer>            
          </CardBody> : ''}
          {error ? (
            <p style={{color: 'red', width: '100%', textAlign: 'center', padding: '5%'}}>{error}</p>
          ): ''}
          {isFood || calcHeight() ? (
            <Button color="success" onClick={() => confirmMeasures()}>
            Próximo
          </Button>
          ) : ''}          
        </Card>
      </GridItem>
    </GridContainer>
  );
}
