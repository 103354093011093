export const dispatch = (type, url, args) => {
  return { type, url, args };
};

export function getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export const parseDateToRequest = (filterDate) => {
  if (filterDate === 'hoje') {
    const today = new Date(Date.now());
    return { month: today.getMonth(), year: today.getFullYear() };
  }
  switch (filterDate.length) {
    case 1:
      return { month: +filterDate, year: null };
    case 2:
      return { month: +filterDate, year: null };
    case 4:
      return { month: null, year: +filterDate };
    case 7:
      return {
        month: +filterDate.substring(0, 2),
        year: +filterDate.substring(3, 7),
      };
    default:
      return { month: null, year: null };
  }
};

export function formatTimeDate(
  dateTime,
  timeSeparator = ':',
  dateSeparator = '/'
) {
  const arrDateTime = dateTime.split(' ');
  let date = arrDateTime[0];
  let time = arrDateTime[1];
  date = date.split('-');
  date = date[2] + dateSeparator + date[1] + dateSeparator + date[0];
  time = time.split(':');
  time = time[0] + timeSeparator + time[1] + timeSeparator + time[2];

  return [date, time];
}

export function formatMoney(
  numb,
  decimalQtd = 2,
  decimal = ',',
  thousands = '.'
) {
  try {
    let amount = numb;
    let decimalCount = Math.abs(decimalQtd);
    decimalCount = Number.isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    const i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)),
      10
    ).toString();
    const j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    console.log(e);
  }
}

export const verifyCpf = (cpf) => {
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf === '') return false;
  // Elimina CPFs invalidos conhecidos
  if (
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  )
    return false;
  // Valida 1o digito
  let add = 0;
  for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(9))) return false;
  // Valida 2o digito
  add = 0;
  for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(10))) return false;
  return true;
};

export function verifyLuhn(luhn) {
  let len = luhn.length,
    mul = 0,
    prodArr = [[0, 1, 2, 3, 4, 5, 6, 7, 8, 9], [0, 2, 4, 6, 8, 1, 3, 5, 7, 9]],
    sum = 0;

  while (len--) {
    sum += prodArr[mul][parseInt(luhn.charAt(len), 10)];
    mul ^= 1;
  }

  return sum % 10 === 0 && sum > 0;
}

// export function verifyLuhn(value) {
//   // Accept only digits, dashes or spaces
//   if (/[^0-9-\s]+/.test(value)) return false;

//   // The Luhn Algorithm. It's so pretty.
//   let nCheck = 0,
//     bEven = false;
//   value = value.replace(/\D/g, '');

//   for (var n = value.length - 1; n >= 0; n--) {
//     var cDigit = value.charAt(n),
//       nDigit = parseInt(cDigit, 10);

//     if (bEven && (nDigit *= 2) > 9) nDigit -= 9;

//     nCheck += nDigit;
//     bEven = !bEven;
//   }

//   return nCheck % 10 === 0;
// }
