import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem('token') ? (
        <Component {...props} />
      ) : (
        <Redirect exact to="/logout" />
      )
    }
  />
);

export function verifyFlag(flag, user) {
  if (flag === 'INCOME' && user.id !== user.business.id) return false;
  return true;
}

export const getRoutes = (name, routes, user) => {
  return routes.map((prop, key) => {
    if (user && prop.flag && !verifyFlag(prop.flag, user)) return null;
    if (prop.collapse) {
      return getRoutes(prop.layout, prop.views);
    }
    if (prop.layout === name) {
      if (prop.protected) {
        return (
          <PrivateRoute
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      if (prop.path === '/') {
        return (
          <Route
            exact
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return (
        <Route
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
        />
      );
    } else {
      return null;
    }
  });
};
