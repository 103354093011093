/** eslint-disable  no-unused-expressions */
import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import {
  Grid,
  Card,
  CircularProgress,
  Select,
  MenuItem,
} from '@material-ui/core';
import Button from 'components/CustomButtons/Button.js';
// import { getIncome, getBusinessList } from '../../../requests/requests';
import HeaderItem from './HeaderItem';
import { useDebounce } from '../../../utils/hooks';
import {
  formatMoney,
  formatTimeDate,
  parseDateToRequest,
} from '../../../utils/functions';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getIncome, getAllIncomes } from '../../../redux/actions/user';
import {CSVLink} from "react-csv";
import Typography from '@material-ui/core/Typography';

export default Income;

function Income(props) {
  const dispatch = useDispatch();
  const stats = useSelector((state) => state.user.income);
  const count = useSelector((state) => state.user.income.count);
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [pageLength, setPageLength] = useState(30);
  const [filterState, setFilterState] = useState({
    ride_id: '',
    month_year: '',
    // requester_id: '',
    external_id: '',
  });
  // const [stats, setStats] = useState({ rides: [], count: 0 });
  // const [businessList, setBusinessList] = useState([]);

  const debouncedRideId = useDebounce(filterState.ride_id, 1000);
  const debouncedMonthYear = useDebounce(filterState.month_year, 1000);
  // const debouncedRequesterId = useDebounce(filterState.requester_id, 1000);
  const debouncedExternalId = useDebounce(filterState.external_id, 1000);

  const csvRes = useSelector((state) => state.user.allIncomes);
  const [csvData, setCsvData] = useState([]);
  const [csvLink, setCsvLink] = useState();
  const [csvLoading, setCsvLoading] = useState(false);

  const headers = [
    {
      label: 'Código do frete',
      key: 'id',
    },
    {
      label: 'Código de controle',
      key: 'external_id',
    },
    {
      label: 'Destino',
      key: 'to',
    },
    {
      label: 'Data/Hora',
      key: 'date',
    },
    {
      label: 'Valor total do frete',
      key: 'price',
    },
  ];

  // useEffect(() => {
  //   dispatch(getIncome());
  //   getBusinessList().then((res) => {
  //     setBusinessList(res.data.businesses);
  //   });
  // }, []);

  /** FUNCTIONS */
  function redirectRide(id) {
    props.history.push(`/dashboard/ride/${id}`);
  }

  useEffect(() => {
    // setStats(res.data);
    setLoading(false);
    setTableLoading(false);
  }, [stats]);

  useEffect(()=>{
    if (csvRes !== undefined){
      let data = [];
      csvRes.rides.map(r=>{
        data.push({
          "id": r.id,
          "external_id": r.external_id,
          "to": r.destinationAddress,
          'date': r.date,
          'price': formatMoney(r.price.total_price),
        });
      });
      setCsvData(data);
      setTableLoading(false);
      setCsvLoading(false);
    }
  },[csvRes])

  useEffect(() => {
    console.log('inicio');
    if (debouncedRideId || debouncedMonthYear || debouncedExternalId) {
      const { month, year } = parseDateToRequest(debouncedMonthYear);
      setTableLoading(true);
      dispatch(
        getIncome({
          index: page * pageLength,
          length: pageLength,
          ride_id: debouncedRideId,
          external_id: debouncedExternalId,
          // requester_id: debouncedRequesterId,
          month,
          year,
        })
      );
      // dispatch(
      //   getAllIncomes({ 
      //     all:true, 
      //     index: 0, 
      //     length: count,
      //     ride_id: debouncedRideId,
      //     external_id: debouncedExternalId,
      //     month,
      //     year, 
      //   })
      // );
      // .then((res) => {
      //   setStats(res.data);
      //   setLoading(false);
      //   setTableLoading(false);
      // })
      // .catch(() => {
      //   setLoading(false);
      //   setTableLoading(false);
      // });
    } else {
      console.log('else');
      setTableLoading(true);
      dispatch(getIncome({ index: page * pageLength, length: pageLength }));
      // dispatch(getAllIncomes({ all:true, index: 0, length: count }));
      // .then((res) => {
      //   setStats(res.data);
      //   setLoading(false);
      //   setTableLoading(false);
      // })
      // .catch(() => {
      //   setLoading(false);
      //   setTableLoading(false);
      // });
    }
  }, [
    page,
    pageLength,
    debouncedRideId,
    debouncedMonthYear,
    debouncedExternalId,
    dispatch,
  ]);

  function getCsvData(){
    if (count === stats.count){
      console.log()
      if (debouncedRideId || debouncedMonthYear || debouncedExternalId) {
        const { month, year } = parseDateToRequest(debouncedMonthYear);
        setCsvLoading(true);
        dispatch(
          getAllIncomes({ 
            all:true, 
            index: 0, 
            length: count,
            ride_id: debouncedRideId,
            external_id: debouncedExternalId,
            month,
            year, 
          })
        );
      } else {
        console.log('else');
        setCsvLoading(true);
        dispatch(getAllIncomes({ all:true, index: 0, length: count }));
       }
    }
  }

  
  function availableDates(startDate, endDate) {
    var startDate = moment('2020-04-01');
    var endDate = moment();

    var result = [];

    if (endDate.isBefore(startDate)) {
      throw 'End date must be greated than start date.';
    }

    while (startDate.isBefore(endDate)) {
      result.push(startDate.format('MM/YYYY'));
      startDate.add(1, 'month');
    }

    return result;
  }

  function downloadCSV(){
    if (csvLink !== undefined && csvData !== undefined){
      if (csvData.length === Number(count)){
        csvLink.link.click();
      }
    }
  }

  return (
    <Grid
      container
      component={Card}
      style={{
        minHeight: '80vh',
        padding: '3% 3%',
      }}
      justify="center"
      alignItems="center"
    >
      {loading ? (
        <CircularProgress color="secondary" />
      ) : (
        <>
          <Grid item md={8}container justify="flex-start">
          <Typography variant='caption'>Os valores exportados serão iguais aos presentes na tabela no momento da exportação.</Typography>
          </Grid>
          <Grid item md={4}container justify="flex-end">
            <div style={{textAlign:'left', marginBottom:10}}>
              {csvData.length === Number(count) ? 
              <>
                <CSVLink
                  data={csvData}
                  headers={headers}
                  separator={";"}
                  filename="dataIncome.csv"
                  className="hidden"
                  ref={(r) => setCsvLink(r)}
                  target="_blank"/>
                <Button
                  size="sm"
                  color="success"
                  onClick={()=>downloadCSV()}>
                  SALVAR
                </Button>
              </>
              :
              <div style={{
                position:'relative',
                margin:1
              }}>
                <Button
                  outline
                  size="sm"
                  color="purple"
                  disabled={csvLoading}
                  onClick={()=>getCsvData()}>
                  {csvLoading ? "PREPARANDO" : "EXPORTAR"}
                </Button>
                {csvLoading && <CircularProgress size={24} style={{
                    color: '#FFF',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: -12,
                    marginLeft: -12}} 
                  />
                }
              </div>
              }
            </div>
          </Grid>
          <Grid item md={12} container>
            <Grid item md={6}>
              <HeaderItem
                title="Valor total"
                value={
                  stats.total_price
                    ? 'R$ ' + formatMoney(stats.total_price)
                    : ''
                }
              />
            </Grid>
            <Grid item md={6} style={{ textAlign: 'right' }}>
              <HeaderItem
                title="Total de corridas"
                value={stats.count ? stats.count : ''}
              />
            </Grid>
          </Grid>
          <Grid item md={12} container>
            <ReactTable
              style={{ width: '100%', textAlign: 'center' }}
              className="-striped -highlight"
              data={stats.rides}
              sortable={false}
              defaultPageSize={pageLength}
              pages={Math.ceil(stats.count / pageLength)}
              loading={tableLoading}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText="Nenhum dado encontrado"
              manual
              onFetchData={(state) => {
                const obj = {
                  ride_id: '',
                  month_year: '',
                  external_id: ''
                };

                state.filtered.forEach((filter) => {
                  obj[filter.id] = filter.value;
                });

                setFilterState(obj);

                setPage(state.page);

                setPageLength(state.pageSize);
              }}
              getTdProps={(state, rowInfo, column, instance) => {
                return {
                  onClick: (e) => {
                    redirectRide(rowInfo.original.id);
                  },
                };
              }}
              columns={[
                {
                  id: 'ride_id',
                  Header: 'Cód. do frete',
                  accessor: (row) => row.id,
                  filterable: true,
                },
                {
                  id: 'external_id',
                  Header: 'Cód. Controle',
                  accessor: (row) => row.external_id,
                  filterable: true,
                },
                {
                  id: 'month_year',
                  Header: 'Data',
                  accessor: (row) => formatTimeDate(row.date)[0],
                  filterable: true,
                  Filter: (
                    <Select
                      id="date-select"
                      fullWidth
                      value={filterState.month_year}
                      onChange={(e) =>
                        setFilterState({
                          ...filterState,
                          month_year: e.target.value,
                        })
                      }
                    >
                      <MenuItem value="">Qualquer</MenuItem>
                      {availableDates().map((date) => (
                        <MenuItem key={date} value={date}>
                          {date}
                        </MenuItem>
                      ))}
                    </Select>
                  ),
                },
                // {
                //   id: 'requester_id',
                //   Header: 'Cod. Cliente',
                //   accessor: (row) => row.requester_id,
                //   filterable: true,
                //   Filter: (
                //     <Select
                //       id="requester-select"
                //       fullWidth
                //       value={filterState.requester_id}
                //       onChange={(e) =>
                //         setFilterState({
                //           ...filterState,
                //           requester_id: e.target.value,
                //         })
                //       }
                //     >
                //       <MenuItem value="">Todos</MenuItem>
                //       {businessList.map((business) => (
                //         <MenuItem key={business.id} value={business.id}>
                //           {business.name}
                //         </MenuItem>
                //       ))}
                //     </Select>
                //   ),
                // },
                {
                  id: 'destinationAddress',
                  Header: 'Destino',
                  accessor: (row) =>
                    row.destinationAddress.substring(0, 25) + '...',
                },
                {
                  id: 'money',
                  Header: 'Valor total do frete',
                  accessor: (row) => `R$ ${formatMoney(row.price.total_price)}`,
                },
              ]}
              showPageJump={false}
            />
          </Grid>
        </>
      )}
    </Grid>
  );}