import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import { watchEntity } from './sagas/mainSagas';
import rootReducer from './reducers';

export default function configureStore(initialState) {
  const sagaMiddleware = createSagaMiddleware();

  //This array can be populated based on environment variables.
  const middlewares = [sagaMiddleware];

  //Can be use for middleware used just for development.
  if (process.env.NODE_ENV === 'development') {
    middlewares.push(logger);
  }

  const store = createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(...middlewares),
      window.devToolsExtension ? window.devToolsExtension() : f => f //Enable Redux Dev Tools
    )
  );

  //making store available on console.
  if (process.env.NODE_ENV === 'development') {
    window.store = store;
  }

  sagaMiddleware.run(watchEntity);

  return store;
}
