export default {
  user: {
    userAuth: undefined,
    createdUser: undefined,
    passwordUpdated: undefined,
    income: { rides: [], count: 0 },
    allIncomes: { rides: [], count: 0 },
    bankSlipGenerated: undefined,
    totalCash: undefined,
    bankSlipsList: undefined,
    driversQueue: undefined,
  },
  common: {
    loginSuccess: null,
    loading: false,
  },
  card: {
    cardsList: undefined,
    cardAdd: undefined,
  },
  ride: {
    allHistory: undefined,
    rideHistory: undefined,
    rideFilter: undefined,
    ride: undefined,
    driverLocation: undefined,
    rideSimulated: undefined,
    rideRequested: undefined,
    cancelRide: undefined,
    rideStatus: undefined,
    requestRideForm: undefined,
    scheduledRide: undefined,
    trackRide: { rides: null },
    rideParent: undefined,
  },
};
