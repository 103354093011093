import React, { useState, useEffect } from 'react';
// @material-ui/core components
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons
import Person from '@material-ui/icons/Person';
import LockOutlined from '@material-ui/icons/LockOutlined';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardFooter from 'components/Card/CardFooter.js';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.js';

const useStyles = makeStyles(styles);

export default function Login(props) {
  const [cardAnimaton, setCardAnimation] = useState('cardHidden');

  /** EFFECTS */
  useEffect(() => {
    let timeOutFunction = setTimeout(function() {
      setCardAnimation('');
    }, 700);

    return function cleanup() {
      clearTimeout(timeOutFunction);
      timeOutFunction = null;
    };
  }, []);

  // styles
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="purple"
              >
                <h4 className={classes.cardTitle}>Log in</h4>
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="Email"
                  id="email"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Person className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    onChange: e => props.handleChange('email', e)
                  }}
                />
                <CustomInput
                  labelText="Senha"
                  id="password"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <LockOutlined className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    type: 'password',
                    autoComplete: 'off',
                    onChange: e => props.handleChange('password', e)
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                {props.loading ? (
                  <CircularProgress />
                ) : (
                  <Button
                    color="purple"
                    simple
                    size="lg"
                    block
                    onClick={() => props.login()}
                  >
                    Entrar
                  </Button>
                )}
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
