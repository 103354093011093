/* global google */
import React from 'react';

import ReactGooglePlacesSuggest from 'react-google-places-suggest';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
// @material-ui/icons
import WhereToVote from '@material-ui/icons/WhereToVote';
import Map from '@material-ui/icons/Map';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';

const style = {
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center'
  },
  inputAdornmentIcon: {
    color: '#555'
  },
  inputAdornment: {
    position: 'relative'
  }
};

class AnddressStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      value: '',
      valueState: '',
      line2: null,
      latitude: 0,
      longitude: 0
    };
  }

  /** FUNCTIONS */
  sendState() {
    return this.state;
  }
  handleInputChange(e, stateName) {
    switch (stateName) {
      case 'address':
        this.setState({ search: e.target.value });
        this.setState({ value: e.target.value });
        this.setState({ valueState: 'error' });
        break;
      case 'line2':
        this.setState({ line2: e.target.value });
        break;
      default:
        break;
    }
  }

  handleNoResult() {
    console.log('No result for', this.state.search);
  }

  handleSelectSuggest(geocodedPrediction, originalPrediction) {
    this.setState({
      latitude: geocodedPrediction.geometry.location.lat()
    });
    this.setState({
      longitude: geocodedPrediction.geometry.location.lng()
    });
    this.setState({ search: '' });
    this.setState({ value: geocodedPrediction.formatted_address });
    this.setState({ valueState: 'success' });
  }

  isValidated() {
    if (this.state.valueState === 'success') {
      return true;
    } else {
      if (this.state.valueState !== 'success') {
        this.setState({ valueState: 'error' });
      }
    }
    return false;
  }

  render() {
    const { classes } = this.props;

    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12}>
          <h4 className={classes.infoText}>
            Preencha aqui o endereço do seu estabelecimento.
          </h4>
        </GridItem>
        <GridItem xs={12} sm={6}>
          <ReactGooglePlacesSuggest
            googleMaps={google.maps}
            autocompletionRequest={{
              input: this.state.search,
              componentRestrictions: { country: 'br' }
            }}
            onNoResult={this.handleNoResult}
            onSelectSuggest={(geocodedPrediction, originalPrediction) =>
              this.handleSelectSuggest(geocodedPrediction, originalPrediction)
            }
            textNoResults="Endereço não encontrado" // null or "" if you want to disable the no results item
            customRender={prediction => (
              <div className="customWrapper">
                                          
                {prediction
                  ? prediction.description
                  : 'Endereço não encontrado'}
                                        
              </div>
            )}
          >
            <CustomInput
              success={this.state.valueState === 'success'}
              error={this.state.valueState === 'error'}
              labelText={
                <span>
                  Endereço <small>(obrigatório)</small>
                </span>
              }
              id="destination"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                value: this.state.value,
                onChange: e => this.handleInputChange(e, 'address'),
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <WhereToVote className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                )
              }}
            />
          </ReactGooglePlacesSuggest>
          <CustomInput
            labelText={<span>Complemento</span>}
            id="line2"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.handleInputChange(event, 'line2'),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <Map className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={10}></GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(style)(AnddressStep);
