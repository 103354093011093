/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";

import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

import { formatMoney } from "../../utils/functions";
import { Typography } from "@material-ui/core";

function CanceledRide(props) {
  const [open, setOpen] = useState(true);
  const [ride, setRide] = useState([]);
  const [originAddress, setOriginAddress] = useState([]);
  const [destinationAddress, setDestinationAddress] = useState([]);
  const [vehicleType, setVehicleType] = useState('');
  const [volumeQnty, setVolumeQnty] = useState([]);
  const [volumeType, setVolumeType] = useState([]);
  const [normalPrice, setNormalPrice] = useState();
  const [optimizedPrice, setOptimizedPrice] = useState();
  const [normalButton, setNormalButton] = useState(false);
  const [optimizedButton, setOptimizedButton] = useState(false);
  const [selected, setSelected] = useState(false);
  const [optimized, setOptimized] = useState(false);

  /** EFFECTS */
  useEffect(()=>{
    if (props.rides !== undefined){
      setRide(props.rides);
      let arr = [];
      let volumeArr = [];
      let typeArr = [];
      let originArr = [];
      if (props.rides.length > 1){
        props.rides.map(ride => {
          originArr.push(ride.originAddress)
          arr.push(ride.destinationAddress)
          volumeArr.push(ride.num_volumes)
          let type = (ride.measures || ride.volume || ride.is_food == 1
            ? 'Pacote'
            : 'Documentos')
          typeArr.push(type)
        })
        setOriginAddress(originArr);
        setDestinationAddress(arr);
        setVehicleType(props.rides[0].vehicle_type.name);
        setVolumeQnty(volumeArr);
        setVolumeType(typeArr);
      } else {
        setOriginAddress([props.rides[0].originAddress]);
        setDestinationAddress([props.rides[0].destinationAddress]);
        setVehicleType(props.rides[0].vehicle_type.name);
        setVolumeQnty([props.rides[0].num_volumes]);
        let type = (ride.measures || ride.volume || ride.is_food == 1
          ? 'Pacote'
          : 'Documentos')
        setVolumeType([type]);
        setSelected(true);
      }
    }
  },[props.rides])

  useEffect(() => {
    if (props.canceledBatchSimulation !== undefined) {
      setNormalPrice(props.canceledBatchSimulation.normal_price);
      setOptimizedPrice(props.canceledBatchSimulation.optimized_price);
    }
  }, [props.canceledBatchSimulation]);

  /** FUNCTIONS */
  function handleClose(){
    setOpen(false);
    props.setCanceledSimulation(false);
  }

  function handleRequest(){
    props.requestCanceledRide(optimized);
  }

  function handleNormal() {
    setSelected(true);
    setNormalButton(true);
    if (optimizedButton) setOptimizedButton(false);
  }

  function handleOptimal() {
    setSelected(true);
    setOptimizedButton(true);
    if (normalButton) setNormalButton(false);
    setOptimized(true);
  }

  // TOOLTIP
  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }))(Tooltip);

  return (
    <GridContainer>
      <GridItem xs={1} sm={1} md={2} lg={4}></GridItem>
      <GridItem xs={10} sm={10} md={8} lg={4}>
        <Dialog
          maxWidth="md"
          fullWidth
          open={open}
          scroll="paper"
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Revise os dados</DialogTitle>
          <DialogContent>
            <GridContainer
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardBody>
                    {ride.length > 1 ? (
                      <>
                      {ride.map((_,index) => (
                      <Card key={index}>
                        <CardBody>
                          <Typography variant="subtitle2" align="center">
                            {`Frete ${index+1}`}
                          </Typography>
                          <div style={{display: "flex", flex: 1, flexDirection: "row"}}>
                            <p style={{fontWeight: 'bold'}}>Endereço de origem: </p>
                            <span style={{paddingLeft: 5 }}>{originAddress[index]}</span>
                          </div>
                          <div style={{display: "flex", flex: 1, flexDirection: "row"}}>
                            <p style={{fontWeight: 'bold'}}>Endereço de destino: </p>
                            <span style={{paddingLeft: 5 }}> {destinationAddress[index]}</span>
                          </div>
                          <div style={{display: "flex", flex: 1, flexDirection: "row"}}>
                            <p style={{fontWeight: 'bold'}}>Tipo de veículo: </p>
                            <span style={{paddingLeft: 5 }}>{vehicleType}</span>
                          </div>
                          <div style={{display: "flex", flex: 1, flexDirection: "row"}}>
                            <p style={{fontWeight: 'bold'}}>Quantidade de volumes: </p>
                            <span style={{paddingLeft: 5 }}>{volumeQnty[index]}</span>
                          </div>
                          <div style={{display: "flex", flex: 1, flexDirection: "row"}}>
                            <p style={{fontWeight: 'bold'}}>Tipo de conteúdo: </p>
                            <span style={{paddingLeft: 5 }}>{volumeType[index]}</span>
                          </div>
                        </CardBody>
                      </Card>
                      ))}
                      </>
                    ) : (
                      <>
                      <div style={{display: "flex", flex: 1, flexDirection: "row"}}>
                        <p style={{fontWeight: 'bold'}}>Endereço de origem: </p>
                        <span style={{paddingLeft: 5 }}>{originAddress}</span>
                      </div>
                      <div style={{display: "flex", flex: 1, flexDirection: "row"}}>
                        <p style={{fontWeight: 'bold'}}>Endereço de destino: </p>
                        <span style={{paddingLeft: 5 }}>{destinationAddress}</span>
                      </div>
                      <div style={{display: "flex", flex: 1, flexDirection: "row"}}>
                        <p style={{fontWeight: 'bold'}}>Tipo de veículo: </p>
                        <span style={{paddingLeft: 5 }}>{vehicleType}</span>
                      </div>
                      <div style={{display: "flex", flex: 1, flexDirection: "row"}}>
                        <p style={{fontWeight: 'bold'}}>Quantidade de volumes: </p>
                        <span style={{paddingLeft: 5 }}>{volumeQnty}</span>
                      </div>
                      <div style={{display: "flex", flex: 1, flexDirection: "row"}}>
                        <p style={{fontWeight: 'bold'}}>Tipo de conteúdo: </p>
                        <span style={{paddingLeft: 5 }}>{volumeType}</span>
                      </div>
                      </>
                    )}
                    <br />
                    {optimizedPrice !== null ? (
                      <div style={{ 
                        display: "flex", 
                        flexDirection: "row", 
                        justifyContent: "space-evenly",
                      }}>
                        <Card>
                          <div style={{marginTop: 8}}>
                            <CardHeader>
                              <Typography variant="body2" align="center">
                                Valor da corrida
                              </Typography>
                            </CardHeader>
                            <CardBody>
                              <Typography variant="button" align="center">
                                Sua rota: 
                              </Typography>
                              <br />
                              <Typography variant="h6" align="center">
                                {normalPrice !== undefined
                                  ? ` R$${formatMoney(normalPrice.total_price)}`
                                  : ""}
                              </Typography>
                              <div
                                style={{
                                  textAlign: "center",
                                  width: "100%",
                                  marginTop: 10,
                                  marginBottom: 10,
                                }}
                              >
                                <Button
                                  onClick={() => handleNormal()}
                                  outline
                                  // color={normalButton ? 'primary' : 'purple'}
                                  color={"primary"}
                                  style={{ width: "100%" }}
                                >
                                  {normalButton ? "Selecionado" : "Selecionar"}
                                </Button>
                              </div>
                            </CardBody>
                          </div>
                        </Card>                        
                          { optimizedPrice !== undefined && normalPrice !== undefined && optimizedPrice.total_price < normalPrice.total_price ? (
                          <Card>
                            <CardHeader>
                              <Typography variant="body2" align="center">
                                Valor da corrida
                              </Typography>
                            </CardHeader>
                            <CardBody>
                              <Typography variant="button" align="center">
                                Rota otimizada:
                              </Typography>
                              <HtmlTooltip
                                title={
                                  <React.Fragment>
                                    <Typography color="inherit">
                                      Rota otimizada
                                    </Typography>
                                    {
                                      "Quando uma corrida possui múltiplos destinos, nós realizamos uma otimização para reduzir o custo e tempo da corrida, mudando a ordem dos destinos."
                                    }
                                  </React.Fragment>
                                }
                              >
                                <HelpOutlineIcon />
                              </HtmlTooltip>
                              <br />
                              <Typography variant="h6" align="center">
                                {optimizedPrice !== undefined
                                  ? ` R$${formatMoney(optimizedPrice.total_price)}`
                                  : ""}
                              </Typography>                            
                              <div
                                style={{
                                  textAlign: "center",
                                  width: "100%",
                                  marginTop: 10,
                                  marginBottom: 10,
                                }}
                              >
                                <Button
                                  onClick={() => handleOptimal()}
                                  outline
                                  color={"primary"}
                                  // color={optimizedButton ? 'primary' : 'purple'}
                                  style={{ width: "100%" }}
                                >
                                  {optimizedButton ? "Selecionado" : "Selecionar"}
                                </Button>
                              </div>
                            </CardBody>                            
                        </Card>
                        ) : ""}
                      </div>
                    ) : (
                    <>
                      <Typography variant="button">
                        Valor da corrida:
                      </Typography>
                      <br />
                      <Typography variant="h6" align="center">
                        {normalPrice !== undefined
                          ? `R$${formatMoney(normalPrice.total_price)}`
                          : ""}
                      </Typography>
                    </>
                    )}
                    <br />
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions>
            <div style={{display: "flex", justifyContent: "space-between"}}>
              <Button 
                onClick={() => handleClose()} 
                color="white"
                style={{ width: 190 }}
              >
                Cancelar
              </Button>
              {selected ? (
                <Button
                  onClick={() => handleRequest()}
                  outline
                  color={"primary"}
                  // style={{ width: "100%" }}
                >
                  Pedir novamente
                </Button>
              ) : (
                ""
              )}
            </div>
            </DialogActions>
        </Dialog>
      </GridItem>
    </GridContainer>
  );
}

export default CanceledRide;