import initialState from '../initialState';
import * as types from '../actions/actionTypes';

export default function commonReducer(state = initialState.common, action) {
  switch (action.type) {
    case types.LOGIN_SUCCESS: {
      localStorage.setItem('token', JSON.stringify(action.data.token));
      return {
        ...state,
        loginSuccess: true
      };
    }

    case types.LOGIN_ERROR: {
      return {
        ...state,
        loginSuccess: action.error
      };
    }

    case types.LOGOUT: {
      return {
        ...state,
        logout: true
      };
    }

    case types.SET_LOADING: {
      return {
        ...state,
        loading: action.value
      };
    }

    case types.RESET_COMMON: {
      return {
        ...state,
        [action.prop]: undefined
      };
    }

    default:
      return state;
  }
}
