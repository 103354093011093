/**
 * COMMON
 */
export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT = 'LOGOUT';
export const SET_LOADING = 'SET_LOADING';
export const RESET_COMMON = 'RESET_COMMON';

/**
 * USER ACTIONS
 */
export const IDENTIFY_USER = 'IDENTIFY_USER';
export const IDENTIFY_USER_SUCCESS = 'IDENTIFY_USER_SUCCESS';
export const IDENTIFY_USER_ERROR = 'IDENTIFY_USER_ERROR';
export const ADD_USER = 'ADD_USER';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_ERROR = 'ADD_USER_ERROR';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR';
export const RESET_USER = 'RESET_USER';
export const GET_ALL_INCOMES = "GET_ALL_INCOMES";
export const GET_ALL_INCOMES_SUCCESS = "GET_ALL_INCOMES_SUCCESS";
export const GET_ALL_INCOMES_ERROR = "GET_ALL_INCOMES_ERROR";
export const GENERATE_BANK_SLIP = "GENERATE_BANK_SLIP";
export const GENERATE_BANK_SLIP_SUCCESS = "GENERATE_BANK_SLIP_SUCCESS";
export const GENERATE_BANK_SLIP_ERROR = "GENERATE_BANK_SLIP_ERROR";
export const GET_TOTAL_CASH = "GET_TOTAL_CASH"
export const GET_TOTAL_CASH_SUCCESS = "GET_TOTAL_CASH_SUCCESS"
export const GET_TOTAL_CASH_ERROR = "GET_TOTAL_CASH_ERROR";
export const GET_PENDING_BANK_SLIPS = "GET_PENDING_BANK_SLIPS";
export const GET_PENDING_BANK_SLIPS_SUCCESS = "GET_PENDING_BANK_SLIPS_SUCCESS";
export const GET_PENDING_BANK_SLIPS_ERROR = "GET_PENDING_BANK_SLIPS_ERROR";
export const GET_DRIVERS_QUEUE = "GET_DRIVERS_QUEUE";
export const GET_DRIVERS_QUEUE_SUCCESS = "GET_DRIVERS_QUEUE_SUCCESS";
export const GET_DRIVERS_QUEUE_ERROR = "GET_DRIVERS_QUEUE_ERROR";

/**
 * CARDS
 */
export const GET_LIST_CARDS = 'GET_LIST_CARDS';
export const GET_LIST_CARDS_SUCCESS = 'GET_LIST_CARDS_SUCCESS';
export const GET_LIST_CARDS_ERROR = 'GET_LIST_CARDS_ERROR';
export const ADD_CARD = 'ADD_CARD';
export const ADD_CARD_SUCCESS = 'ADD_CARD_SUCCESS';
export const ADD_CARD_ERROR = 'ADD_CARD_ERROR';
export const RESET_CARDS = 'RESET_CARDS';
export const DELETE_CARD = 'DELETE_CARD';
export const DELETE_CARD_SUCCESS = 'DELETE_CARD_SUCCESS';
export const DELETE_CARD_ERROR = 'DELETE_CARD_ERROR';

/**
 * RIDE
 */
export const GET_RIDE_HISTORY = 'GET_RIDE_HISTORY';
export const GET_RIDE_HISTORY_SUCCESS = 'GET_RIDE_HISTORY_SUCCESS';
export const GET_RIDE_HISTORY_ERROR = 'GET_RIDE_HISTORY_ERROR';
export const GET_ALL_HISTORY = "GET_ALL_HISTORY";
export const GET_ALL_HISTORY_SUCCESS = "GET_ALL_HISTORY_SUCCESS";
export const GET_ALL_HISTORY_ERROR = "GET_ALL_HISTORY_ERROR";
export const GET_RIDE = 'GET_RIDE';
export const GET_RIDE_SUCCESS = 'GET_RIDE_SUCCESS';
export const GET_RIDE_ERROR = 'GET_RIDE_ERROR';
export const GET_DRIVER_LOCATION = 'GET_DRIVER_LOCATION';
export const GET_DRIVER_LOCATION_SUCCESS = 'GET_DRIVER_LOCATION_SUCCESS';
export const GET_DRIVER_LOCATION_ERROR = 'GET_DRIVER_LOCATION_ERROR';
export const SIMULATE_RIDE = 'SIMULATE_RIDE';
export const SIMULATE_RIDE_SUCCESS = 'SIMULATE_RIDE_SUCCESS';
export const SIMULATE_RIDE_ERROR = 'SIMULATE_RIDE_ERROR';
export const REQUEST_RIDE = 'REQUEST_RIDE';
export const REQUEST_RIDE_SUCCESS = 'REQUEST_RIDE_SUCCESS';
export const REQUEST_RIDE_ERROR = 'REQUEST_RIDE_ERROR';
export const VALIDATE_RIDE = 'VALIDATE_RIDE';
export const VALIDATE_RIDE_SUCCESS = 'VALIDATE_RIDE_SUCCESS';
export const VALIDATE_RIDE_ERROR = 'VALIDATE_RIDE_ERROR';
export const SCHEDULE_RIDE = 'SCHEDULE_RIDE';
export const SCHEDULE_RIDE_SUCCESS = 'SCHEDULE_RIDE_SUCCESS';
export const SCHEDULE_RIDE_ERROR = 'SCHEDULE_RIDE_ERROR';
export const SAVE_REQUEST_RIDE_FORM = 'SAVE_REQUEST_RIDE_FORM';
export const RESET_RIDE = 'RESET_RIDE';
export const TRACK_RIDE = 'TRACK_RIDE';
export const TRACK_RIDE_SUCCESS = 'TRACK_RIDE_SUCCESS';
export const TRACK_RIDE_ERROR = 'TRACK_RIDE_ERROR';
export const GET_INCOME = 'GET_INCOME';
export const GET_INCOME_SUCCESS = 'GET_INCOME_SUCCESS';
export const GET_INCOME_ERROR = 'GET_INCOME_ERROR';
export const CANCEL_RIDE = 'CANCEL_RIDE';
export const CANCEL_RIDE_SUCCESS = 'CANCEL_RIDE_SUCCESS';
export const CANCEL_RIDE_ERROR = 'CANCEL_RIDE_ERROR';
export const GET_PARENTS = 'GET_PARENTS';
export const GET_PARENTS_SUCCESS = 'GET_PARENTS_SUCCESS';
export const GET_PARENTS_ERROR = 'GET_PARENTS_ERROR';
export const BATCH_SIMULATE_RIDE = 'BATCH_SIMULATE_RIDE';
export const BATCH_SIMULATE_RIDE_SUCCESS = 'BATCH_SIMULATE_RIDE_SUCCESS';
export const BATCH_SIMULATE_RIDE_ERROR = 'BATCH_SIMULATE_RIDE_ERROR';